import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap-5';

export default function GoToPreviewModal({ previewUrl, show, onHide }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Unable to become a lead</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You are unable to become a lead because of your association with the property.
        </p>
        <p>
          <a href={previewUrl}>Click Here</a> to preview the steps a user goes to become a lead
        </p>
      </Modal.Body>
    </Modal>
  );
}

GoToPreviewModal.propTypes = {
  previewUrl: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};
