import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap-5';

import { setFilterAttribute } from '../../../store/actions/criteria';

function MultiSelectInput({ criteria, criteriaKey, options, setFilterAttribute }) {
  const selectedOptions = criteria[criteriaKey] || [];

  return (
    <div>
      {(options instanceof Map ? [...options.entries()] : Object.entries(options)).map(([key, value], idx) => (
        <Form.Check
          checked={selectedOptions.includes(key) || false}
          id={`${criteriaKey}_${idx}`}
          key={key}
          label={value}
          type="checkbox"
          onChange={() => setFilterAttribute(criteriaKey, key, { array: true })}
        />
      ))}
    </div>
  );
}

MultiSelectInput.propTypes = {
  criteria: PropTypes.object.isRequired,
  criteriaKey: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,  
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(MultiSelectInput);
