import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuildoutLogo } from 'helpers/custom_fontawesome_icons';

import { EmptyState } from './results/empty_state';
import EmptyStateBadges from './results/empty_state_badges';
import OffMarketResultsCardList from './off_market_results_card_list';
import OffMarketResultsTable from './off_market_results_table';

function OffMarketResults({
  allOffMarketResults,
  allResults,
  containerRef,
  showTableView
}) {
  const [selectedPropertyIds, setSelectedPropertyIds] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectedPropertyIds(new Set());
    setSelectAll(false);
  }, [allOffMarketResults]);

  const handleSelectToggle = (propertyId) => {
    if (selectAll) {
      setSelectAll(false);
      const ids = new Set();
      allOffMarketResults.forEach((result) => {
        if (propertyId !== result.propertyId) ids.add(result.propertyId);
      });

      setSelectedPropertyIds(ids);
    } else if (selectedPropertyIds.has(propertyId)) {
      setSelectedPropertyIds(prev => new Set([...prev].filter(id => id !== propertyId)));
    } else {
      setSelectedPropertyIds(previousState => new Set([...previousState, propertyId]));
    }
  };

  const handleToggleAllSelected = () => {
    setSelectedPropertyIds(new Set());
    setSelectAll(!selectAll);
  };

  if (!allResults) {
    return (
      <div className="text-center py-4">
        <FontAwesomeIcon beatFade className="text-primary" icon={faBuildoutLogo} size="3x" />
      </div>
    );
  }

  if (allResults.length === 0) {
    return (
      <div>
        <EmptyState subtitle="Expand your search to find matching, off market properties" title="No matches" />
        <EmptyStateBadges />
      </div>
    );
  }

  return (
    <div>
      {!showTableView && (
        <OffMarketResultsCardList
          containerRef={containerRef}
          selectAll={selectAll}
          selectedPropertyIds={selectedPropertyIds}
          onSelectAllToggle={handleToggleAllSelected}
          onSelectToggle={handleSelectToggle}
        />
      )}
      {showTableView && (
        <OffMarketResultsTable
          containerRef={containerRef}
          selectAll={selectAll}
          selectedPropertyIds={selectedPropertyIds}
          onSelectAllToggle={handleToggleAllSelected}
          onSelectToggle={handleSelectToggle}
        />
      )}
    </div>
  );
}

OffMarketResults.propTypes = {
  allOffMarketResults: PropTypes.arrayOf(PropTypes.shape({
    propertyId: PropTypes.string.isRequired
  })),
  allResults: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  })),
  containerRef: PropTypes.object,
  showTableView: PropTypes.bool.isRequired
};

const mapStateToProps = ({
  results: {
    offMarketResults,
    allOffMarketResults
  },
  ui: { showTableView }
}) => {
  return {
    allOffMarketResults,
    allResults: offMarketResults,
    showTableView
  };
};
export default connect(mapStateToProps)(OffMarketResults);
