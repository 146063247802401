import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paginator from './results/paginator';
import { Button, FormCheck, Table } from 'react-bootstrap-5';
import { setOffMarketPropertyId } from '../helpers/navigation_helper';
import { ScoreBadge } from './results/score_badges';
import ProductFeature from './product_feature';
import pluralize from 'pluralize';
import { formatWithCommas } from 'helpers/formatters/number_formats';
import AddToList from '../../properties/add_to_list';
import { PROPERTY_MODEL } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleHistoryCirclePlus, faTableLayout } from '@fortawesome/pro-solid-svg-icons';
import OffMarketMapTableViewToggle from './off_market_map_table_view_toggle';
import { connect } from 'react-redux';
import ManageColumnsModal from './manage_columns_modal';

const defaultTableFields = [
  { value: 'owner1Name', label: 'Owner Name' },
  { value: 'likelyToSellRank', label: 'Likely To Sell Rank' },
  { value: 'propertyType', label: 'Property Type' },
  { value: 'lastPurchaseDate', label: 'Last Purchase Date' },
  { value: 'lastPurchasePrice', label: 'Last Purchase Price' },
  { value: 'ownerOccupied', label: 'Owner Occupied' },
  { value: 'totalBathrooms', label: 'Number of Bathrooms' },
  { value: 'bedrooms', label: 'Number of Bedrooms' },
  { value: 'buildingSize', label: 'Building Size' }
];

const otherTableFields = [
  { value: 'ownerAddress', label: 'Owner Address' },
  { value: 'ownerCity', label: 'Owner City' },
  { value: 'ownerState', label: 'Owner State' },
  { value: 'ownerZip', label: 'Owner Zip' },
  { value: 'owner2Name', label: 'Other Owner Name' },
  { value: 'legalDescription', label: 'Legal Description' },
  { value: 'zoning', label: 'Zoning' },
  { value: 'constructionType', label: 'Construction Type' },
  { value: 'heatingType', label: 'Heating Type' },
  { value: 'heatingFuelType', label: 'Heating Fuel Type' },
  { value: 'fireplaceType', label: 'Fireplace Type' },
  { value: 'garageType', label: 'Garage Type' },
  { value: 'roofCoverType', label: 'Roof Cover Type' },
  { value: 'roofType', label: 'Roof Type' },
  { value: 'lotSize', label: 'Lot Size' },
  { value: 'yearBuilt', label: 'Year Built' },
  { value: 'landAssessedValue', label: 'Land Assessed Value' },
  { value: 'improvementAssessedValue', label: 'Improvement Assessed Value' },
  { value: 'totalAssessedValue', label: 'Total Assessed Value' },
  { value: 'numberOfStories', label: 'Number Of Stories' },
  { value: 'totalRooms', label: 'Total No. Rooms' },
  { value: 'poolType', label: 'Pool Type' },
  { value: 'apn', label: 'APN' },
  { value: 'censusTract', label: 'Census Tract' },
  { value: 'schoolDistrict', label: 'School District' },
  { value: 'owner1Type', label: 'Owner Type' },
  { value: 'owner2Type', label: 'Other Owner Type' },
  { value: 'taxAmount', label: 'Tax Amount' },
  { value: 'taxYear', label: 'Tax Year' },
  { value: 'taxDelinquentYear', label: 'Tax Delinquent Year' },
  { value: 'assessedYear', label: 'Assessed Year' },
  { value: 'schoolTaxDistrict1', label: 'School Tax District 1' },
  { value: 'schoolTaxDistrict2', label: 'School Tax District 2' },
  { value: 'schoolTaxDistrict3', label: 'School Tax District 3' },
  { value: 'basementSize', label: 'Basement Size' },
  { value: 'basementType', label: 'Basement Type' },
  { value: 'garageSize', label: 'Garage Size' },
  { value: 'lotDepth', label: 'Lot Depth' },
  { value: 'lotFrontage', label: 'Lot Frontage' },
  { value: 'fullBathrooms', label: 'Full Bathrooms' },
  { value: 'partialBathrooms', label: 'Partial Bathrooms' },
  { value: 'bathroomFixtures', label: 'Bath Fixtures' },
  { value: 'airConditioning', label: 'AC Type' },
  { value: 'buildingClass', label: 'Building Class' },
  { value: 'buildingCondition', label: 'Building Condition' },
  { value: 'interiorWallType', label: 'Interior Wall Type' },
  { value: 'exteriorWallType', label: 'Exterior Wall Type' },
  { value: 'floorType', label: 'Floor Type' },
  { value: 'locationInfluence', label: 'Location Influence' },
  { value: 'numberOfGarageSpaces', label: 'Number of Garage Spaces' },
  { value: 'drivewayType', label: 'Driveway Type' },
  { value: 'patioType', label: 'Patio Type' },
  { value: 'porchType', label: 'Porch Type' },
  { value: 'residentialUnits', label: '# of Resi Units' },
  { value: 'commercialUnits', label: '# of Commercial Units' },
  { value: 'lotCode', label: 'Lot Code' },
  { value: 'lotNumber', label: 'Lot Number' },
  { value: 'block', label: 'Block' },
  { value: 'district', label: 'District' },
  { value: 'legalUnit', label: 'Legal Unit' },
  { value: 'municipality', label: 'Municipality' },
  { value: 'subdivision', label: 'Subdivision' },
  { value: 'subdivisionPhaseNumber', label: 'Subdivision Phase Number' },
  { value: 'subdivisionTractNumber', label: 'Subdivision Tract Number' },
  { value: 'numberOfOpenLiens', label: 'Total Open Liens' },
  { value: 'amountOfOpenLiens', label: 'Total Open Lien Amount' },
  { value: 'opportunityZone', label: 'Opportunity Zone' },
  { value: 'likelyToRefiRank', label: 'Likely To Refi Rank' },
  { value: 'numberOfBuildings', label: 'Number of Buildings' }
];


export const allTableFields = [...otherTableFields, ...defaultTableFields];

const displayField = (key, field) => {
  switch (key) {
  case 'likelyToSellRank':
    return <ScoreBadge badgeClass="likely-seller" label="Sell" score={{ rank: field.value, ...field }} />;
  case 'likelyToRefiRank':
    return <ScoreBadge badgeClass="likely-refi" label="ReFi" score={{ rank: field.value, ...field }} />;
  case 'ownerOccupied':
    return <div className="text-muted text-nowrap">{field?.value || 'No'}</div>;
  default :
    return <div className="text-muted text-nowrap">{field?.value}</div>;
  }
};

function OffMarketResultsTable(
  {
    allResults,
    containerRef,
    criteria,
    loading,
    selectAll,
    selectedPropertyIds,
    total,
    onSelectAllToggle,
    onSelectToggle
  }) {
  const [selectedFields, setSelectedFields] = useState(defaultTableFields);
  const [showModal, setShowModal] = useState(false);

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalSave = (newSelectedFields) => {
    setSelectedFields(newSelectedFields);
    setShowModal(false);
  };

  return (
    <div>
      {total && (
        <div
          className="p-3 d-flex justify-content-between align-items-center position-sticky top-0 z-3 bg-white shadow-md"
        >
          <div className="fw-semibold">{formatWithCommas(total)} {pluralize('property', total)}</div>
          <ProductFeature product="prospecting">
            <div className="d-flex gap-4 align-items-center">
              {(selectedPropertyIds.size > 0 || selectAll) && (
                <AddToList
                  criteria={criteria}
                  itemCount={selectAll ? total : selectedPropertyIds.size}
                  itemIds={selectedPropertyIds}
                  itemType={PROPERTY_MODEL}
                  selectAll={selectAll}
                >
                  <Button variant="text-primary">
                    <FontAwesomeIcon icon={faRectangleHistoryCirclePlus} />
                    Add to List
                  </Button>
                </AddToList>
              )}
              <Button variant="text-primary" onClick={() => setShowModal(!showModal)}>
                <FontAwesomeIcon icon={faTableLayout} />
                Manage Columns
              </Button>
              {selectedPropertyIds.size === 0 && !selectAll && (
                <Button disabled variant="text-primary">
                  <FontAwesomeIcon icon={faRectangleHistoryCirclePlus} />
                  Add to List
                </Button>
              )}
              <OffMarketMapTableViewToggle />
            </div>
          </ProductFeature>
        </div>
      )}
      <div className={`table-responsive transition-opacity ${loading ? 'opacity-75' : 'opacity-100'}`}>
        <Table className="my-0" hover>
          <thead className="bg-light">
            <tr className="align-middle">
              <th className="fw-bolder text-nowrap sticky-column bg-light">
                <div className="d-flex gap-2">
                  <ProductFeature product="prospecting">
                    <FormCheck
                      checked={selectAll}
                      data-pendo-tag="results_select_all_checkbox"
                      onChange={onSelectAllToggle}
                    />
                  </ProductFeature>
                  <div>Property Address</div>
                </div>
              </th>
              {selectedFields.map(field => (
                <th className="fw-bolder text-nowrap" key={field.label}>{field.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allResults.map((property => (
              <tr className="align-middle hover-toggle-parent" key={property.propertyId}>
                <th className="sticky-column bg-white">
                  <div className="d-flex gap-3">
                    <FormCheck
                      checked={selectAll || selectedPropertyIds.has(property.propertyId)}
                      onChange={() => onSelectToggle(property.propertyId)}
                    />
                    <div>
                      <span
                        className="clickable text-primary fw-bolder text-nowrap"
                        onClick={() => setOffMarketPropertyId(property.propertyId)}
                      >
                        {`${property.address}, ${property.city} ${property.state}`}
                      </span>
                    </div>
                  </div>
                </th>
                {selectedFields.map(field => (
                  <td className="bg-white z-1" key={field.value}>
                    {displayField(field.value, property.tableSummary[field.value])}
                  </td>
                ))}
              </tr>
            )))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-center position-sticky z-3 bottom-0 bg-white p-2">
        <Paginator containerRef={containerRef} />
      </div>

      <ManageColumnsModal
        initialSelectedColumns={selectedFields}
        show={showModal}
        onHide={handleModalCancel}
        onSave={handleModalSave}
      />
    </div>
  );
}

OffMarketResultsTable.propTypes = {
  allResults: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  })),
  containerRef: PropTypes.object,
  criteria: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  selectAll: PropTypes.bool.isRequired,
  selectedPropertyIds: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  onSelectAllToggle: PropTypes.func.isRequired,
  onSelectToggle: PropTypes.func.isRequired
};

const mapStateToProps = ({
  criteria,
  results: {
    offMarketResults,
    totalOffMarketResults
  },
  ui: { loading }
}) => {
  return {
    allResults: offMarketResults,
    criteria,
    total: totalOffMarketResults,
    loading
  };
};
export default connect(mapStateToProps)(OffMarketResultsTable);


