import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { faBoxArchive } from '@fortawesome/pro-regular-svg-icons';
import { formatShortDate } from '../../helpers/date_helpers';
import { USD } from 'helpers/formatters/number_formats';

function rowDetail(label, value) {
  if (!value) return;
  return (
    <Row className="gx-0 border-bottom border-light align-items-center py-2">
      <Col lg={5}>
        {label}
      </Col>
      <Col>{value}</Col>
    </Row>
  );
}

function boolToString(value) {
  if (value) {
    return 'Yes';
  }

  return 'No';
}

export default function PropertyRecordRow({ collapsed, propertyRecord }) {
  const [open, setOpen] = useState(!collapsed);
  const mortgage1 =
    propertyRecord.mortgages && propertyRecord.mortgages.length > 0 ? propertyRecord.mortgages[0] : {};

  return (
    <div>
      <Row className="align-items-center clickable bottom-border" onClick={() => setOpen(!open)}>
        <Col>
          <FontAwesomeIcon className="text-secondary me-2" icon={faBoxArchive} />
          <strong>{propertyRecord.recordType} on {formatShortDate(propertyRecord.date)}</strong>
        </Col>
        <Col xs="auto">
          <FontAwesomeIcon icon={faAngleUp} rotation={open ? 180 : undefined} />
        </Col>
      </Row>
      <Collapse in={open}>
        <div>
          {rowDetail('Recording Date', formatShortDate(propertyRecord.date))}
          {propertyRecord.buyerOrBorrower && propertyRecord.buyerOrBorrower.length > 0 && (
            rowDetail('Buyer/Borrower', propertyRecord.buyerOrBorrower.map((name, i) => <div key={i}>{name}</div>))
          )}
          {propertyRecord.sellers && propertyRecord.sellers.length > 0 && (
            rowDetail('Seller', propertyRecord.sellers.map((name, i) => <div key={i}>{name}</div>))
          )}
          {rowDetail('Document Type', propertyRecord.documentTypeDescription)}
          {rowDetail('Transaction Type', propertyRecord.transactionTypeDescription)}
          {rowDetail('Current Lender', propertyRecord.currentLender)}
          {rowDetail('Auction Date', formatShortDate(propertyRecord.auctionDate))}
          {rowDetail('Auction Address', propertyRecord.auctionAddress)}
          {rowDetail('Auction City', propertyRecord.auctionCity)}
          {/* auction Minimum Bid Amount has a typo in FA see acution soo adding both incase they fix it */}
          {rowDetail('Auction Minimum Bid Amount', propertyRecord.acutionMinimumBidAmount)}
          {rowDetail('Auction Minimum Bid Amount', propertyRecord.auctionMinimumBidAmount)}
          {rowDetail('Title Company', propertyRecord.titleCompany)}
          {(mortgage1.amount || 0) > 0 && rowDetail('Mortgage Amount', USD.format(mortgage1.amount, { round: true }))}
          {rowDetail('Lender Name', mortgage1.lenderName)}
          {(mortgage1.term || 0) > 0 && rowDetail('Loan Type', mortgage1.loanType)}
          {(mortgage1.term || 0) > 0 && rowDetail('Term', mortgage1.term)}
          {(mortgage1.interestRate || 0) > 0 && rowDetail('Interest Rate', `${mortgage1.interestRate} %`)}
          {(mortgage1.interestRate || 0) > 0 && rowDetail('Interest Rate Type', mortgage1.interestRateType)}
          {
            rowDetail(
              'Subordinate Loan',
              mortgage1.subordinateLoan !== undefined ? boolToString(mortgage1.subordinateLoan) : undefined
            )
          }
          {rowDetail('Title Company', mortgage1.titleCompany)}
          {rowDetail('Subdivision Name', propertyRecord.subdivisionName)}
        </div>
      </Collapse>
    </div>
  );
}

PropertyRecordRow.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  propertyRecord: PropTypes.object.isRequired
};
