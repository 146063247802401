import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap-5';
import { allTableFields } from './off_market_results_table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import SelectedColumnList from '../../data_exports/selected_columns/list';

export default function ManageColumnsModal({ initialSelectedColumns, onHide, onSave, show }) {
  const [selectedColumns, setSelectedColumns] = useState(initialSelectedColumns);
  const [columnSearchString, setColumnSearchString] = useState('');
  const columnOptionsForSelect = allTableFields.filter(o => !selectedColumns.includes(o));

  const columnOptionSearchResults = useMemo(() => {
    if (!columnSearchString) return columnOptionsForSelect;

    return columnOptionsForSelect.filter(o => o.label.toLowerCase().includes(columnSearchString.toLowerCase()));
  }, [columnOptionsForSelect, columnSearchString]);

  const selectColumn = useCallback((column) => {
    setSelectedColumns([...selectedColumns, column]);
  }, [selectedColumns]);

  const deselectColumn = useCallback((column) => {
    setSelectedColumns(selectedColumns.filter(c => c != column));
  }, [selectedColumns]);

  const handleReorderColumns = useCallback((dragIndex, dropIndex) => {
    const columns = Object.assign([], selectedColumns);
    const column = columns[dragIndex];
    columns.splice(dragIndex, 1);
    columns.splice(dropIndex, 0, column);

    setSelectedColumns(columns);
  }, [selectedColumns]);

  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manage Columns</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          You can use the panes below to add, remove, and reoganize the columns that appear on the search results.
        </div>
        <Row>
          <Col xs={6}>
            <Form.Label>Available Columns ({columnOptionsForSelect.length})</Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faMagnifyingGlass}/>
              </InputGroup.Text>
              <Form.Control
                type="text"
                value={columnSearchString}
                onChange={e => setColumnSearchString(e.target.value)}
              />
            </InputGroup>
            <div className="rounded border p-2" style={{ maxHeight: 400, overflow: 'scroll' }}>
              <div className=" d-flex flex-column gap-2">
                {columnOptionSearchResults.map(col => (
                  <div
                    className="d-flex align-items-center justify-content-between rounded border p-2 pe-4"
                    key={col.value}
                  >
                    {col.label}
                    <FontAwesomeIcon
                      className="clickable text-primary"
                      icon={faArrowRight}
                      onClick={() => selectColumn(col)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <Form.Label>Selected Columns ({selectedColumns.length})</Form.Label>
            <div className="rounded border p-2" style={{ maxHeight: 400, overflow: 'scroll' }}>
              <SelectedColumnList
                selectedColumns={selectedColumns}
                onDeselectColumn={deselectColumn}
                onReorderColumns={handleReorderColumns}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onHide}>Cancel</Button>
        <Button variant="secondary" onClick={() => onSave(selectedColumns)}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

ManageColumnsModal.propTypes = {
  initialSelectedColumns: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};
