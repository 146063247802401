import React from 'react';
import PropTypes from 'prop-types';
import Paginator from './results/paginator';
import OffMarketResultCard from './results/off_market_result_card';
import pluralize from 'pluralize';
import ProductFeature from './product_feature';
import AddToList from '../../properties/add_to_list';
import { PROPERTY_MODEL } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleHistoryCirclePlus } from '@fortawesome/pro-solid-svg-icons';

import { formatWithCommas } from 'helpers/formatters/number_formats';
import OffMarketMapTableViewToggle from './off_market_map_table_view_toggle';
import { connect } from 'react-redux';

function OffMarketResultsCardList(
  {
    allResults,
    containerRef,
    criteria,
    loading,
    selectAll,
    selectedPropertyIds,
    total,
    onSelectToggle,
    onSelectAllToggle
  }) {
  return (
    <div>
      {total && (
        <div className="p-3 d-flex justify-content-between position-sticky top-0 z-3 bg-white shadow-md">
          <div className="fw-semibold">{formatWithCommas(total)} {pluralize('property', total)}</div>
          <ProductFeature product="prospecting">
            <div className="d-flex gap-4 align-items-center">
              <label>
                <div className="d-flex gap-2">
                  <input
                    checked={selectAll}
                    data-pendo-tag="results_select_all_checkbox"
                    type="checkbox"
                    onChange={onSelectAllToggle}
                  />
                  <div>Select All</div>
                </div>
              </label>
              {(selectedPropertyIds.size > 0 || selectAll) && (
                <AddToList
                  criteria={criteria}
                  itemCount={selectAll ? total : selectedPropertyIds.size}
                  itemIds={selectedPropertyIds}
                  itemType={PROPERTY_MODEL}
                  selectAll={selectAll}
                >
                  <FontAwesomeIcon className="text-primary clickable" icon={faRectangleHistoryCirclePlus} />
                </AddToList>
              )}
              {selectedPropertyIds.size === 0 && !selectAll && (
                <FontAwesomeIcon className="text-muted" icon={faRectangleHistoryCirclePlus} />
              )}
              <OffMarketMapTableViewToggle />
            </div>
          </ProductFeature>
        </div>
      )}
      <div className={`mt-1 p-3 transition-opacity ${loading ? 'opacity-75' : 'opacity-100'}`}>
        {allResults.map(result => (
          <OffMarketResultCard
            isSelected={selectAll || selectedPropertyIds.has(result.propertyId)}
            key={result.propertyId}
            result={result}
            onSelectToggle={onSelectToggle}
          />
        ))}
      </div>

      <div className="d-flex justify-content-center mb-4">
        <Paginator containerRef={containerRef} />
      </div>
    </div>
  );
}

OffMarketResultsCardList.propTypes = {
  allResults: PropTypes.arrayOf(PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  })),
  containerRef: PropTypes.object,
  criteria: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  selectAll: PropTypes.bool.isRequired,
  selectedPropertyIds: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  onSelectAllToggle: PropTypes.func.isRequired,
  onSelectToggle: PropTypes.func.isRequired
};

const mapStateToProps = ({
  criteria,
  results: {
    offMarketResults,
    totalOffMarketResults
  },
  ui: { loading }
}) => {
  return {
    allResults: offMarketResults,
    criteria,
    total: totalOffMarketResults,
    loading
  };
};
export default connect(mapStateToProps)(OffMarketResultsCardList);

