import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Form, Overlay, Popover } from 'react-bootstrap-5';

export default function EditTagPopover({ anchor, tag, onCancel, onUpdate }) {
  const [tagName, setTagName] = useState(tag.name);

  return (
    <Overlay rootClose show={true} target={anchor} onHide={onCancel} >
      <Popover className="border" style={{ width: '400px' }} onClick={e => e.stopPropagation()}>
        <Popover.Header className="bg-white border-bottom">
          <h6 className="fw-bolder m-0">Edit Tag</h6>
        </Popover.Header>
        <Popover.Body className="px-0 pb-0">
          <Form.Group className="mb-2 px-3 pb-2">
            <Form.Label>Tag Name</Form.Label>
            <Form.Control type="text" value={tagName} onChange={e => setTagName(e.target.value)}/>
          </Form.Group>
          <div className="border-top d-flex align-items-center justify-content-end p-2">
            <Button variant="link" onClick={onCancel}>Cancel</Button>
            <Button variant="secondary" onClick={() => onUpdate(tag.id, tagName)}>
              Update Tag
            </Button>
          </div>
        </Popover.Body>
      </Popover>
    </Overlay>
  );
}

EditTagPopover.propTypes = {
  anchor: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
};
