import { createStore } from 'redux';
import thunk from 'redux-thunk';
import { throttle, isEqual, debounce } from 'lodash';
import { search } from './actions/results';

import { applyMiddleware } from 'helpers/redux_dev_tools';
import rootReducer from './root_reducer';
import { getDetailsOfPlace } from '../helpers/map_helpers';
import { fetchZonesList } from './actions/ui';
import { saveCriteria, saveOffMarketSearchFlag, updateTableViewFlag } from '../../../helpers/local_storage';

export default function (state, query = {}) {
  const store = createStore(rootReducer, state, applyMiddleware(thunk));

  const shortDebounceSearch = debounce(() => {
    store.dispatch(search());
  }, 500);

  const debouncedZoneFetch = debounce(() => {
    store.dispatch(fetchZonesList());
  }, 500);

  store.subscribe(throttle(() => {
    const criteria = store.getState().criteria;
    saveCriteria({ criteria });
    const { offMarketSearch, showTableView } = store.getState().ui;
    saveOffMarketSearchFlag(offMarketSearch);
    updateTableViewFlag(showTableView);
  }));

  let prevState = store.getState();

  store.subscribe(() => {
    const state = store.getState();
    if (!state.ui.mapLoaded || (state.ui.mapZoomReadonly || 0) <= 11) return;

    const hasZoomChanged = prevState.ui.mapZoomReadonly !== state.ui.mapZoomReadonly;
    const hasBoundsChanged = !isEqual(prevState.criteria.mapBounds, state.criteria.mapBounds);
    if (hasZoomChanged || hasBoundsChanged) debouncedZoneFetch();
  });

  store.subscribe(() => {
    const state = store.getState();
    const mapLoaded = !prevState.ui.mapLoaded && state.ui.mapLoaded;

    if (mapLoaded && query.placeId) {
      getDetailsOfPlace({ placeId: query.placeId }).then((place) => {
        const detail = {
          placeId: query.placeId,
          address: place.formatted_address,
          types: place.types
        };

        const event = new CustomEvent('placeSelect', { detail });
        window.dispatchEvent(event);
      });
    } else if (
      (mapLoaded && !state.ui.showMap) ||
      !isEqual(prevState.criteria, state.criteria) ||
      prevState.ui.offMarketSearch !== state.ui.offMarketSearch
    ) {
      shortDebounceSearch();
    }

    prevState = state;
  });

  if (module.hot) {
    module.hot.accept('./root_reducer', () => {
      store.replaceReducer(require('./root_reducer'));
    });
  }

  return store;
}
