import React from 'react';
import PropTypes from 'prop-types';

import { reorderableList } from 'helpers/react_dnd/reorderable_list';
import SelectedColumnRow from './row';

function SelectedColumnList({ selectedColumns, onDeselectColumn, onReorderColumns }) {
  return (
    <div className=" d-flex flex-column gap-2">
      {selectedColumns.map((column, index) => (
        <SelectedColumnRow
          column={column}
          index={index}
          key={column.value}
          onDeselectColumn={() => onDeselectColumn(column)}
          onReorder={onReorderColumns}
        />
      ))}
    </div>
  );
}

SelectedColumnList.propTypes = {
  selectedColumns: PropTypes.array,
  onDeselectColumn: PropTypes.func.isRequired,
  onReorderColumns: PropTypes.func.isRequired
};

export default reorderableList(SelectedColumnList);
