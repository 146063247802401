import { faPlug } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap-5';
import { deleteRethinkCredential, getRethinkCredential } from '../../helpers/prospect_api';
import { displayToast } from '../../helpers/display_toast';

export default function ConnectionSettings({ initialCredentialId, salesforceAuthorizeUrl }) {
  const [credentialId, setCredentialId] = useState(initialCredentialId);
  const [processing, setProcessing] = useState(false);

  const handleClick = () => {
    setProcessing(true);

    if (credentialId) {
      deleteRethinkCredential(initialCredentialId).then(() => {
        setCredentialId(null);
        setProcessing(false);
        displayToast('Disconnected from Rethink', 'success');
      });
    } else {
      window.open(salesforceAuthorizeUrl, '_blank');

      const interval = setInterval(() => {
        getRethinkCredential().then((credential) => {
          if (credential) {
            clearInterval(interval);
            setCredentialId(credential.id);
            setProcessing(false);
            displayToast('Connected to Rethink', 'success');
          }
        });
      }, 3000);
    }
  };

  return (
    <>
      <Card.Header className="p-6">
        <Row className="p-0">
          <Col><h4 className="my-0">App Connections</h4></Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <div className="fs-large fw-bolder">Rethink</div>
            <div>
              CRM to manage prospect pipelines.
              Connecting to Rethink allows you to send contacts and property data and sync it with the CRM.
            </div>
          </Col>
          <Col xs="auto">
            <Button variant="secondary" onClick={handleClick}>
              { processing ? <Spinner animation="border" size="sm"/> : <FontAwesomeIcon icon={faPlug} /> }
              { credentialId ? 'Disconnect from Rethink' : 'Connect to Rethink' }
            </Button>            
          </Col>
        </Row>
      </Card.Body>
    </>
  );
}

ConnectionSettings.propTypes = {
  initialCredentialId: PropTypes.number,
  salesforceAuthorizeUrl: PropTypes.string.isRequired
};
