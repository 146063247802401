import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col } from 'react-bootstrap-5';

import PopoverSelect from './filters/popover_select';
import CriteriaDecorator from '../helpers/criteria_decorator';
import ConnectFiltersModal from './filters/connect_filters_modal';
import SaveSearchButton from './filters/save_search_button';

import PropertyTypeInput from './filters/inputs/property_type_input';
import RangeInput from './filters/inputs/range_input';
import LocationInput from './filters/inputs/location_input';
import OpportunityZoneToggleInput from './filters/inputs/opportunity_zone_toggle_input';
import OnMarketDurationToggle from './filters/inputs/on_market_duration_toggle';

import { clearFilters } from '../store/actions/criteria';

function ConnectFilters({
  clearFilters,
  criteria,
  offMarketSearch
}) {
  const [showAllFilters, setShowAllFilters] = useState(false);

  const decoratedCriteria = useMemo(
    () => new CriteriaDecorator(criteria, offMarketSearch),
    [criteria, offMarketSearch]
  );
  const filterCount = decoratedCriteria.numCriteriaExcludingLocation();

  return (
    <>
      <Col lg={3} md={4}>
        <LocationInput />
      </Col>
      <Col md="auto" style={{ minWidth: '180px' }} xs={6}>
        <PopoverSelect
          bodyClassName="p-0"
          className="border"
          label="Property Type"
          selectedLabel={decoratedCriteria.assetClassSummary()}
        >
          <PropertyTypeInput />
        </PopoverSelect>
      </Col>
      <Col className="d-none d-md-block" xs="auto">
        <PopoverSelect
          label="Price"
          selectedLabel={decoratedCriteria.priceSummary()}
        >
          <RangeInput
            maxAttribute="maxSalePrice"
            minAttribute="minSalePrice"
            placeholder="Price"
          />
        </PopoverSelect>
      </Col>
      <Col className="d-none d-md-flex mb-auto" xs="auto">
        <OpportunityZoneToggleInput bordered />
      </Col>
      <Col className="d-none d-md-flex mb-auto" md="auto" xs={6}>
        <OnMarketDurationToggle bordered />
      </Col>
      <Col md="auto" xs={6}>
        <Button
          data-pendo-tag="exclusive_listings_all_filters"
          variant="outline-primary"
          onClick={() => setShowAllFilters(true)}
        >
          All Filters
          {filterCount > 0 && <span> ({filterCount})</span>}
        </Button>
      </Col>
      <Col className="d-none d-md-block" xs="auto">
        <SaveSearchButton data-pendo-tag="exclusive_listing_save_search" />
      </Col>
      {filterCount > 0 && (
        <Col className="d-none d-md-block" xs="auto">
          <Button className="px-0" variant="text-neutral" onClick={clearFilters}>Clear Filters</Button>
        </Col>
      )}
      <ConnectFiltersModal show={showAllFilters} onHide={() => setShowAllFilters(false)} />
    </>
  );
}

ConnectFilters.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  criteria: PropTypes.shape({
    assetClassIds: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  offMarketSearch: PropTypes.bool
};

const mapStateToProps = ({ criteria, ui: { offMarketSearch } }) => ({ criteria, offMarketSearch });

export default connect(mapStateToProps, { clearFilters })(ConnectFilters);
