import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap-5';

export default function OwnerNameDisplay({ children, label, name }) {
  return (
    <Row className="align-items-center">
      <Col>
        <small className="text-muted fw-normal">{label || 'Contact'}</small>
        <h6 className="text-body privacy-mask mb-0">{name}</h6>
      </Col>
      {children}
    </Row>
  );
}

OwnerNameDisplay.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string.isRequired
};
