import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap-5';

import PropTypes from 'prop-types';
import { fetchImportStatus } from '../../../helpers/prospect_api';
import LoadingBar from './loading_bar';

export default function ImportModalUploading({
  handleClose,
  importId,
  setCallListData,
  setCallListId,
}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(async () => {
      const data = await fetchImportStatus(importId);
      setProgress(data.progress);

      if (data.callListId !== undefined) {
        clearInterval(interval);
        setCallListId(data.callListId);
        setCallListData(data);
      }
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Uploading...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          We will upload and prepare this list for you.
          Feel free to close this dialog as this will continue to be worked on in the background.
        </p>
        <LoadingBar progress={progress} />
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-text-primary"
          onClick={handleClose}
        >Close</button>
      </Modal.Footer>
    </React.Fragment>
  );
}

ImportModalUploading.propTypes = {
  handleClose: PropTypes.func.isRequired,
  importId: PropTypes.number.isRequired,
  setCallListData: PropTypes.func.isRequired,
  setCallListId: PropTypes.func.isRequired,
};
