import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Spinner } from 'react-bootstrap-5';

export default function TagModal({ tagForModal, showModal, onHide }) {
  return (
    <Modal contentClassName="overflow-hidden" show={showModal} onHide={onHide}>
      <turbo-frame class="h-100" id="tag-details" src={`/prospect/tags/${encodeURIComponent(tagForModal)}`}>
        <Modal.Body>
          <div style={{ minHeight: '300px' }}>
            <div className="px-3 py-5">
              <div className="text-center">
                <Spinner animation="border" variant="primary" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </turbo-frame>
    </Modal>
  );
}

TagModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  tagForModal: PropTypes.string,
  onHide: PropTypes.func.isRequired,
};
