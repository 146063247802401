import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UnstyledDropdownToggle from 'components/bootstrap_extensions/bs5/unstyled_dropdown_toggle';
import {
  faEdit,
  faEllipsisVertical,
  faFileExport,
  faLock,
  faTrash
} from '@fortawesome/pro-solid-svg-icons';
import CallListExportModal from '../call_lists/export/modal';
import { deleteRequest } from 'helpers/turbo_api';
import { displayToast } from '../../helpers/display_toast';
import ExportUnavailable from '../call_lists/export/export_unavailable';

export default function CallListActionDropdown({
  exportDisabled,
  callListId,
  exportPath,
  pathForCallList,
  templates
}) {

  const [showExportModal, setShowExportModal] = useState(false);

  const handleRemove = async () => {
    confirm('Are you sure?') && await deleteRequest(pathForCallList)
      .then(() => {
        displayToast('List successfully deleted', 'success');
      })
      .catch(() => displayToast('Something went wrong', 'error'));
  };

  return (
    <>
      <Dropdown drop="start">
        <Dropdown.Toggle as={UnstyledDropdownToggle} className="text-muted">
          <FontAwesomeIcon className="clickable px-2 py-1" icon={faEllipsisVertical} tabIndex={0} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="py-0" renderOnMount>
          {exportDisabled ? (
            <ExportUnavailable disabled={exportDisabled}>
              <div>
                <Dropdown.Item disabled>
                  <FontAwesomeIcon className="me-2" fixedWidth icon={faLock} />
                  Export List
                </Dropdown.Item>
              </div>
            </ExportUnavailable>
          ) :
            (
              <Dropdown.Item onClick={() => setShowExportModal(true)}>
                <FontAwesomeIcon className="me-2" fixedWidth icon={faFileExport} />
                Export List
              </Dropdown.Item>
            )}
          <Dropdown.Item data-turbo={false} href={pathForCallList}>
            <FontAwesomeIcon className="me-2" fixedWidth icon={faEdit} />
            Edit List
          </Dropdown.Item>
          <Dropdown.Item onClick={handleRemove}>
            <FontAwesomeIcon className="me-2" fixedWidth icon={faTrash} />
            Delete List
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <CallListExportModal
        callListId={callListId}
        exportPath={exportPath}
        show={showExportModal}
        templates={templates}
        onHide={() => setShowExportModal(false)}
      />
    </>
  );
}

CallListActionDropdown.propTypes = {
  callListId: PropTypes.number.isRequired,
  exportDisabled: PropTypes.string,
  exportPath: PropTypes.string.isRequired,
  pathForCallList: PropTypes.string.isRequired,
  templates: PropTypes.array.isRequired
};
