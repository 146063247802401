import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Col, Button } from 'react-bootstrap-5';

import CriteriaDecorator from '../helpers/criteria_decorator';
import PopoverSelect from './filters/popover_select';
import LocationInput from './filters/inputs/location_input';
import PropertyTypeInput from './filters/inputs/property_type_input';
import RangeInput from './filters/inputs/range_input';
import OffMarketFiltersModal from './filters/off_market_filters_modal';
import SaveSearchButton from './filters/save_search_button';
import SortingSelect from './filters/sorting_select';
import SessionModal from '../../session_modal';

import { clearFilters } from '../store/actions/criteria';

function OffMarketFilters({ clearFilters, criteria, offMarketSearch, products, profile }) {
  const [showAllFilters, setShowAllFilters] = React.useState(false);

  const decoratedCriteria = useMemo(
    () => new CriteriaDecorator(criteria, offMarketSearch), [criteria, offMarketSearch]
  );

  const filterCount = decoratedCriteria.numCriteriaExcludingLocation();
  const hasProspecting = !!products.prospecting;
  const hasInsightsV2 = !!products.insightsV2;

  return (
    <>
      <Col lg={3} md={4}>
        <LocationInput />
      </Col>
      <Col className="d-none d-xl-block" md="auto" style={{ minWidth: '180px' }} xs={6}>
        <PopoverSelect
          bodyClassName="p-0"
          className="border"
          label="Property Type"
          selectedLabel={decoratedCriteria.assetClassSummary(true)}
        >
          <PropertyTypeInput />
        </PopoverSelect>
      </Col>
      <Col className="d-none d-xl-block" xs="auto">
        <PopoverSelect
          label="Number of Units"
          selectedLabel={decoratedCriteria.numberOfUnitsSummary()}
        >
          <RangeInput
            maxAttribute="maxNumberOfUnits"
            minAttribute="minNumberOfUnits"
            placeholder=""
          />
        </PopoverSelect>
      </Col>
      <Col className="d-none d-xl-block" xs="auto">
        <PopoverSelect
          label="Building Size"
          selectedLabel={decoratedCriteria.buildingSizeSummary()}
        >
          <RangeInput
            maxAttribute="maxBuildingSizeSf"
            minAttribute="minBuildingSizeSf"
            placeholder="SF"
          />
        </PopoverSelect>
      </Col>
      <Col className="d-none d-xl-block" xs="auto">
        <PopoverSelect
          label="Lot Size"
          selectedLabel={decoratedCriteria.lotSizeSummary()}
        >
          <RangeInput
            maxAttribute="maxLotSizeAcres"
            minAttribute="minLotSizeAcres"
            placeholder="Acres"
          />
        </PopoverSelect>
      </Col>
      <Col xs="auto">
        <SortingSelect />
      </Col>
      <Col md="auto" xs={6}>
        <Button
          data-pendo-tag="all_properties_all_filters"
          variant="outline-primary"
          onClick={() => setShowAllFilters(true)}
        >
          All Filters
          {filterCount > 0 && <span> ({filterCount})</span>}
        </Button>
      </Col>
      <Col className="d-none d-md-block" xs="auto">
        <SaveSearchButton data-pendo-tag="all_properties_save_search" />
      </Col>
      {filterCount > 0 && (
        <Col className="d-none d-md-block" md="auto" xs={6}>
          <Button className="px-0" variant="text-neutral" onClick={clearFilters}>Clear Filters</Button>
        </Col>
      )}
      {profile?.id && (
        <OffMarketFiltersModal
          hasInsightsV2={hasInsightsV2}
          hasProspecting={hasProspecting}
          show={showAllFilters}
          userType={profile.userType}
          onHide={() => setShowAllFilters(false)}
        />
      )}
      {!profile?.id && showAllFilters && (
        <SessionModal onHide={() => setShowAllFilters(false)} />
      )}
    </>
  );
}

OffMarketFilters.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  criteria: PropTypes.object,
  offMarketSearch: PropTypes.bool,
  products: PropTypes.object.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number,
    userType: PropTypes.string
  }).isRequired
};

const mapStateToProps = ({ criteria, ui: { offMarketSearch, profile, products } }) => {
  return { criteria, offMarketSearch, products, profile };
};

export default connect(mapStateToProps, { clearFilters })(OffMarketFilters);
