import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, ListGroup } from 'react-bootstrap-5';
import { connect } from 'react-redux';
import ActivityEdit from '../call_list/activity_edit';
import ActivityHistoryItem from '../call_list/activity_history_item';
import { removeActivity, updateActivity } from '../call_list/store/actions/activities';
import EmptyState from '../empty_state';
import { faListTimeline } from '@fortawesome/pro-solid-svg-icons';
import { sortBy } from 'lodash';

function ActivityHistoryCard({ activities, removeActivity, updateActivity }) {
  let completedActivities = activities.filter(activity => !activity.isTask || activity.completedAt);
  completedActivities = sortBy(completedActivities, activity => activity.completedAt || activity.createdAt).reverse();
  const [editedActivity, setEditedActivity] = useState();
  const [showModal, setShowModal] = useState(false);

  const onEdit = (activity) => {
    setEditedActivity(activity);
    setShowModal(true);
  };

  const handleClose = useCallback(() => setShowModal(false), []);

  return (
    <>
      <ActivityEdit
        initialActivity={editedActivity}
        show={showModal}
        onClose={handleClose}
        onUpdate={updateActivity}
      />
      {completedActivities.length === 0 && (
        <Card.Body>
          <EmptyState
            icon={faListTimeline}
            text="There are no activities for this contact."
          />
        </Card.Body>
      )}
      {completedActivities.length > 0 && (
        <ListGroup className="border-top-0" variant="flush">
          {completedActivities.map(activity => (
            <ActivityHistoryItem activity={activity} key={activity.id} onDelete={removeActivity}  onEdit={onEdit}/>
          ))}
        </ListGroup>
      )}
    </>
  );
}

ActivityHistoryCard.propTypes = {
  activities: PropTypes.array.isRequired,
  removeActivity: PropTypes.func.isRequired,
  updateActivity: PropTypes.func.isRequired,
};

const mapStateToProps = ({ activities }) => ({ activities });
export default connect(mapStateToProps, { removeActivity, updateActivity })(ActivityHistoryCard);
