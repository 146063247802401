import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, InputGroup } from 'react-bootstrap-5';
import ButtonGroupRadio from './button_group_radio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneOffice } from '@fortawesome/pro-solid-svg-icons';

export const CALL_RESULTS = [
  { value: 'reached', label: 'Reached' },
  { value: 'could_not_reach', label: 'Not Reached' },
  { value: 'left_message', label: 'Left Voicemail' },
  { value: 'bad_phone', label: 'Bad Phone' }
];

export default function CallForm(
  { activity, callTemplates, phoneNumbers, selectedTemplate, onTemplateSelect, onUpdate }
) {
  const handleTemplateSelect = useCallback((e) => {
    onTemplateSelect(e.target.value || undefined);
  }, []);

  return (
    <>
      {callTemplates && callTemplates.length > 0 && (
        <Form.Group>
          <Form.Label>Follow a script</Form.Label>
          <Form.Select value={selectedTemplate || ''} onChange={handleTemplateSelect}>
            <option value="">Use a predefined call template</option>
            {callTemplates.map(callTemplate => (
              <option key={callTemplate.id} value={callTemplate.id}>{callTemplate.title}</option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
      {activity.script && (
        <Form.Group>
          {activity.id && <Form.Label>Script used:</Form.Label>}
          <Card className="bg-light">
            <Card.Body style={{ whiteSpace: 'pre-line' }}>
              {activity.script}
            </Card.Body>
          </Card>
        </Form.Group>
      )}
      {phoneNumbers && phoneNumbers.length > 0 && (
        <Form.Group>
          <Form.Label>Select Phone</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faPhoneOffice}/>
            </InputGroup.Text>
            <Form.Select
              className="border-0 privacy-mask"
              value={activity.phoneNumber}
              onChange={e => onUpdate({ ...activity, phoneNumber: e.target.value })}
            >
              <option hidden value="">Select a phone number from the contact</option>
              <option value=""></option>
              {phoneNumbers.map(phoneNumber => (
                <option key={phoneNumber.number} value={phoneNumber.number}>{phoneNumber.formatted}</option>
              ))}
            </Form.Select>
          </InputGroup>
        </Form.Group>
      )}
      <Form.Group>
        <Form.Label>Call Result</Form.Label>
        <div className="d-none d-lg-block">
          <ButtonGroupRadio
            checked={activity.callResult}
            name="call_result"
            options={CALL_RESULTS}
            onChange={callResult => onUpdate({ ...activity, callResult })}
          />
        </div>

        <div className="d-lg-none">
          <Form.Select
            value={activity.callResult}
            onChange={e => onUpdate({ ...activity, callResult: e.target.value })}
          >
            {CALL_RESULTS.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label>Call Details</Form.Label>
        <Form.Control
          as="textarea"
          className="privacy-mask"
          placeholder="Notes from this call"
          rows={5}
          value={activity.description}
          onChange={e => onUpdate({ ...activity, description: e.target.value })}
        />
      </Form.Group>
    </>
  );
}

CallForm.propTypes = {
  activity: PropTypes.object.isRequired,
  callTemplates: PropTypes.array,
  phoneNumbers: PropTypes.array,
  selectedTemplate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onTemplateSelect: PropTypes.func,
  onUpdate: PropTypes.func.isRequired
};
