import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Col, Row, Table } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/pro-regular-svg-icons';
import { formatPhoneNumber } from 'helpers/formatters/phone_number_formats';
import { TENANT_EMPLOYEE_SIZE_MAP, TENANT_SALES_REVENUE_MAP } from '../../constants';

export default function PropertyTenantRow({ tenant }) {
  return (
    <div className="bg-light">
      <Row className="px-3 pt-3">
        <Col>
          <div className="fw-bolder"> {tenant.companyName} </div>
          <small className="text-muted d-block">{tenant.streetAddress1} {tenant.streetAddress2}</small>
          <small className="text-muted d-block">{tenant.city}, {tenant.state} {tenant.zip}</small>
        </Col>
        <Col xs="auto">
          <div className="d-flex gap-5">
            <div>
              <div className="fw-bold">
                {TENANT_EMPLOYEE_SIZE_MAP[tenant.employeeSize] || tenant.employeeSize}
              </div>
              <div className="text-muted">Employees</div>
            </div>
            <div>
              <div className="fw-bold">
                {TENANT_SALES_REVENUE_MAP[tenant.salesRevenue] || tenant.salesRevenue}
              </div>
              <div className="text-muted">Revenue</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="p-3">
        <Col>
          {tenant.sic1 && (
            <div className="d-flex gap-2">
              <div className="fw-bolder">SIC Code:</div>
              <div className="text-muted">{tenant.sic1} - {tenant.industry1}</div>
            </div>
          )}
          {tenant.website && (
            <div className="d-flex gap-2">
              <div className="fw-bolder">Website:</div>
              <a href={`https://${tenant.website}`} rel="noopener noreferrer" target="_blank">{tenant.website}</a>
            </div>
          )}
        </Col>
      </Row>
      {tenant.contacts.length == 0 && (
        <Row className="p-3">
          <Col>
            <div className="accordion-header-empty">
              <div className="d-flex align-items-center gap-2">
                <FontAwesomeIcon className="text-primary" icon={faUsers} />
                <div className="fw-bolder">No Contacts Found</div>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {tenant.contacts.length > 0 && (
        <Row className="p-3">
          <Col>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="d-flex gap-2">
                    <FontAwesomeIcon className="text-primary" icon={faUsers} />
                    <div className="fw-bolder">Contacts ({tenant.contacts.length})</div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <Table className="mb-0"responsive style={{ minWidth: '800px' }}>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '200px' }}>Name</th>
                        <th style={{ minWidth: '200px' }}>Title</th>
                        <th style={{ minWidth: '200px' }}>Emails</th>
                        <th style={{ minWidth: '200px' }}>Phone Numbers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tenant.contacts.map((contact, idx) => (
                        <tr key={idx}>
                          <td>{contact.contactName}</td>
                          <td>{contact.contactJobTitle}</td>
                          <td>
                            {contact.emails.map(email => (
                              <div key={email}><a href={`mailto:${email}`}>{email}</a></div>
                            ))}
                          </td>
                          <td>
                            {contact.phoneNumbers.map(number => (
                              <div key={number}><a href={`tel:${number}`}>{formatPhoneNumber(number)}</a></div>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      )}
    </div>
  );
}

PropertyTenantRow.propTypes = {
  tenant: PropTypes.object.isRequired
};
