import {
  SET_HOVER_LISTING_ID,
  SET_LISTING_ID,
  SET_OFF_MARKET_HOVER_PROPERTY_ID,
  SET_LOADING,
  SET_SHOW_MAP,
  SET_SHOW_TABLE_VIEW,
  SET_SAVE_SEARCH_MODAL,
  INCREMENT_LISTING_VIEW_COUNT,
  SET_OFF_MARKET_SEARCH,
  SET_MAP_LOADED,
  SET_MAP_ZOOM_READONLY,
  SET_CHOICE_PRESETS,
} from '../actions/ui';
import {
  CLEAR_CRITERIA, UPDATE_CRITERIA, TOGGLE_ASSET_CLASS
} from '../actions/criteria';
export const DEFAULT_UI = {
  property: null,
  hoverSaleListingId: undefined,
  loading: false,
  showMap: true,
  showTableView: false,
  showSaveSearchModal: false,
  mapLoaded: false,
  listingViewCount: 0,
  offMarketHoverPropertyId: null,
  mapZoomReadonly: null,
  products: {
    prospecting: false
  },
  choicePresets: {},
};

export default function(state = DEFAULT_UI, action) {
  switch (action.type) {
  case SET_OFF_MARKET_SEARCH:
    return { ...state, offMarketSearch: action.payload, hoverProperty: null };
  case SET_OFF_MARKET_HOVER_PROPERTY_ID:
    return { ...state, offMarketHoverPropertyId: action.payload };
  case SET_HOVER_LISTING_ID:
    return { ...state, hoverSaleListingId: action.payload };
  case SET_LISTING_ID:
    return { ...state, listingId: action.payload };
  case SET_LOADING:
    return { ...state, loading: action.payload };
  case SET_SHOW_MAP:
    return { ...state, showMap: action.payload };
  case SET_SHOW_TABLE_VIEW:
    return { ...state, showTableView: action.payload };
  case SET_SAVE_SEARCH_MODAL:
    return { ...state, showSaveSearchModal: action.payload, listingViewCount: 0, profileSavedSearch: true };
  case SET_CHOICE_PRESETS:
    return {
      ...state,
      choicePresets: {
        ...state.choicePresets,
        [action.payload.key]: action.payload.value,
      },
    };
  case INCREMENT_LISTING_VIEW_COUNT:
    return { ...state, listingViewCount: state.listingViewCount + 1 };
  case SET_MAP_LOADED:
    return { ...state, mapLoaded: true };
  case SET_MAP_ZOOM_READONLY:
    return { ...state, mapZoomReadonly: action.payload };
  case CLEAR_CRITERIA:
  case UPDATE_CRITERIA:
  case TOGGLE_ASSET_CLASS:
  default:
    return state;
  }
}
