import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Nav, Tab } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBank,
  faBriefcase,
  faBuilding,
  faBuildings,
  faBuildingUser,
  faCube,
} from '@fortawesome/pro-solid-svg-icons';

import { setFilterAttribute } from '../../store/actions/criteria';

import PropertyTypeInput from './inputs/property_type_input';
import BuildingClassInput from './inputs/building_class_input';
import MultiSelectInput from './inputs/multi_select_input';
import MultiSelectDropdown from './inputs/multi_select_dropdown_input';
import RangeInput from './inputs/range_input';
import DateRangeInput from './inputs/date_range_input';
import RegionInput from './inputs/region_input';
import TextInput from './inputs/text_input';
import Select from './inputs/select';
import BooleanSelect from './inputs/boolean_select';
import StateSelect from './inputs/state_select';
import Toggle from './inputs/toggle';

import { Filter } from './modal_components';

import {
  AIR_CONDITIONING_CODES,
  BUILDING_CONDITIONS,
  LIKELY_TO_SELL_RANKS,
  ROOF_TYPES,
} from './constants';
import { TENANT_EMPLOYEE_SIZE_MAP, TENANT_SALES_REVENUE_MAP, TENANT_SIC_CODE_MAP } from '../../../../constants';
import { map } from 'lodash';

function OffMarketFiltersModal({
  criteria,
  hasProspecting,
  hasInsightsV2,
  setFilterAttribute,
  show,
  onHide,
  choicePresets,
}) {
  const sicCodeOptions = useMemo(() => map(TENANT_SIC_CODE_MAP, (k, v) => ({ label: `${v} - ${k}`, value: v })), []);

  return (
    <Modal
      className="search-filters"
      fullscreen="sm-down"
      scrollable={true}
      show={show}
      size="lg"
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white p-0">
        <Tab.Container defaultActiveKey="property-information">
          <Row className="gx-0">
            <Col className="overflow-x-auto" md="auto" sm={12}>
              <Nav className="flex-md-column flex-nowrap nav-accent p-4" variant="pills">
                <Nav.Item className="text-nowrap">
                  <Nav.Link className="text-truncate text-start" eventKey="property-information">
                    <FontAwesomeIcon className="me-2" icon={faBuildings} />
                    Property Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-nowrap">
                  <Nav.Link className="text-truncate text-start" eventKey="building-information">
                    <FontAwesomeIcon className="me-2" icon={faBuilding} />
                    Building Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-nowrap">
                  <Nav.Link className="text-truncate text-start" eventKey="lot-information">
                    <FontAwesomeIcon className="me-2" icon={faCube} />
                    Lot Information
                  </Nav.Link>
                </Nav.Item>
                {hasProspecting && (
                  <Nav.Item className="text-nowrap">
                    <Nav.Link className="text-truncate text-start" eventKey="owner-information">
                      <FontAwesomeIcon className="me-2" icon={faBuildingUser} />
                      Owner Information
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Nav.Item className="text-nowrap">
                  <Nav.Link className="text-truncate text-start" eventKey="financial-information">
                    <FontAwesomeIcon className="me-2" icon={faBank} />
                    Financial Information
                  </Nav.Link>
                </Nav.Item>
                {hasInsightsV2 && (
                  <Nav.Item className="text-nowrap">
                    <Nav.Link className="text-truncate text-start" eventKey="tenant-information">
                      <FontAwesomeIcon className="me-2" icon={faBriefcase} />
                      Tenant Information
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </Col>
            <Col md sm={12}>
              <Tab.Content className="p-4">
                <Tab.Pane eventKey="property-information">
                  <div className="d-flex flex-column gap-3">
                    <Filter className="border rounded overflow-hidden" title="Property Type">
                      <PropertyTypeInput />
                    </Filter>
                    <div>
                      <Toggle
                        checked={!!criteria?.onMarket}
                        label="Active Listings"
                        onClick={() => setFilterAttribute('onMarket', !criteria?.onMarket)}
                      />
                      {hasProspecting && (
                        <>
                          <Toggle
                            checked={!!criteria?.minLikelyToSellRank}
                            label="Likely to Sell Only"
                            onClick={() => {
                              setFilterAttribute(
                                'minLikelyToSellRank',
                                criteria?.minLikelyToSellRank ? undefined : LIKELY_TO_SELL_RANKS[1]
                              );
                            }}
                          />
                          {hasInsightsV2 && (
                            <Toggle
                              checked={!!criteria?.minLikelyToRefiRank}
                              label="Likely to Refi Only"
                              onClick={() => {
                                setFilterAttribute(
                                  'minLikelyToRefiRank',
                                  criteria?.minLikelyToRefiRank ? undefined : LIKELY_TO_SELL_RANKS[1]
                                );
                              }}
                            />
                          )}
                          <Toggle
                            checked={!!criteria?.bookmarked}
                            label="Property Bookmarked"
                            onClick={() => setFilterAttribute('bookmarked', !criteria.bookmarked)}
                          />
                        </>
                      )}
                    </div>
                    <Filter title="Region">
                      <RegionInput />
                    </Filter>
                    {hasProspecting && (
                      <Toggle
                        checked={!!criteria?.qoz}
                        label="Opportunity Zone"
                        onClick={() => setFilterAttribute('qoz', !criteria?.qoz)}
                      />
                    )}
                    <Filter title="APN">
                      <TextInput attribute="apn" placeholder="Enter an APN" />
                    </Filter>
                    <Filter title="Air Conditioning Code">
                      <MultiSelectDropdown
                        attribute="airConditioningId"
                        options={AIR_CONDITIONING_CODES.map(i => ({
                          label: i,
                          value: i,
                        }))}
                      />
                    </Filter>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="building-information">
                  <div className="d-flex flex-column gap-3">
                    <Filter title="Building SF">
                      <RangeInput
                        maxAttribute="maxBuildingSizeSf"
                        minAttribute="minBuildingSizeSf"
                        placeholder="SF"
                      />
                    </Filter>
                    <Filter title="Number of Units">
                      <RangeInput
                        maxAttribute="maxNumberOfUnits"
                        minAttribute="minNumberOfUnits"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Number of Residential Units">
                      <RangeInput
                        maxAttribute="maxResidentialUnits"
                        minAttribute="minResidentialUnits"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Number of Commercial Units">
                      <RangeInput
                        maxAttribute="maxCommercialUnits"
                        minAttribute="minCommercialUnits"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Number of Buildings">
                      <RangeInput
                        maxAttribute="maxNumberOfBuildings"
                        minAttribute="minNumberOfBuildings"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Number of Stories">
                      <RangeInput
                        maxAttribute="maxNumberOfStories"
                        minAttribute="minNumberOfStories"
                        placeholder=""
                      />
                    </Filter>
                    {criteria?.assetClassIds?.includes(10) && (
                      <>
                        <Filter title="Number of Bedrooms">
                          <RangeInput
                            maxAttribute="maxNumberOfBedrooms"
                            minAttribute="minNumberOfBedrooms"
                            placeholder=""
                          />
                        </Filter>
                        <Filter title="Number of Bathrooms">
                          <RangeInput
                            maxAttribute="maxNumberOfBathrooms"
                            minAttribute="minNumberOfBathrooms"
                            placeholder=""
                          />
                        </Filter>
                      </>
                    )}
                    <Filter title="Floor Area Ratio">
                      <RangeInput
                        float
                        maxAttribute="maxFloorAreaRatio"
                        minAttribute="minFloorAreaRatio"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Year Built">
                      <RangeInput
                        maxAttribute="maxYearBuilt"
                        minAttribute="minYearBuilt"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Year Renovated">
                      <RangeInput
                        maxAttribute="maxYearRenovated"
                        minAttribute="minYearRenovated"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Roof Type">
                      <Select
                        attribute="roofType"
                        includeBlank={true}
                        options={ROOF_TYPES.map(i => ({
                          label: i,
                          value: i,
                        }))}
                      />
                    </Filter>
                    <Filter title="Building Condition">
                      <Select
                        attribute="buildingCondition"
                        includeBlank={true}
                        options={BUILDING_CONDITIONS.map(i => ({
                          label: i,
                          value: i,
                        }))}
                      />
                    </Filter>
                    <Filter bordered title="Building Class">
                      <BuildingClassInput />
                    </Filter>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="lot-information">
                  <div className="d-flex flex-column gap-3">
                    <Filter title="Lot Size">
                      <RangeInput
                        maxAttribute="maxLotSizeAcres"
                        minAttribute="minLotSizeAcres"
                        placeholder="Acres"
                      />
                    </Filter>
                    <Filter title="Lot Depth">
                      <RangeInput
                        maxAttribute="maxLotDepth"
                        minAttribute="minLotDepth"
                        placeholder="Ft"
                      />
                    </Filter>
                    <Filter title="Lot Frontage">
                      <RangeInput
                        maxAttribute="maxLotFrontage"
                        minAttribute="minLotFrontage"
                        placeholder="Ft"
                      />
                    </Filter>

                    <Filter title="Zoning">
                      <React.Fragment>
                        {(choicePresets?.zoneList && choicePresets.zoneList.length > 0) ?
                          (
                            <Select
                              attribute="zoning"
                              includeBlank={true}
                              options={choicePresets.zoneList.map(i=> ({
                                label: i,
                                value: i,
                              }))}
                            />
                          ) : (
                            <TextInput
                              attribute="zoning"
                              placeholder="Enter a zoning code"
                            />
                          )}
                      </React.Fragment>
                    </Filter>

                  </div>
                </Tab.Pane>
                {hasProspecting && (
                  <Tab.Pane eventKey="owner-information">
                    <div className="d-flex flex-column gap-3">
                      <Filter title="Owner Name">
                        <TextInput
                          attribute="ownerName"
                          placeholder="Enter the owner's name"
                        />
                      </Filter>
                      <Filter title="Corporate Owned">
                        <BooleanSelect
                          attribute="corporateOwned"
                          includeBlank={true}
                        />
                      </Filter>
                      <Filter title="Occupied by Owner">
                        <BooleanSelect
                          attribute="ownerOccupied"
                          includeBlank={true}
                        />
                      </Filter>
                      <Filter title="Owner Address">
                        <TextInput
                          attribute="ownerAddress"
                          placeholder="Enter the owner's address"
                        />
                      </Filter>
                      <Filter title="Owner State">
                        <StateSelect />
                      </Filter>
                      <Filter title="Owner City">
                        <TextInput
                          attribute="ownerCity"
                          placeholder="Enter the owner's city"
                        />
                      </Filter>
                      <Filter title="Owner Zip">
                        <TextInput
                          attribute="ownerZip"
                          placeholder="Enter the owner's zip code"
                        />
                      </Filter>
                    </div>
                  </Tab.Pane>
                )}
                <Tab.Pane eventKey="financial-information">
                  <div className="d-flex flex-column gap-3">
                    <Filter title="Land Assessed Value">
                      <RangeInput
                        maxAttribute="maxLandAssessedValue"
                        minAttribute="minLandAssessedValue"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Improvement Assessed Value">
                      <RangeInput
                        maxAttribute="maxImprovementAssessedValue"
                        minAttribute="minImprovementAssessedValue"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Total Assessed Value">
                      <RangeInput
                        maxAttribute="maxTotalAssessedValue"
                        minAttribute="minTotalAssessedValue"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Tax Amount">
                      <RangeInput
                        maxAttribute="maxTaxAmount"
                        minAttribute="minTaxAmount"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Last Transaction Amount">
                      <RangeInput
                        maxAttribute="maxLastTransactionAmount"
                        minAttribute="minLastTransactionAmount"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Last Transaction Amount per SF">
                      <RangeInput
                        maxAttribute="maxLastTransactionAmountPerSf"
                        minAttribute="minLastTransactionAmountPerSf"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Tax Delinquent Year">
                      <RangeInput
                        maxAttribute="maxTaxDelinquentYear"
                        minAttribute="minTaxDelinquentYear"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Last Transaction Date">
                      <DateRangeInput
                        maxAttribute="maxTransactionDate"
                        minAttribute="minTransactionDate"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Loan Starting Date">
                      <DateRangeInput
                        maxAttribute="maxLoanBeginningDate"
                        minAttribute="minLoanBeginningDate"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Loan Maturity Date">
                      <DateRangeInput
                        maxAttribute="maxLoanMaturityDate"
                        minAttribute="minLoanMaturityDate"
                        placeholder=""
                      />
                    </Filter>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="tenant-information">
                  <div className="d-flex flex-column gap-3">
                    <Filter title="Tenant Company Name">
                      <TextInput
                        attribute="tenantCompanyName"
                        placeholder="Enter a company name"
                      />
                    </Filter>
                    <Filter bordered title="Tenant Employee Size">
                      <MultiSelectInput criteriaKey="tenantEmployeeSizes" options={TENANT_EMPLOYEE_SIZE_MAP} />
                    </Filter>
                    <Filter bordered title="Tenant Sales Revenue">
                      <MultiSelectInput criteriaKey="tenantSalesRevenues" options={TENANT_SALES_REVENUE_MAP} />
                    </Filter>
                    <Filter title="Tenant SIC Codes">
                      <MultiSelectDropdown
                        attribute="tenantSicCodes"
                        isMulti
                        options={sicCodeOptions}
                      />
                    </Filter>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer className="p-2 justify-content-start justify-content-md-end">
        <Button variant="text-primary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

OffMarketFiltersModal.propTypes = {
  criteria: PropTypes.object.isRequired,
  hasInsightsV2: PropTypes.bool,
  hasProspecting: PropTypes.bool,
  setFilterAttribute: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  choicePresets: PropTypes.object,
};

const mapStateToProps = ({ criteria, ui: { choicePresets } }) => ({ criteria, choicePresets });

export default connect(mapStateToProps, { setFilterAttribute })(
  OffMarketFiltersModal
);
