import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Select from 'react-select';

import { setFilterAttribute } from '../../../store/actions/criteria';

function MultiSelectDropdown({ criteria, attribute, isMulti, options, setFilterAttribute }) {
  const handleChange = (selectedOptions) => {
    if (isMulti) {
      if (selectedOptions.length > 0) {
        setFilterAttribute(attribute, selectedOptions.map(option => option.value));
      } else {
        setFilterAttribute(attribute, undefined);
      }
    } else {
      setFilterAttribute(attribute, selectedOptions?.value);
    }
  };

  const selectValue = () => {
    if (isMulti) {
      return options.filter(option => (criteria[attribute] || []).includes(option.value));
    } else {
      return criteria[attribute] ?
        { value: criteria[attribute], label: options.find(option => option.value === criteria[attribute]).label } :
        null;
    }
  };

  return (
    <Select
      className={classNames('as-button', criteria[attribute] ? 'active' : 'text-muted')}
      isClearable
      isMulti={isMulti}
      menuPortalTarget={document.body}
      options={options}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      value={selectValue()}
      onChange={handleChange}
    />
  );
}

MultiSelectDropdown.propTypes = {
  attribute: PropTypes.string.isRequired,
  criteria: PropTypes.object.isRequired,
  includeBlank: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  isMulti: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.any
  })),
  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(MultiSelectDropdown);
