import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import PropertyPrice from '../property_price';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSign } from '@fortawesome/pro-solid-svg-icons';
import { Col, Row } from 'react-bootstrap-5';
import ResultCardHighlights from './result_card_highlights';
import ScoreBadges from './score_badges';

export default function OffMarketSummary({ property }) {
  const highlightText = property.highlights
    .map(({ label, value }) => compact([value, label]).join(' '));

  const estimate = property?.valuation?.estimate;
  const salePrice = property?.listings?.salePrice;
  const forSale = !!property?.listings?.forSale;
  const hasPrice = !!((estimate && estimate > 0) || (forSale && salePrice && salePrice > 0));

  return (
    <div className="d-flex flex-column justify-content-md-between gap-3 gap-md-2 h-100">
      {hasPrice && (
        <div>
          <div>
            {forSale && (
              <Col className="text-secondary text-xs" xs="auto">
                <FontAwesomeIcon className="me-1" icon={faSign} />
                On Market
              </Col>
            )}
          </div>
          <div className="d-flex flex-column gap-1 clickable-text">
            <Row>
              <Col>
                <PropertyPrice
                  estimatedValue={property?.valuation?.estimate}
                  listingValue={property?.listings?.salePrice}
                  valueClass="fw-bolder fs-base"
                />
              </Col>
            </Row>
            <div className="small result-address lh-sm">
              <div>{property.address}</div>
              <div>{property.city}, {property.state} {property.zip}</div>
            </div>
          </div>
        </div>
      )}
      {!hasPrice && (
        <div className="clickable-text lh-sm">
          <div className="fw-bolder result-address">{property.address}</div>
          <small>{property.city}, {property.state} {property.zip}</small>
        </div>
      )}
      <div className="small text-muted lh-sm">
        <ResultCardHighlights highlights={highlightText} />
      </div>
      <ScoreBadges scores={property.scores} />
    </div>
  );
}

OffMarketSummary.propTypes = {
  property: PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    listings: PropTypes.shape({
      forSale: PropTypes.bool,
      salePrice: PropTypes.number,
    }),
    valuation: PropTypes.shape({
      estimate: PropTypes.number,
    }),
  }).isRequired,
};
