// Date string is YYYY-MM-DD
export function formatDateString(date) {
  return (new Date(date))
    .toLocaleString('en-US', { timeZone: 'UTC', day: '2-digit', month: 'short', year: 'numeric', weekday: 'short' });
}

export function formatLongDate(dateString) {
  return new Date(dateString).toLocaleString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  }).replace(',', ' at');
}

export function formatShortDate(dateString) {
  if (!dateString) return;
  if ((/\d{4}-\d{1,2}-\d{1,2}/).test(dateString)) return formatDateFromIso8601(dateString);

  const date = new Date(dateString);

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

function formatDateFromIso8601(dateString) {
  const parts = dateString.split('-');

  return `${parts[1]}/${parts[2]}/${parts[0]}`;
}
