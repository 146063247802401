import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap-5';
import Ownership from '../../call_list/owners/ownership';
import OwnerNameDisplay from './owner-name-display';
import Paginator from '../../paginator';
import LlcMatchBadge from '../../call_list/owners/llc_match_badge';

export default function TabbedOwnerList({ activeKey, owners, setActiveKey }) {
  const OWNER_PAGE_SIZE = 10;
  const [page, setPage] = useState(1);

  const paginatedOwners = useMemo(() => {
    return owners.slice((page - 1) * OWNER_PAGE_SIZE, page * OWNER_PAGE_SIZE);
  }, [owners, page]);

  return (
    <Tab.Container activeKey={activeKey} onSelect={setActiveKey} >
      <Row>
        <Col>
          <Nav className="nav-pills flex-column">
            {paginatedOwners.map(owner => (
              <Nav.Link
                className="text-start rounded-1 border-0"
                eventKey={owner.id}
                key={owner.id}
              >
                <OwnerNameDisplay label={owner.label} name={owner.contact.name}>
                  <>
                    {owner.llcMatch && (
                      <Col xs="auto">
                        <LlcMatchBadge />
                      </Col>
                    )}
                    <Col xs="auto">
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Col>
                  </>

                </OwnerNameDisplay>
              </Nav.Link>
            ))}
          </Nav>
          {owners.length > OWNER_PAGE_SIZE && (
            <div className="d-flex justify-content-center">
              <Paginator currentPage={page} perPage={OWNER_PAGE_SIZE} total={owners.length} onSelect={setPage}/>
            </div>
          )}
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Tab.Content>
                {owners.map(owner => (
                  <Tab.Pane eventKey={owner.id} key={owner.id}>
                    <Ownership owner={owner} showOwnerName={true} />
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Tab.Container>
  );
}

TabbedOwnerList.propTypes = {
  activeKey: PropTypes.string,
  owners: PropTypes.array.isRequired,
  setActiveKey: PropTypes.func.isRequired,
};
