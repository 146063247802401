import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Container, Offcanvas, Row } from 'react-bootstrap-5';
import { Provider } from 'react-redux';
import { createPortal } from 'react-dom';

import configureStore from './store/configure';
import Tasks from './tasks';
import NotesCard from './notes';
import { PROPERTY_MODEL } from '../../constants';
import Overview from './overview';
import ActivitySection from './activity_section';
import ActivityHistoryCard from '../contacts/activity_history_card';

export default function CallListItem(
  {
    activities,
    callListId,
    callTemplates,
    trialUser,
    recordId,
    recordType,
    rethinkInstanceUrl,
    insightsPropertyId
  }
) {
  const [showProperty, setShowProperty] = useState(false);
  const store = useMemo(() => configureStore({ activities, owners: null, insightsPropertyId }), []);
  const headerButtonContainerTablet = useMemo(() => document.getElementById('js-header-portal-tablet'), []);
  const headerButtonContainerMobile = useMemo(() => document.getElementById('js-header-portal-mobile'), []);
  const hasProperty = recordType === PROPERTY_MODEL;

  return (
    <Provider store={store}>
      <Container fluid>
        {createPortal((
          <Button className="mt-1" variant="primary" onClick={() => setShowProperty(true)}>View Property</Button>
        ), headerButtonContainerTablet)}
        {createPortal((
          <Button className="w-100" variant="primary" onClick={() => setShowProperty(true)}>View Property</Button>
        ), headerButtonContainerMobile)}
        <Offcanvas
          className="property-offcanvas"
          placement="end"
          show={showProperty}
          onHide={() => setShowProperty(false)}
        >
          <Offcanvas.Header className="d-md-none justify-content-end" closeButton />
          <Overview insightsPropertyId={insightsPropertyId} />
        </Offcanvas>
        <Row className="g-3">
          {hasProperty && (
            <Col className="d-none d-xl-block" xl={5}>
              <Overview insightsPropertyId={insightsPropertyId}/>
            </Col>
          )}
          <Col>
            <Row className="g-3">
              <Col xs={hasProperty ? 12 : 7}>
                <ActivitySection
                  callListId={callListId}
                  callTemplates={callTemplates}
                  recordId={recordId}
                  recordType={recordType}
                  rethinkInstanceUrl={rethinkInstanceUrl}
                  trialUser={trialUser}
                />
              </Col>
              <Col xs={hasProperty ? 12 : 5}>
                <Row className="g-3">
                  <Col xl={hasProperty ? 6 : 12}>
                    <Card>
                      <Card.Header>
                        <h5 className="fw-bolder mb-0">Activity History</h5>
                      </Card.Header>
                      <ActivityHistoryCard />
                    </Card>
                  </Col>
                  <Col className="d-flex flex-column gap-3" xl={hasProperty ? 6 : 12}>
                    <NotesCard
                      recordId={recordId}
                      recordType={recordType}
                    />
                    <Tasks
                      callListId={callListId}
                      callTemplates={callTemplates}
                      recordId={recordId}
                      recordType={recordType}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Provider>
  );
}

CallListItem.propTypes = {
  activities: PropTypes.array.isRequired,
  callListId: PropTypes.number.isRequired,
  callTemplates: PropTypes.array.isRequired,
  insightsPropertyId: PropTypes.string,
  recordId: PropTypes.number.isRequired,
  recordType: PropTypes.string.isRequired,
  rethinkInstanceUrl: PropTypes.string,
  trialUser: PropTypes.bool.isRequired
};
