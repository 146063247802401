import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import { Badge, Col } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import PropTypes from 'prop-types';
import CriteriaDecorator from '../../helpers/criteria_decorator';
import { setFilterAttribute } from '../../store/actions/criteria';

function Badges({ criteria, offMarketSearch, setFilterAttribute }) {
  const filterSummary =
    useMemo(() => new CriteriaDecorator(criteria, offMarketSearch).filterSummary(), [criteria, offMarketSearch]);

  const removeFilter = useCallback((filter) => {
    if (!filter.keys) return;

    filter.keys.forEach(key => setFilterAttribute(key, undefined));
  }, [setFilterAttribute]);

  if (!filterSummary) return null;

  return (
    <>
      {filterSummary.map((filter) => {
        if (filter.keys && filter.keys.includes('locationAttributes')) return;

        return (
          <Col key={filter.label} xs="auto">
            <Badge
              bg=""
              className="clickable rounded-pill me-2 py-2 px-3 primary"
              onClick={() => removeFilter(filter)}
            >
              {filter.label}
              {filter.keys && (
                <FontAwesomeIcon className="ms-2" icon={faXmark} size="lg" />
              )}
            </Badge>
          </Col>
        );
      })}
    </>
  );
}

Badges.propTypes = {
  criteria: PropTypes.object.isRequired,
  offMarketSearch: PropTypes.bool.isRequired,

  setFilterAttribute: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria, ui: { offMarketSearch } }) => ({ criteria, offMarketSearch });
const mapDispatchToProps = { setFilterAttribute };

export default connect(mapStateToProps, mapDispatchToProps)(Badges);
