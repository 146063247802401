import React from 'react';
import { Modal, Row } from 'react-bootstrap-5';

import PropTypes from 'prop-types';

export default function ImportModalAddressList({
  callListData,
  callListId,
  handleClose,
}) {
  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Missing Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <p>
            These are the properties we could not match from your list.
            You can try performing individual searches and add them to this list manually.
          </p>
        </Row>
        <div className="bg-light rounded p-2 mb-2 overflow-auto border" style={{ maxHeight: '500px' }}>
          {callListData.noPropertyAddresses.map((address, index) => (
            <div className="d-flex align-items-center justify-content-between" key={index} >
              <div>
                <p className="m-2">
                  {address.address}{address.state && `, ${address.state}`}{address.zip && `, ${address.zip}`}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-text-primary"
          onClick={handleClose}
        >
          Close
        </button>
        <a className="btn btn-secondary"
          href={`/prospect/connect_call_lists/${callListId}`}
        >
          View List
        </a>
      </Modal.Footer>
    </React.Fragment >
  );
}

ImportModalAddressList.propTypes = {
  callListData: PropTypes.object.isRequired,
  callListId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};
