import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';

import { post } from 'helpers/api';
import { getProfileData } from '../../helpers/preview';

export default function VerifyEmail({ email, readonly, resendVerificationPath }) {
  const [emailResent, setEmailResent] = useState(false);
  const previewProfile = getProfileData();

  useEffect(() => {
    if (readonly) post(resendVerificationPath, { previewProfile, readonly });
  }, []);

  const resendEmail = () => {
    post(resendVerificationPath, { previewProfile, readonly }).then(() => {
      setEmailResent(true);
      setTimeout(() => setEmailResent(false), 5000);
    });
  };

  return (
    <div>
      <div className="text-center mb-3 text-branding">
        <FontAwesomeIcon icon={faEnvelopeOpenText} size="2x"/>
      </div>
      <h5 className="text-center mb-6">
        <strong>Verify your email address</strong>
      </h5>
      <p>
        We just sent an email to <strong>{email}</strong>
      </p>
      <p>
        We need to verify this email belongs to you.
        Check your email and click "confirm" to finish creating your account.
      </p>
      <p className="text-muted">
        <small>
          If you don't see a message in your inbox, make sure the email address listed above is correct.
          The email will be sent from support@buildout.com
        </small>
      </p>
      <div className="text-center">
        <Button disabled={emailResent} variant="link" onClick={resendEmail}>
          Resend Verification Email
        </Button>
      </div>
      {emailResent && (
        <div className="animate__animated animate__fadeInUp text-branding text-center mt-3">
          Email verification resent!
        </div>
      )}
    </div>
  );
}

VerifyEmail.propTypes = {
  email: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  resendVerificationPath: PropTypes.string.isRequired
};
