import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap-5';
import { faExclamationCircle, faFileExport, faTimer } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createExport, fetchCallListExportCreditCounts } from '../../../helpers/prospect_api';

export default function PropertyOwnerExport({ callListId, exportPath, show, templates, onHide }) {
  const CALL_LIST_EXPORT_PROPERTY_LIMIT = 1000;
  const [saving, setSaving] = useState(false);
  const [exportComplete, setExportComplete] = useState(false);
  const [exportName, setExportName] = useState('');
  const [emailNotification, setEmailNotification] = useState(true);
  const [exportOwners, setExportOwners] = useState(true);
  const [templateId, setTemplateId] = useState();
  const [exportCreditCounts, setExportCreditCounts] = useState();

  useEffect(() => {
    if (!show) return;

    fetchCallListExportCreditCounts(callListId).then(setExportCreditCounts);
  }, [show]);

  const handleExport = () => {
    setSaving(true);
    const dataExport = {
      name: exportName,
      connectDataExportTemplateId: templateId,
      emailNotification,
      exportOwners
    };
    createExport(exportPath, { dataExport, type: 'property' }).then(() => {
      setSaving(false);
      setExportComplete(true);
    });
  };

  if (!show) return null;

  const showPropertyLimitWarning = exportCreditCounts &&
                                   exportCreditCounts.callListSize > CALL_LIST_EXPORT_PROPERTY_LIMIT;
  const showCreditWarning = exportCreditCounts &&
                            exportCreditCounts.availableCredits < exportCreditCounts.creditsForExport && exportOwners;
  const showCreditInfo = exportCreditCounts && exportCreditCounts.creditsForExport > 0 &&
                         exportCreditCounts.availableCredits >= exportCreditCounts.creditsForExport && exportOwners;

  // Owner unlocks happen at a rate of 1 per 5 seconds. See app/workers/connect/exports/call_list_properties_worker.rb
  const estimatedTimeInMinutes = showCreditInfo ? Math.ceil((exportCreditCounts.creditsForExport * 5) / 60) : 0;

  return (
    <>
      <Modal.Body>
        {exportComplete ? (
          <div>
            Property / Owner export queued!&nbsp;
            Check your inbox or navigate to your <a href="/prospect/profile#exports">Profile Settings</a>&nbsp;
            to download your export
          </div>
        ) : (
          <div className="d-flex flex-column gap-4">
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder="Export Name"
                value={exportName}
                onChange={e => setExportName(e.target.value)}
              />
            </Form.Group>
            {templates.length > 0 && (
              <Form.Group>
                <Form.Label>Export Template</Form.Label>
                <Form.Select value={templateId} onChange={e => setTemplateId(e.target.value)}>
                  <option value="">Select a template</option>
                  {templates.map(template => (
                    <option key={template.id} value={template.id}>{template.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
            {showPropertyLimitWarning && (
              <div className="alert alert-danger d-flex align-items-start gap-2 m-0">
                <FontAwesomeIcon className="mt-1" icon={faExclamationCircle} />
                <div>
                  <div><strong>Property Limit</strong></div>
                  <div>Data exports are limited to a maximum of 1,000 properties.</div>
                </div>
              </div>
            )}
            {showCreditInfo && (
              <div className="alert alert-info d-flex align-items-start gap-2 m-0">
                <FontAwesomeIcon className="mt-1" icon={faExclamationCircle} />
                <div>
                  <div><strong>Credits will be used</strong></div>
                  <div>
                    The list selected contains owners that have not been unmasked.
                    Running this export will use up to <strong>{exportCreditCounts.creditsForExport} credits.</strong>
                  </div>
                  <div>You currently have {exportCreditCounts.availableCredits} credits available.</div>
                </div>
              </div>
            )}
            {showCreditWarning && (
              <div className="alert alert-danger d-flex align-items-start gap-2 m-0">
                <FontAwesomeIcon className="mt-1" icon={faExclamationCircle} />
                <div>
                  <div><strong>Not enough credits</strong></div>
                  <div>
                    This list contains {exportCreditCounts.creditsForExport} properties
                    whose owners have not been unmasked.
                    Owners for these properties will not be included due to lack of credits.
                  </div>
                  <div>You currently have {exportCreditCounts.availableCredits} credits available.</div>
                </div>
              </div>
            )}
            {estimatedTimeInMinutes > 1 && (
              <div className="alert alert-info d-flex align-items-start gap-2 m-0">
                <FontAwesomeIcon className="mt-1" icon={faTimer} />
                <div>
                  This export may take up to {estimatedTimeInMinutes} minutes to complete.
                </div>
              </div>
            )}
            <div className="d-flex flex-column gap-2">
              <Form.Group>
                <Form.Check
                  checked={exportOwners}
                  id="export-checkbox"
                  label={
                    <div>
                      <div>Include owner contact data</div>
                      {exportCreditCounts && exportCreditCounts.creditsForExport > 0 && (
                        <small className="text-muted lh-sm">
                          This will expend an additional credit for any owner
                          contact that has not been unmasked previously.
                        </small>
                      )}
                    </div>
                  }
                  type="checkbox"
                  onChange={e => setExportOwners(e.target.checked)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Check
                  checked={emailNotification}
                  id="email-checkbox"
                  label="Email me when the export completes"
                  type="checkbox"
                  onChange={e => setEmailNotification(e.target.checked)}
                />
              </Form.Group>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {exportComplete ? (
          <Button variant="success" onClick={onHide}>
            Done!
          </Button>
        ) : (
          <>
            <Button disabled={saving} variant="link" onClick={onHide}>
              Cancel
            </Button>
            <Button disabled={saving} variant="secondary" onClick={handleExport}>
              <FontAwesomeIcon icon={faFileExport}/>
              Begin Export
            </Button>
          </>
        )}
      </Modal.Footer>
    </>
  );
}

PropertyOwnerExport.propTypes = {
  callListId: PropTypes.number.isRequired,
  exportPath: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  onHide: PropTypes.func.isRequired
};
