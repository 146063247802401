import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Container } from 'react-bootstrap-5';
import { isEmpty, omit } from 'lodash';

import Filters from './components/filters';
import MapScriptLoader from '../../helpers/map_script_loader';
import MobileViewSwitcher from './components/mobile_view_switcher';
import ResultAndMapViewer from './components/result_and_map_viewer';
import SaveSearchModal from './components/filters/save_search_modal';

import configureStore from './store/configure';

import { DEFAULT_UI } from './store/reducers/ui';
import {
  loadCriteria,
  loadOffMarketSearchFlag,
  loadOffMarketTableViewFlag
} from 'bundles/connect/helpers/local_storage';
import { getUrlParam, getUrlParams } from './helpers/url_helper';
import { EXCLUSIVE_LISTING_URL_PARAM, OFF_MARKET_URL_PARAM } from './components/property_offcanvas_container';

export default class Search extends React.Component {
  static propTypes = {
    nominatimUrl: PropTypes.string.isRequired,
    offMarketSearch: PropTypes.bool,
    products: PropTypes.shape({
      prospecting: PropTypes.bool.isRequired,
      exclusiveListings: PropTypes.bool
    }).isRequired,
    profile: PropTypes.shape({
      id: PropTypes.number,
      location: PropTypes.array,
      userType: PropTypes.string,
      defaultMapType: PropTypes.string,
      residential: PropTypes.bool
    }).isRequired,
    profileSavedSearch: PropTypes.bool.isRequired,
    searchCriteria: PropTypes.object,
    user: PropTypes.object
  };

  constructor(props) {
    super(props);

    const bc = new BroadcastChannel('connect_search');
    bc.onmessage = e => (e.data == 'RELOAD') && window.location.reload();

    const listingId = getUrlParam('exclusive_listing_id');
    const placeId = getUrlParam('place_id');
    const associatedSearch = getUrlParam('associated_search');
    const query = omit(
      getUrlParams(),
      ['place-id', EXCLUSIVE_LISTING_URL_PARAM, OFF_MARKET_URL_PARAM, 'search_criteria', 'associated_search']
    );

    let searchCriteria = props.searchCriteria || loadCriteria()?.criteria || {};
    searchCriteria = isEmpty(query) ? searchCriteria : query;

    if (associatedSearch) {
      searchCriteria.assetClassIds = [1, 2, 3, 5, 6, 7, 8, 10];
    }

    if (isEmpty(searchCriteria)) {
      searchCriteria = {};
      if (!isEmpty(props.profile.location)) {
        searchCriteria.mapBounds = props.profile.location.boundingBox;
      }

      if (props.profile.residential) {
        searchCriteria.assetClassIds = [10];
      }
    }

    const criteria = placeId ? {} : searchCriteria;

    let offMarketSearch = true;

    if (props.profile.userType !== 'broker') {
      // Brokers only have access to offMarketSearch, so ignore any stored flags
      offMarketSearch = props.offMarketSearch !== null ? props.offMarketSearch : loadOffMarketSearchFlag();
    }

    const initialState =  {
      criteria,
      ui: {
        ...DEFAULT_UI,
        products: props.products,
        profileSavedSearch: props.profileSavedSearch,
        showMap: false,
        showTableView: loadOffMarketTableViewFlag(),
        offMarketSearch,
        listingId,
        profile: props.profile,
        user: props.user
      }
    };

    this.store = configureStore(initialState, { placeId });
  }

  render() {
    return (
      <MapScriptLoader>
        <Provider store={this.store}>
          <div className="position-absolute w-100 h-100 d-flex flex-column bg-white">
            <div className="d-none d-md-block">
              <Container fluid>
                <h3 className="mt-3 mb-1">Search</h3>
              </Container>
            </div>

            <Container className="d-flex flex-column flex-grow-1 overflow-hidden" fluid>
              <Filters />
              <ResultAndMapViewer nominatimUrl={this.props.nominatimUrl} />
              <MobileViewSwitcher />
              <SaveSearchModal />
            </Container>
          </div>
        </Provider>
      </MapScriptLoader>
    );
  }
}
