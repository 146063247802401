import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Form, Modal, Row, Nav, Tab, InputGroup } from 'react-bootstrap-5';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeDollar, faBuilding, faBuildingMemo, faDollar, faPercent } from '@fortawesome/pro-solid-svg-icons';

import { setFilterAttribute } from '../../store/actions/criteria';

import PropertyTypeInput from './inputs/property_type_input';
import BuildingClassInput from './inputs/building_class_input';
import MultiSelectInput from './inputs/multi_select_input';
import OnMarketDurationToggle from './inputs/on_market_duration_toggle';
import OpportunityZoneToggleInput from './inputs/opportunity_zone_toggle_input';
import RangeInput from './inputs/range_input';
import RegionInput from './inputs/region_input';
import Toggle from './inputs/toggle';

import { Filter } from './modal_components';
import { INVESTMENT_TYPE_MAP } from '../../../../constants';

function ConnectFiltersModal({
  criteria,
  setFilterAttribute,
  show,
  onHide
}) {
  return (
    <Modal
      className="search-filters"
      fullscreen="sm-down"
      show={show}
      size="lg"
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white p-0">
        <Tab.Container defaultActiveKey="property-information">
          <Row className="gx-0">
            <Col className="overflow-x-auto" md="auto" sm={12}>
              <Nav className="flex-md-column flex-nowrap nav-accent p-4" variant="pills">
                <Nav.Item className="text-nowrap">
                  <Nav.Link className="text-truncate text-start" eventKey="property-information">
                    <FontAwesomeIcon className="me-2" icon={faBuildingMemo} />
                    Property Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-nowrap">
                  <Nav.Link className="text-truncate text-start" eventKey="investment-details">
                    <FontAwesomeIcon className="me-2" icon={faBadgeDollar} />
                    Investment Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="text-nowrap">
                  <Nav.Link className="text-truncate text-start" eventKey="building-details">
                    <FontAwesomeIcon className="me-2" icon={faBuilding} />
                    Building Details
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md sm={12}>
              <Tab.Content className="p-4">
                <Tab.Pane eventKey="property-information">
                  <div className="d-flex flex-column gap-3">
                    <Filter className="border rounded overflow-hidden" title="Property Type">
                      <PropertyTypeInput />
                    </Filter>
                    <Filter title="Price">
                      <RangeInput
                        addOn={<FontAwesomeIcon icon={faDollar} />}
                        maxAttribute="maxSalePrice"
                        minAttribute="minSalePrice"
                        placeholder=""
                      />
                    </Filter>
                    <Filter>
                      <Toggle
                        checked={!!criteria.recentPriceReduction}
                        label="Recent Price Reduction"
                        onClick={() => setFilterAttribute('recentPriceReduction', !criteria.recentPriceReduction)}
                      />
                    </Filter>
                    <Filter title="Cap Rate">
                      <RangeInput
                        addOn={<FontAwesomeIcon icon={faPercent} />}
                        maxAttribute="maxCapRate"
                        minAttribute="minCapRate"
                        percent={true}
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Region">
                      <RegionInput />
                    </Filter>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="investment-details">
                  <div className="d-flex flex-column gap-3">
                    <Filter bordered title="Investment Type">
                      <MultiSelectInput criteriaKey="investmentTypeIds" options={INVESTMENT_TYPE_MAP} />
                    </Filter>
                    <Filter title="Property Use">
                      <Form.Select
                        className={classNames('as-button', criteria.propertyUse ? 'active' : 'text-muted')}
                        value={criteria.propertyUse}
                        onChange={e => setFilterAttribute('propertyUse', e.target.value, { int: true })}
                      >
                        <option value={''}>Any</option>
                        <option value={1}>Net Lease Investment (NNN)</option>
                        <option value={2}>Investment</option>
                        <option value={3}>Owner / User</option>
                        <option value={4}>Business for Sale</option>
                        <option value={5}>Development</option>
                      </Form.Select>
                    </Filter>
                    <Filter title="Tenancy">
                      <Form.Select
                        className={classNames('as-button', criteria.tenancyId ? 'active' : 'text-muted')}
                        value={criteria.tenancyId}
                        onChange={e => setFilterAttribute('tenancyId', e.target.value, { int: true })}
                      >
                        <option value={''}>Any</option>
                        <option value={1}>Single</option>
                        <option value={2}>Multiple</option>
                      </Form.Select>
                    </Filter>
                    <Filter>
                      <OpportunityZoneToggleInput />
                    </Filter>
                    <Filter>
                      <OnMarketDurationToggle />
                    </Filter>
                    <Filter>
                      <Toggle
                        checked={!!criteria.includeUnderContract}
                        label="Include Under Contract"
                        onClick={() => setFilterAttribute('includeUnderContract', !criteria.includeUnderContract)}
                      />
                    </Filter>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="building-details">
                  <div className="d-flex flex-column gap-3">
                    <Filter title="Building SF">
                      <RangeInput
                        maxAttribute="maxBuildingSizeSf"
                        minAttribute="minBuildingSizeSf"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Number of Units">
                      <RangeInput
                        maxAttribute="maxNumberOfUnits"
                        minAttribute="minNumberOfUnits"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Year Built">
                      <RangeInput
                        maxAttribute="maxYearBuilt"
                        minAttribute="minYearBuilt"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Lot Size (Acres)">
                      <RangeInput
                        maxAttribute="maxLotSizeAcres"
                        minAttribute="minLotSizeAcres"
                        placeholder=""
                      />
                    </Filter>
                    <Filter title="Dock High Doors">
                      <RangeInput
                        maxAttribute="maxDockHighDoors"
                        minAttribute="minDockHighDoors"
                        placeholder=""
                      />
                    </Filter>
                    <Filter bordered title="Building Class">
                      <BuildingClassInput />
                    </Filter>
                    <Filter title="Max Vacancy">
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPercent} />
                        </InputGroup.Text>
                        <Form.Control
                          className={criteria.maxVacancyPct ? 'active' : ''}
                          placeholder=""
                          step="0.1"
                          type="number"
                          value={criteria.maxVacancyPct || ''}
                          onChange={e => setFilterAttribute('maxVacancyPct', e.target.value, { percent: true })}
                        />
                      </InputGroup>
                    </Filter>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer className="p-2">
        <Button variant="text-primary" onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

ConnectFiltersModal.propTypes = {
  criteria: PropTypes.object.isRequired,
  setFilterAttribute: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria }) => ({ criteria });

export default connect(mapStateToProps, { setFilterAttribute })(ConnectFiltersModal);
