import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap-5';
import { faFileExport, faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CallListExportModal from './modal';
import ExportUnavailable from './export_unavailable';


export default function CallListExport({ disabled, callListId, exportPath, templates }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ExportUnavailable disabled={disabled}>
        <div>
          <Button
            disabled={!!disabled}
            size="sm"
            variant="link"
            onClick={disabled ? undefined : () => setShowModal(true)}
          >
            <FontAwesomeIcon icon={disabled ? faLock : faFileExport}/>
            Export
          </Button>
        </div>

      </ExportUnavailable>
      <CallListExportModal
        callListId={callListId}
        exportPath={exportPath}
        show={showModal}
        templates={templates}
        onHide={() => setShowModal(false)}
      />
    </>
  );
}

CallListExport.propTypes = {
  callListId: PropTypes.number.isRequired,
  disabled: PropTypes.string,
  exportPath: PropTypes.string.isRequired,
  templates: PropTypes.array.isRequired
};
