import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { OverlayTrigger, Popover } from 'react-bootstrap-5';
import { faLock } from '@fortawesome/pro-regular-svg-icons';


export default function ExportUnavailable({ children, disabled }) {
  if (!disabled) return children;

  return (
    <OverlayTrigger
      key="locked-popover"
      overlay={
        <Popover id="popover-contained">
          <Popover.Body>
            <div className="d-flex align-items-center mb-2">
              <FontAwesomeIcon icon={faLock} />
              <h6 className="m-0 ps-2">Upgrade Your Account</h6>
            </div>
            {disabled}
          </Popover.Body>
        </Popover>
      }
      placement="bottom"
    >
      {children}
    </OverlayTrigger >
  );
}

ExportUnavailable.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.string
};
