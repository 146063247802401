import React from 'react';
import { Button, Col, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleHistoryCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import PropTypes from 'prop-types';
import AddToList from '../properties/add_to_list';
import PropertyBookmark from '../search/components/results/property_bookmark';
import { PROPERTY_MODEL } from '../../constants';

export default function PropertyActions({
  bookmarked,
  insightsPropertyId,
  products
}) {
  return (
    <Row className="gx-2">
      {products.prospecting && (
        <Col xs="auto">
          <PropertyBookmark bookmarked={bookmarked} id={insightsPropertyId}/>
        </Col>
      )}
      {products.prospecting && (
        <Col xs="auto">
          {products.prospecting && (
            <AddToList
              itemCount={1}
              itemIds={new Set([insightsPropertyId])}
              itemType={PROPERTY_MODEL}
              tooltip={false}
            >
              <Button variant="secondary">
                <FontAwesomeIcon className="me-1" icon={faRectangleHistoryCirclePlus}/> Add To List
              </Button>
            </AddToList>
          )}
        </Col>
      )}
    </Row>
  );
}

PropertyActions.propTypes = {
  bookmarked: PropTypes.bool.isRequired,
  insightsPropertyId: PropTypes.string.isRequired,
  products: PropTypes.shape({
    prospecting: PropTypes.bool.isRequired
  }).isRequired
};
