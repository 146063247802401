import { CLEAR_CRITERIA, UPDATE_CRITERIA, TOGGLE_ASSET_CLASS } from '../actions/criteria';
import { PERSISTANT_ATTRIBUTES } from '../../components/filters/constants';
import { pick } from 'lodash';
import { SET_OFF_MARKET_SEARCH } from '../actions/ui';

export default function(state = {}, action) {
  switch (action.type) {
  case UPDATE_CRITERIA:
    return { ...state, ...action.payload };
  case SET_OFF_MARKET_SEARCH:
    if (!action.payload) {
      // When action.payload is false, it means the user is switching to exclusive listings.
      // When this happens, remove residential from the chosen property types because residential is not supported
      const existingAssetClassIds = state.assetClassIds || [];
      const newState = { ...state };

      newState.assetClassIds = existingAssetClassIds.filter(val => val !== 10);
      return newState;
    } else {
      return state;
    }
  case TOGGLE_ASSET_CLASS: {
    const { id, toggle } = action.payload;
    const existingAssetClassIds = state.assetClassIds || [];
    const newState = { ...state };

    if (toggle) {
      newState.assetClassIds = [...existingAssetClassIds, id];
    } else {
      newState.assetClassIds = existingAssetClassIds.filter(val => val !== id);
    }

    return newState;
  }
  case CLEAR_CRITERIA: {
    return pick(state, PERSISTANT_ATTRIBUTES);
  }
  default:
    return state;
  }
}
