import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap-5';
import ContactMethod from './contact_method';
import ActionsDropdown from './actions_dropdown';

import ContactSection from './contact_section';
import { CONTACT_MODEL } from '../../../constants';
import ConnectTagManager from '../../tags/connect_tag_manager';
import ButtonGroupRadio from '../activity_forms/button_group_radio';
import { setVerifiedOwner } from '../store/actions/owners';
import { connect } from 'react-redux';
import OwnerNameDisplay from '../../off_market_properties/owners/owner-name-display';

const VERIFIED_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

function Ownership({ owner, renderLogActivity, setVerifiedOwner, showOwnerName }) {
  const { contact } = owner;

  return (
    <ListGroup.Item className="pb-4">
      <div className="d-flex flex-column gap-3">
        {showOwnerName && <OwnerNameDisplay label={owner.label} name={contact.name} />}
        <div className="d-flex align-items-center justify-content-between">
          <ConnectTagManager
            initialTags={owner.contact.tags}
            recordId={owner.contact.id}
            recordType={CONTACT_MODEL}
          />
          <ActionsDropdown owner={owner} />
        </div>
        {owner.id && (
          <div className="d-flex align-items-center justify-content-between">
            <div className="fw-bolder">
              Is this a verified owner?
            </div>
            <ButtonGroupRadio
              checked={owner.verified}
              name={`verified_owner_${owner.id}`}
              options={VERIFIED_OPTIONS}
              onChange={verified => setVerifiedOwner(owner.id, verified)}
            />
          </div>
        )}
        <div className="d-flex flex-column gap-3">
          {contact.phoneNumbers.length > 0 && (
            <ContactSection defaultOpen={true} title="Phone Numbers">
              <ContactMethod
                contactMethodKey="phoneNumbers"
                owner={owner}
                renderDisplayName={(phoneNumber, textStyle) => (
                  <div className={`d-flex align-items-center flex-wrap ${textStyle}`}>
                    <a className="privacy-mask" href={`tel:${phoneNumber.number}`} target="_top">
                      {phoneNumber.formatted}
                    </a>
                    <small className="ms-1 text-muted fw-normal">{phoneNumber.label}</small>
                  </div>
                )}
                renderLogActivity={renderLogActivity}
              />
            </ContactSection>
          )}
          {contact.emails.length > 0 && (
            <ContactSection defaultOpen={true} title="Email Addresses">
              <ContactMethod
                contactMethodKey="emails"
                owner={owner}
                renderDisplayName={(email, textStyle) => (
                  <a
                    className={`text-truncate privacy-mask ${textStyle}`}
                    href={`mailto:${email.email}`}
                    target="_top"
                  >
                    {email.email}
                  </a>
                )}
                renderLogActivity={renderLogActivity}
              />
            </ContactSection>
          )}
          {contact.addresses.length > 0 && (
            <ContactSection title="Addresses">
              <ContactMethod
                contactMethodKey="addresses"
                owner={owner}
                renderDisplayName={(address, textStyle) => (
                  <div className={`${textStyle} lh-sm privacy-mask`}>
                    <div>{address.twoLine[0]}</div>
                    <div>{address.twoLine[1]}</div>
                  </div>
                )}
              />
            </ContactSection>
          )}
        </div>
      </div>
    </ListGroup.Item>
  );
}

Ownership.propTypes = {
  owner: PropTypes.shape({
    id: PropTypes.number,
    contact: PropTypes.shape({
      addresses: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        twoLine: PropTypes.arrayOf(PropTypes.string).isRequired
      })).isRequired,
      emails: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired
      })).isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired
      })).isRequired
    }).isRequired
  }),
  renderLogActivity: PropTypes.func,
  setVerifiedOwner: PropTypes.func.isRequired,
  showOwnerName: PropTypes.bool.isRequired
};

export default connect(null, { setVerifiedOwner })(Ownership);
