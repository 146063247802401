import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row } from 'react-bootstrap-5';

import { STATES } from 'components/state_select';
import Controls from './controls';
import pluralize from 'pluralize';
import Select from 'react-select';
import { map } from 'lodash';

export default function CountySelect({ counties, profile, saving, onBack, onSave, onValueChange }) {
  const submit = (e) => {
    e.preventDefault();
    onSave();
  };

  const isDisabled = () => {
    return profile.regions.some(region => !region.state || !region.county) || saving;
  };

  const handleStateChange = (state, regionId) => {
    onValueChange(regionId, 'state', state);
    onValueChange(regionId, 'county', undefined);
  };

  const stateOptions = useMemo(() => map(STATES, (k, v) => ({ label: `${v} - ${k}`, value: v })), []);

  return (
    <>
      <Form onSubmit={submit}>
        <Row className="gap-3 flex-column">
          <Col>
            <Row>
              <Col className="fw-bolder">
                {profile.regions.length === 1 ? 'Residential' : 'Regional'} Plan
              </Col>
              <Col className="text-end">
                {profile.regions.length} {pluralize('County', profile.regions.length)} remaining
              </Col>
            </Row>
            <small className="text-muted">Your Selected Plan</small>
          </Col>
          {profile.regions.map(region => (
            <Col key={region.id}>
              <Row>
                <Col xs={6}>
                  <Form.Label>State *</Form.Label>
                  <Select
                    options={stateOptions}
                    value={region.state ? { value: region.state, label: STATES[region.state] } : null}
                    onChange={({ value }) => handleStateChange(value, region.id)}
                  />
                </Col>
                <Col xs={6}>
                  <Form.Label>County *</Form.Label>
                  <Select
                    options={(counties[region.state] || []).map(county => ({ value: county, label: county }))}
                    value={region.county ? { value: region.county, label: region.county } : null}
                    onChange={({ value }) => onValueChange(region.id, 'county', value)}
                  />
                </Col>
              </Row>
            </Col>
          ))}
          <Col>
            <Controls
              disabled={isDisabled()}
              saving={saving}
              showSave
              onBack={onBack}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}

CountySelect.propTypes = {
  counties: PropTypes.object.isRequired,
  profile: PropTypes.shape({
    companyName: PropTypes.string
  }).isRequired,
  saving: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired
};
