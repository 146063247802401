// NOTE: Importing Turbo without globally disabling Drive breaks most of Buildout, but Prospect
//       actually needs Drive enabled. So, take care to only import this API helper file in places
//       it is safe to do so.
import { Turbo } from '@hotwired/turbo-rails';

import * as apiHelpers from './api';

export function deleteRequest(path, options = {}) {
  return makeRequest('deleteRequest', path, options);
}

export function get(path, options = {}) {
  return makeRequest('get', path, options);
}

export function put(path, body, options = {}) {
  return makeRequest('put', path, body, options);
}

export function post(path, body, options = {}) {
  return makeRequest('post', path, body, options);
}

export function patch(path, body, options = {}) {
  return makeRequest('patch', path, body, options);
}

async function makeRequest(method, ...args) {
  const options = args[args.length - 1];
  options.headers = options.headers || {};
  options.headers.Accept = 'text/vnd.turbo-stream.html';

  const response = await apiHelpers[method](...args);

  Turbo.renderStreamMessage(response);
  return response;
}
