import React, { useCallback, useEffect, useState } from 'react';

import { Button, Card, Col, Form, InputGroup, ListGroup, OverlayTrigger, Popover, Row } from 'react-bootstrap-5';
import { createTag, deleteTag, fetchTags, updateTag } from '../../helpers/prospect_api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPencil, faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { debounce } from 'lodash';
import { formatShortDate } from '../../helpers/date_helpers';
import { displayToast } from '../../helpers/display_toast';
import pluralize from 'pluralize';
import TagModal from './tag_modal';
import EditTagPopover from './edit_tag_popover';

export default function TagSettings() {
  const [tags, setTags] = useState([]);
  const [searchString, setSearchString] = useState('');
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tagForModal, setTagForModal] = useState();
  const [editPopover, setEditPopover] = useState({ show: false });
  const [tagInput, setTagInput] = useState('');

  useEffect(() => {
    fetchTags(searchString).then((response) => {
      setTags(response['connect/tags']);
    });
  }, []);

  const debouncedSearch = useCallback(
    debounce((inputString) => {
      fetchTags(inputString).then(response => setTags(response['connect/tags']));
    }, 300),
    []
  );

  useEffect(() => {
    debouncedSearch(searchString);
  }, [searchString]);

  const handleRemove = (tag) => {
    if (confirm(`Delete tag "${tag.name}"?`)) {
      deleteTag(tag.id).then(() => {
        setTags(tags.filter(t => t.id != tag.id));
      });
    }
  };

  const handleUpdate = (id, name) => {
    updateTag(id, name).then((response) => {
      const updatedTag = response['connect/tag'];
      setTags(prevTags => prevTags.map(tag => tag.id === updatedTag.id ? updatedTag : tag));
      displayToast('Tag has been updated!', 'success');
      setEditPopover({ show: false });
    }).catch(() => displayToast('Something went wrong', 'error'));
  };

  const handleEdit = (e, tag) => {
    setEditPopover({
      show: true,
      anchor: e.currentTarget,
      tag: tag
    });
  };

  const handleCreate = () => {
    createTag(tagInput).then((response) => {
      setTags([
        ...tags,
        response['connect/tag']
      ]);
      setShowOverlay(false);
      displayToast('Tag Created!', 'success');
    });
  };

  const handleOpenModal = useCallback((tag) => {
    setShowModal(true);
    setTagForModal(tag);
  }, []);

  const popover = (
    <Popover className="border" style={{ width: '400px' }} onClick={e => e.stopPropagation()}>
      <Popover.Header className="bg-white border-bottom">
        <h6 className="fw-bolder m-0">New Tag</h6>
      </Popover.Header>
      <Popover.Body className="px-0 pb-0">
        <Form.Group className="mb-2 px-3 pb-2">
          <Form.Label>Tag Name</Form.Label>
          <Form.Control type="text" onChange={e => setTagInput(e.target.value)}/>
        </Form.Group>
        <div className="border-top d-flex align-items-center justify-content-end p-2">
          <Button variant="link" onClick={() => setShowOverlay(false)}>Cancel</Button>
          <Button variant="secondary" onClick={handleCreate}>
            Create Tag
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Card.Header className="p-6">
        <Row className="p-0">
          <Col><h4 className="my-0">Tags</h4></Col>
          <Col xs="auto">
            <OverlayTrigger
              overlay={popover}
              placement="bottom"
              rootClose
              show={showOverlay}
              trigger="click"
              onToggle={show => setShowOverlay(show)}
            >
              <Button variant="secondary" onClick={() => {}}>
                <FontAwesomeIcon icon={faPlus}/>
                New Tag
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-3">
        <Form.Group className="mb-2">
          <Form.Label>Search tags</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faMagnifyingGlass}/>
            </InputGroup.Text>
            <Form.Control
              placeholder="Search tags..."
              type="text"
              value={searchString}
              onChange={e => setSearchString(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
        <ListGroup variant="flush">
          {tags.map(tag => (
            <ListGroup.Item  key={tag.name}>
              <Row className="align-items-center">
                <Col>
                  <div
                    className="fw-bolder d-inline"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleOpenModal(tag.name)}
                  >
                    {tag.name}
                  </div>
                  <small className="text-muted">
                    <div className="d-flex flex-wrap column-gap-1">
                      <div>
                        {tag.tagCount} {pluralize('Record', tag.tagCount)} tagged
                      </div>
                      <span>•</span>
                      <div>
                        Created on {formatShortDate(tag.createdAt)}
                      </div>
                      <span>•</span>
                      <div>
                        Last modified on {formatShortDate(tag.updatedAt)}
                      </div>
                    </div>
                  </small>
                </Col>
                <Col xs="auto">
                  <Button className="text-link" variant="link" onClick={e => handleEdit(e, tag)}>
                    <FontAwesomeIcon icon={faPencil} />
                    Edit
                  </Button>
                  <Button className="text-danger" variant="link" onClick={() => handleRemove(tag)}>
                    <FontAwesomeIcon icon={faTrash} />
                    Delete
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
      <TagModal showModal={showModal} tagForModal={tagForModal} onHide={() => setShowModal(false)}/>
      {editPopover.show && (
        <EditTagPopover
          {...editPopover}
          onCancel={() => setEditPopover({ show: false })}
          onUpdate={handleUpdate}
        />
      )}
    </>
  );
}
