import { combineReducers } from 'redux';
import criteria from './reducers/criteria';
import results from './reducers/results';
import ui from './reducers/ui';

export default combineReducers({
  criteria,
  results,
  ui,
});
