import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';

import { LIKELY_TO_SELL_RANK_TO_LIKELIHOOD } from '../filters/constants';
import { Badge, Col, Row } from 'react-bootstrap-5';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';

export default function ScoreBadges({ scores }) {
  const likelyToSell = scores?.likelyToSell;
  const likelyToRefi = scores?.likelyToRefi;
  if (!likelyToSell?.restricted && !likelyToSell?.rank && !likelyToRefi?.rank && !likelyToRefi?.restricted) return null;

  return (
    <Row className="d-flex g-1">
      <ScoreBadge badgeClass="likely-seller" label="Sell" score={likelyToSell} />
      <ScoreBadge badgeClass="likely-refi" label="ReFi" score={likelyToRefi} />
    </Row>
  );
}

ScoreBadges.propTypes = {
  scores: PropTypes.shape({
    likelyToSell: PropTypes.object,
    likelyToRefi: PropTypes.object,
  })
};

export function ScoreBadge({ badgeClass, label, score }) {
  let badge = null;

  if (score?.restricted) badge = (
    <div
      className="d-flex align-items-center gap-2 py-1 px-2 fw-bold small"
      style={{ backgroundColor: '#7422ce', color: '#f2eafd' }}
    >
      <FontAwesomeIcon icon={faLock} />
      Unlock Likely {label} Score
    </div>
  );

  if (LIKELY_TO_SELL_RANK_TO_LIKELIHOOD[score?.rank]) badge = (
    <Badge bg="" className={`likely-seller text-dark fw-normal ${badgeClass}`} style={{ fontSize: '0.875rem' }}>
      <FontAwesomeIcon icon={faSparkles} />
      {LIKELY_TO_SELL_RANK_TO_LIKELIHOOD[score.rank]} To {label}
    </Badge>
  );

  return badge ? <Col xs="auto">{badge}</Col> : null;
}

ScoreBadge.propTypes = {
  badgeClass: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  score: PropTypes.shape({
    rank: PropTypes.number,
    restricted: PropTypes.bool
  })
};
