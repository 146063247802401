import PropTypes from 'prop-types';
import listingPropTypes, { listingShape } from 'components/listing/prop_types';
import { ALL_BOOST_STATUSES } from 'bundles/project/components/boost/constants';

export const LeaseSpaceListingPropTypes = PropTypes.shape({
  ...listingShape,
  address2: PropTypes.string.isRequired,
  address2Label: PropTypes.string,
  dateAvailable: PropTypes.string,
  leaseStatusLabel: PropTypes.string,
  spaceAvailable: PropTypes.string,
  spaceSizeLabel: PropTypes.string,
  tenantName: PropTypes.string
});

export const ProjectContactsPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  link: PropTypes.string,
  header: PropTypes.string,
  initials: PropTypes.string,
  type: PropTypes.string
});

export const ProjectDatabaseMemberPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  removeable: PropTypes.bool,
  role: PropTypes.string
});

export const ProjectPropTypes = PropTypes.shape({
  boostStatus: PropTypes.oneOf(ALL_BOOST_STATUSES),
  bulkDownloadPhotosLink: PropTypes.string.isRequired,
  canViewUnreleasedFeatures: PropTypes.bool,
  clientReportCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      cardType: PropTypes.string.isRequired,
      content: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      visibleToClient: PropTypes.bool.isRequired
    }).isRequired
  ),
  clientReportShareableTokens: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired
    })
  ),
  clientCustomizations: PropTypes.shape({
    hasDealStatusLabelDisabled: PropTypes.bool
  }),
  collaborators: PropTypes.arrayOf(ProjectDatabaseMemberPropTypes).isRequired,
  contacts: PropTypes.arrayOf(ProjectContactsPropTypes),
  defaultAssociatedModel: PropTypes.shape({
    associatedModelId: PropTypes.number.isRequired,
    associatedModelType: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    default: PropTypes.bool.isRequired
  }),
  databaseContactRoles: PropTypes.arrayOf(PropTypes.shape({
    contact: PropTypes.shape({
      contactType: PropTypes.string,
      email: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      phoneFormatted: PropTypes.string,
      referralSourceLabel: PropTypes.string
    }),
    id: PropTypes.number,
    relationship: PropTypes.string,
    relationshipLabel: PropTypes.string
  })),
  editLink: PropTypes.string,
  hidePropertyButtons: PropTypes.bool,
  forwardingEmail: PropTypes.string,
  id: PropTypes.number,
  leaseSpaceListings: PropTypes.arrayOf(LeaseSpaceListingPropTypes),
  listing: listingPropTypes,
  name: PropTypes.string,
  otherAuthorizedUsers: PropTypes.arrayOf(ProjectDatabaseMemberPropTypes).isRequired,
  propertyLink: PropTypes.string,
  proposal: PropTypes.bool,
  researchProperty: PropTypes.object,
  rootMarketingDocumentFolderId: PropTypes.number,
  rootNonMarketingDocumentFolderId: PropTypes.number,
  status: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  userIsOutsideBroker: PropTypes.bool,
  useVaultGates: PropTypes.bool
});

export const LeadPropTypes = PropTypes.shape({
  contactCompany: PropTypes.string,
  contactCompanyPath: PropTypes.string,
  contactId: PropTypes.number,
  contactType: PropTypes.string,
  contactPath: PropTypes.string,
  createdAt: PropTypes.string,
  displayName: PropTypes.string,
  emailAddress: PropTypes.string,
  id: PropTypes.number,
  lastActivityDate: PropTypes.string,
  lastActivityType: PropTypes.string,
  leadData: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    initials: PropTypes.string,
    phone: PropTypes.string
  }),
  leaseVault: PropTypes.shape({
    id: PropTypes.number,
    accessLevels: PropTypes.arrayOf(PropTypes.string)
  }),
  listingId: PropTypes.number,
  listingLabel: PropTypes.string,
  listingStatus: PropTypes.shape({
    pillClass: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  phoneNumber: PropTypes.string,
  projectAddress: PropTypes.arrayOf(PropTypes.string),
  projectUsers: PropTypes.arrayOf(PropTypes.object),
  projectName: PropTypes.string,
  projectPath: PropTypes.string,
  referralSource: PropTypes.number,
  referralSourceLabel: PropTypes.string,
  saleVault: PropTypes.shape({
    id: PropTypes.number,
    accessLevels: PropTypes.arrayOf(PropTypes.string)
  }),
  source: PropTypes.string,
  status: PropTypes.number,
  statusLabel: PropTypes.string,
  statusUpdatedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  user: PropTypes.shape({
    name: PropTypes.string,
    imageUrl: PropTypes.string
  }),
  webStatus: PropTypes.string
});
