import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap-5';
import { get } from 'helpers/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import PDF from './pdf';
import axios from 'axios';

export default function BODoc({ file }) {
  const [renderedUrl, setRenderedUrl] = React.useState(null);
  const [showError, setShowError] = React.useState(false);

  React.useEffect(() => {
    let timer;
    const requestController = new AbortController();
    let didError = false;

    const checkRender = () => {
      get(file.url, { signal: requestController.signal }).then((data) => {
        if (data.rendering) {
          timer = setTimeout(checkRender, 500);
        } else {
          setRenderedUrl(data.url);
        }
      }).catch((err) => {
        if (axios.isCancel(err)) return;

        if (didError) {
          setShowError(true);
        } else {
          didError = true;
          timer = setTimeout(checkRender, 500);
        }
      });
    };

    checkRender();

    return () => {
      if (timer) clearTimeout(timer);
      if (requestController) requestController.abort();
    };
  }, [file]);

  if (showError) {
    return (
      <React.Fragment>
        <Row className="justify-content-center py-6 gx-0 my-auto text-center">
          <Col className="connect-card-container" lg={10} xl={8} xxl={7}>
            <Card className="overflow-hidden connect-card">
              <Card.Body className="p-8">
                <h4 className="mb-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon className="me-2 text-danger" icon={faExclamationCircle}/>
                  <strong>An Error occurred</strong>
                </h4>
                <p className="m-0">
                  We were unable to create a fresh copy of <strong>{file.name}</strong>.
                  Our team has been alerted and are working on fixing this
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="mt-auto"/>
      </React.Fragment>
    );
  }

  if (!renderedUrl) {
    return (
      <React.Fragment>
        <Row className="justify-content-center py-6 gx-0 my-auto my-auto text-center" name="rendering-message">
          <Col className="connect-card-container" lg={10} xl={8} xxl={7}>
            <Card className="overflow-hidden connect-card">
              <Card.Body className="p-8">
                <h4 className="mb-4 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon className="me-2 text-muted" icon={faCog} spin/>
                  <strong>Rendering...</strong>
                </h4>
                <p className="m-0">
                  We're making a fresh copy of <strong>{file.name}</strong> just for you.
                  This could take a minute. We will automatically display it here when it is ready.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="mt-auto"/>
      </React.Fragment>
    );
  }

  return (
    <PDF file={{ url: renderedUrl }}/>
  );
}

BODoc.propTypes = {
  file: PropTypes.shape({
    url: PropTypes.string
  })
};
