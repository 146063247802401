import React from 'react';
import { Modal } from 'react-bootstrap-5';

import PropTypes from 'prop-types';

export default function ImportModalComplete({
  callListId,
  handleClose,
}) {
  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Upload Complete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Your upload has been completed and processed.
          You can begin your outreach on those properties right away.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-secondary"
          onClick={handleClose}
        >
          Close
        </button>
        <a className="btn btn-outline-secondary"
          href={`/prospect/connect_call_lists/${callListId}`}
        >
          View List
        </a>
      </Modal.Footer>
    </React.Fragment>
  );
}

ImportModalComplete.propTypes = {
  callListId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
};
