import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, ListGroup, ListGroupItem, Row } from 'react-bootstrap-5';

import { STATES } from 'components/state_select';
import Controls from './controls';
import { countries } from 'helpers/countries';
import Select from 'react-select';
import { map } from 'lodash';

export default function ProfileInformation(
  { brokerSpecialtyOptions, investorTypeOptions, profile, saving, showSave, onSubmit, onValueChange }
) {
  const investorTypes = profile.investorTypes || [];
  const brokerSpecialties = profile.brokerSpecialties || [];
  const stateOptions = useMemo(() => map(STATES, (k, v) => ({ label: `${v} - ${k}`, value: v })), []);
  const countryOptions = useMemo(() => map(countries, country => ({ label: country.label, value: country.id })), []);
  const submit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <>
      <Form onSubmit={submit}>
        <Form.Label>Company Name *</Form.Label>
        <Row className="mb-3 align-items-center gy-4">
          <Col xs={10}>
            <Form.Control
              autoFocus
              placeholder="Company Name"
              value={profile.companyName || ''}
              onChange={e => onValueChange('companyName', e.target.value)}
            />
          </Col>
          <Col xs="auto">
            <Form.Check
              checked={profile.companyIsSelf}
              id="company-self"
              label="Self"
              type="checkbox"
              onChange={e => onValueChange('companyIsSelf', e.target.checked)}
            />
          </Col>
        </Row>
        <Row className="mb-3 gy-4">
          <Col md={4}>
            <Form.Label>Country</Form.Label>
            <Select
              options={countryOptions}
              value={countryOptions.find(option => option.value === profile.countryId)}
              onChange={({ value }) => onValueChange('countryId', value)}
            />
          </Col>

          <Col md={4} xs={6}>
            <Form.Label>Company City</Form.Label>
            <Form.Control
              placeholder="Company City"
              value={profile.city || ''}
              onChange={e => onValueChange('city', e.target.value)}
            />
          </Col>

          {profile.countryId === 1 && (
            <Col md={4} xs={6}>
              <Form.Label>Company State *</Form.Label>
              <Select
                options={stateOptions}
                value={stateOptions.find(option => option.value === profile.state)}
                onChange={({ value }) => onValueChange('state', value)}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12}>
            {profile.userType === 'broker' && (
              <>
                <Form.Label>What are your specialties? *</Form.Label>
                <ListGroup>
                  {Object.keys(brokerSpecialtyOptions).map(brokerSpecialtyKey => (
                    <ListGroupItem key={brokerSpecialtyKey}>
                      <Form.Check
                        checked={brokerSpecialties.includes(brokerSpecialtyKey)}
                        id={brokerSpecialtyKey}
                        label={brokerSpecialtyOptions[brokerSpecialtyKey]}
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            onValueChange(
                              'brokerSpecialties',
                              [...brokerSpecialties, brokerSpecialtyKey]
                            );
                          } else {
                            onValueChange(
                              'brokerSpecialties',
                              brokerSpecialties.filter(t => t !== brokerSpecialtyKey)
                            );
                          }
                        }}
                      />
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </>
            )}

            {profile.userType !== 'broker' && (
              <>
                <Form.Label>What type of investor are you? *</Form.Label>
                <ListGroup>
                  {Object.keys(investorTypeOptions).map(investorTypeKey => (
                    <ListGroupItem className="px-2" key={investorTypeKey}>
                      <Form.Check
                        checked={investorTypes.includes(investorTypeKey)}
                        id={investorTypeKey}
                        label={investorTypeOptions[investorTypeKey]}
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            onValueChange(
                              'investorTypes',
                              [...investorTypes, investorTypeKey]
                            );
                          } else {
                            onValueChange(
                              'investorTypes',
                              investorTypes.filter(t => t !== investorTypeKey)
                            );
                          }
                        }}
                      />
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </>
            )}
          </Col>
        </Row>
        <Controls
          disabled={
            !profile.state ||
            !profile.companyName ||
            (investorTypes.length === 0 && brokerSpecialties.length === 0)
          }
          saving={saving}
          showNext={!showSave}
          showSave={showSave}
        />
      </Form>
    </>
  );
}

ProfileInformation.propTypes = {
  brokerSpecialtyOptions: PropTypes.object.isRequired,
  investorTypeOptions: PropTypes.object.isRequired,
  profile: PropTypes.shape({
    companyName: PropTypes.string
  }).isRequired,
  saving: PropTypes.bool.isRequired,
  showSave: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired
};
