import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from 'react-bootstrap-5';
import { fetchOwners } from '../../../helpers/prospect_api';
import { OWNERSHIP_UNLOCKED_EVENT } from './unlock_owners';
import pluralize from 'pluralize';

export default function RefreshOwnersModal({
  hideModal,
  insightsPropertyId,
  remainingCredits,
  setLastFetchedAt,
  setRemainingCredits,
  trialUser,
  show,
  onUnlock
}) {
  const onRefresh = () => {
    setOwners(true);
    hideModal();
  };

  const setOwners = useCallback((useCredit) => {
    fetchOwners(insightsPropertyId, useCredit, true)
      .then((results) => {
        onUnlock(results['connect/ownerships']);
        if (useCredit) {
          window.dispatchEvent(new CustomEvent(OWNERSHIP_UNLOCKED_EVENT));
          setRemainingCredits(remainingCredits - 1);
          setLastFetchedAt(new Date());
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          if (error.response.data['remaining_credits'] !== undefined) {
            setRemainingCredits(error.response.data['remaining_credits']);
          }
        }
      });
  }, [insightsPropertyId]);

  return (
    <Modal centered show={show}>
      <Modal.Header closeButton onHide={hideModal}>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>A credit will still be consumed even if no new contacts are found.</p>
        <p>You have {remainingCredits} {pluralize('credit', remainingCredits)} remaining
          {trialUser ? ' during your trial' : ''}.&nbsp;
          Unlocking this owner information will use 1 credit.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="tertiary" onClick={hideModal}>
          Cancel
        </Button>
        <Button onClick={onRefresh}>
          Refresh
        </Button>
      </Modal.Footer>
    </Modal>
  );

}

RefreshOwnersModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  insightsPropertyId: PropTypes.string.isRequired,
  remainingCredits: PropTypes.number.isRequired,
  setLastFetchedAt: PropTypes.func.isRequired,
  setRemainingCredits: PropTypes.func.isRequired,
  show: PropTypes.bool,
  trialUser: PropTypes.bool.isRequired,
  onUnlock: PropTypes.func.isRequired
};
