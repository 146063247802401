import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap-5';

import { showSaveSearchModal } from '../../store/actions/ui';
import SessionModal from '../../../session_modal';

const SaveSearchButton = ({ loggedIn, showSaveSearchModal, ...props }) => {
  const [showSessionModal, setSessionModal] = useState(false);

  const handleClick = () => loggedIn ? showSaveSearchModal(true) : setSessionModal(true);

  return (
    <>
      <Button className="rounded" variant="secondary" onClick={handleClick} {...props}>
        Save Search
      </Button>

      {showSessionModal && <SessionModal onHide={() => setSessionModal(false)} />}
    </>
  );
};

SaveSearchButton.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  showSaveSearchModal: PropTypes.func.isRequired,
};

export default connect(({ ui: { profile } }) => ({ loggedIn: !!profile?.id }), {
  showSaveSearchModal,
})(SaveSearchButton);
