import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap-5';
import ImportModalStart from './import_modal_start.js';
import ImportModalUploading from './import_modal_uploading';
import ImportModalComplete from './import_modal_complete';
import ImportModalErrors from './import_modal_errors';
import ImportModalAddressList from './import_modal_address_list';


export default function ImportListModal() {
  const [showImportContactsModal, setShowImportContactsModal] = useState(false);
  const [importId, setImportId] = useState(0);
  const [callListId, setCallListId] = useState(0);
  const [callListData, setCallListData] = useState({});
  const [showAddressModal, setShowAddressModal] = useState(false);

  const renderModalContent = () => {
    const PROPS = { handleClose };
    if (importId === 0 && callListId === 0) {
      return (
        <ImportModalStart {...PROPS} setImportId={setImportId} />
      );
    } else if (importId !== 0 && callListId === 0) {
      return (
        <ImportModalUploading {...PROPS} importId={importId}
          setCallListData={setCallListData}
          setCallListId={setCallListId}
        />
      );
    } else if (showAddressModal) {
      return (
        <ImportModalAddressList {...PROPS} callListData={callListData} callListId={callListId} />
      );
    } else if (callListData.noPropertyAddresses?.length > 0) {
      return (
        <ImportModalErrors {...PROPS} callListData={callListData}
          callListId={callListId}
          setShowAddressModal={setShowAddressModal}
        />
      );
    } else if (callListId !== 0) {
      return (
        <ImportModalComplete {...PROPS} callListId={callListId} />
      );
    }
  };

  const handleClose = (pastStart) => {
    if (pastStart) window.location.reload();
    setShowImportContactsModal(false);
    setImportId(0);
    setCallListId(0);
  };

  return (
    <>
      <Button className="me-2"
        variant="text-primary"
        onClick={() => setShowImportContactsModal(true)}
      >
        <i className="fa-solid fa-file-arrow-up" style={{ color: '#2042A7' }} />Upload
      </Button>
      <Modal centered show={showImportContactsModal} onHide={handleClose}>
        {renderModalContent()}
      </Modal>
    </>
  );
}
