import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ListGroup } from 'react-bootstrap-5';
import { connect } from 'react-redux';

import Task from '../call_list/tasks/task';
import EditTaskModal from '../call_list/tasks/edit_task_modal';
import NewTaskModal from '../call_list/tasks/new_task_modal';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { addActivity, removeActivity, updateActivity } from '../call_list/store/actions/activities';

function TaskList({ activities, addActivity, removeActivity, updateActivity }) {
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const [taskForEdit, setTaskForEdit] = useState();
  const createButtonElement = useMemo(() => document.getElementById('js-new-task-button'), []);

  return (
    <>
      {activities.length > 0 && (
        <ListGroup variant="flush">
          {activities.map(task => (
            <Task
              key={task.id}
              task={task}
              onDelete={() => removeActivity(task.id, 'Task')}
              onEdit={setTaskForEdit}
              onUpdate={updateActivity}
            />
          ))}
        </ListGroup>
      )}
      {activities.length === 0 && (
        <div className="p-4 text-center">
          <div className="my-4">
            <span className="fa-stack fa-xl">
              <i className="fa-solid fa-stack-2x fa-circle icon-gradient" />
              <i className="fa-solid fa-stack-1x fa-inverse fa-calendar" />
            </span>
          </div>
          <h6 className="mb-4 fw-bold">No tasks were found</h6>
        </div>
      )}
      <EditTaskModal
        existingActivity={taskForEdit}
        onHide={() => setTaskForEdit()}
        onUpdate={updateActivity}
      />
      <NewTaskModal
        initialConnectedRecords={[]}
        show={showNewTaskModal}
        onAdd={addActivity}
        onHide={() => setShowNewTaskModal(false)}
      />
      {createPortal((
        <Button variant="secondary" onClick={() => setShowNewTaskModal(true)}>
          <FontAwesomeIcon icon={faPlus} /> New Task
        </Button>
      ), createButtonElement)}
    </>
  );
}

TaskList.propTypes = {
  activities: PropTypes.array.isRequired,
  addActivity: PropTypes.func.isRequired,
  removeActivity: PropTypes.func.isRequired,
  updateActivity: PropTypes.func.isRequired
};

const mapStateToProps = ({ activities }) => ({ activities });
export default connect(mapStateToProps, { addActivity, removeActivity, updateActivity })(TaskList);

