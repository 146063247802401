import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { post } from 'helpers/api';
import GoogleLogo from 'logos/google-logo-standalone.png';
import LinkedInLogo from 'logos/LinkedIn-logo-standalone.png';
import HideShowPassword from 'components/form/hide_show_password';
import { PROFILE_OPTIONS, setProfileData } from '../../helpers/preview';

export function Login({
  checkSsoRedirectPath,
  hasGates,
  loginOnly,
  loginPath,
  oauthUrls,
  previewPropsForLogin,
  readonly,
  redirectTo,
  resetPasswordPath,
  onNext
}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailFound, setEmailFound] = useState();
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [fetching, setFetching] = useState(false);

  const resetForm = () => {
    setEmailFound(undefined);
    setPasswordReset(false);
  };

  const resetPassword = () => {
    post(resetPasswordPath, { email, redirectTo }).then(() => {
      setPasswordReset(true);
    });
  };

  const checkEmail = (e) => {
    e.preventDefault();
    setFetching(true);
    const params = { email, currentUrl: window.location.href, readonly };
    post(checkSsoRedirectPath, params).then((data) => {
      if (data.buildoutLogin) {
        setEmailFound(true);
        if (readonly) setEmail('test_user@testing.com');
      } else if (data.externalLoginPath) {
        window.location = data.externalLoginPath;
      }
    }).catch(() => {
      setEmailFound(false);
    }).finally(() => setFetching(false));
  };

  const login = (e) => {
    e.preventDefault();
    setFetching(true);

    post(loginPath, { email, password, readonly })
      .then(() => {
        if (readonly) {
          const updatedProfile = {
            [PROFILE_OPTIONS.PROFILE_PRESENT]: true,
            [PROFILE_OPTIONS.ATTRIBUTES]: previewPropsForLogin,
          };

          setProfileData(updatedProfile);
        }

        onNext();
      })
      .catch(() => setInvalidPassword(true))
      .finally(() => setFetching(false));
  };

  if (passwordReset) return (
    <div>
      <div className="text-center">
        <h4>
          Password reset sent to {email}!
        </h4>
        Please check your email for further instructions.
        <div className="mt-3">
          <Button name="back-to-login" variant="link" onClick={resetForm}>
            <FontAwesomeIcon className="me-1" icon={faChevronLeft}/>
            Back
          </Button>
        </div>
      </div>
    </div>
  );

  if (emailFound) return (
    <div>
      <Button name="back-to-login" variant="link" onClick={resetForm}>
        <FontAwesomeIcon className="me-1" icon={faChevronLeft}/>
        Back
      </Button>

      <form onSubmit={login}>
        <h5 className="mb-5 text-center">
          <strong>Sign in {!loginOnly && 'to view this document'}</strong>
        </h5>
        <p className="text-center">
          Enter password for <strong>{email}</strong>
        </p>
        {invalidPassword === true && (
          <p className="text-danger text-center animate__animated animate__fadeIn">
            <FontAwesomeIcon className="me-2" icon={faInfoCircle}/> Password is incorrect
          </p>
        )}

        <Form.Group className="mb-6">
          <Form.Label htmlFor="login-password">Password</Form.Label>
          <HideShowPassword
            autoFocus
            id="login-password"
            name="password"
            placeholder="Enter password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button className="w-100" name="login" type="submit" variant="primary">
          Log In
        </Button>
        <div className="text-center mt-3">
          <Button name="forgot-password" variant="link" onClick={resetPassword}>
            Forgot Password?
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <div>
      {hasGates && (
        <Button className="d-none d-lg-block" variant="link" onClick={() => onNext({ overrideStep: 'create_account' })}>
          <FontAwesomeIcon className="me-2" icon={faChevronLeft} size="lg"/>
          Back
        </Button>
      )}
      <h5 className="mb-5 text-center">
        <strong>Sign in {!loginOnly && 'to view this document'}</strong>
      </h5>
      <p className="text-center">
        Login or create a Buildout account to access secure documents.
      </p>
      <form onSubmit={checkEmail}>
        {emailFound === false && (
          <p className="text-danger animate__animated animate__fadeIn" name="email_error">
            <FontAwesomeIcon className="me-2" icon={faInfoCircle}/> Couldn't find your Buildout account.
          </p>
        )}
        <Form.Group className="mb-6">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            autoFocus
            className="login-email-field"
            name="email"
            placeholder="Enter email address"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>
        <Button className="w-100 mb-3" disabled={fetching} name="verify_email" type="submit" variant="primary">
          Next
        </Button>
      </form>
      {!hasGates && <div className="text-center">
        <Button variant="link" onClick={() => onNext({ overrideStep: 'create_account' })}>
          Create a Buildout account
        </Button>
      </div>}
      <Row className="align-items-center my-3">
        <Col>
          <hr className="m-0"/>
        </Col>
        <Col xs="auto">Or log in with</Col>
        <Col>
          <hr className="m-0"/>
        </Col>
      </Row>
      <Row>
        <Col>
          <a
            className="btn btn-outline-neutral border w-100 d-flex align-items-center justify-content-center"
            href={`${oauthUrls.google}&origin=${encodeURIComponent(window.location.href)}`}
          >
            <img className="me-2" src={GoogleLogo} style={{ height: '30px' }}/>
            <span>Google</span>
          </a>
        </Col>
        <Col>
          <a
            className="btn btn-outline-neutral border w-100 d-flex align-items-center justify-content-center"
            href={`${oauthUrls.linkedIn}&origin=${encodeURIComponent(window.location.href)}`}
          >
            <img
              className="me-2"
              src={LinkedInLogo}
              style={{ height: '26px', marginTop: '2px', marginBottom: '2px' }}
            />
            <span>LinkedIn</span>
          </a>
        </Col>
      </Row>
    </div>
  );
}

Login.propTypes = {
  checkSsoRedirectPath: PropTypes.string.isRequired,
  hasGates: PropTypes.bool.isRequired,
  loginOnly: PropTypes.bool,
  loginPath: PropTypes.string.isRequired,
  oauthUrls: PropTypes.shape({
    google: PropTypes.string.isRequired,
    linkedIn: PropTypes.string.isRequired
  }).isRequired,
  previewPropsForLogin: PropTypes.object,
  readonly: PropTypes.bool,
  redirectTo: PropTypes.string.isRequired,
  resetPasswordPath: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired
};

export const mapStateToProps = ({ preview, vault }) => (
  { oauthUrls: vault.oauthUrls, previewPropsForLogin: preview.previewPropsForLogin }
);
export default connect(mapStateToProps)(Login);
