import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { post } from 'helpers/api';
import { faCheckCircle, faClock } from '@fortawesome/pro-solid-svg-icons';
import Snackbar from 'shared/snackbar';
import { getProfileData } from '../../../helpers/preview';

const EMAIL_STATES = {
  NOT_SENT: 'not_sent',
  SENDING: 'sending',
  SENT: 'sent'
};

const BUTTON_TEXT = {
  [EMAIL_STATES.NOT_SENT]: 'Send Verification Email',
  [EMAIL_STATES.SENDING]: 'Sending Verification Email',
  [EMAIL_STATES.SENT]: 'Email Verification Sent',
};

export default function SendVerifyEmail({ email, readonly = false, sendVerificationPath }) {
  const [emailState, setEmailState] = useState(EMAIL_STATES.NOT_SENT);
  const [showSnackbar, setShowSnackbar] = useState(false);

  function sendEmail() {
    setEmailState(EMAIL_STATES.SENDING);
    const previewProfile = getProfileData();
    post(sendVerificationPath, { readonly, previewProfile }).then(() => {
      setEmailState(EMAIL_STATES.SENT);
      setShowSnackbar(true);
    });
  }

  const buttonText = BUTTON_TEXT[emailState];
  const sendEmailDisabled = [EMAIL_STATES.SENDING, EMAIL_STATES.SENT].includes(emailState);

  return (
    <div className="text-center">
      <FontAwesomeIcon className="mb-6 mt-2 text-branding" icon={faClock} size="2x"/>
      <h5 className="mb-6">
        <strong>Verify your email address</strong>
      </h5>
      <p>
        We need to verify {email} belongs to you.
        Once the verification email is sent, click “confirm” in your email to finish creating your account
      </p>
      <p className="text-muted">
        <small>
          If you don't see a message in your inbox, make sure the email address listed above is correct.
          The email will be sent from support@buildout.com
        </small>
      </p>
      <div className="text-center mt-6 mb-2">
        <Button disabled={sendEmailDisabled} variant="primary" onClick={sendEmail}>{buttonText}</Button>
      </div>
      <Snackbar
        icon={faCheckCircle}
        show={showSnackbar}
        text="Successfully sent email verification"
        variant="success"
        onClose={() => setShowSnackbar(false)}
      />
    </div>
  );
}

SendVerifyEmail.propTypes = {
  email: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  sendVerificationPath: PropTypes.string.isRequired
};
