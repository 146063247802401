import ReactDOM from 'react-dom';

export function unmountChildComponents(parentElement) {
  parentElement.querySelectorAll('.js-react-on-rails-component').forEach((element) => {
    const mountedElement = document.querySelector(`#${element.dataset.domId}`);
    if (mountedElement) {
      ReactDOM.unmountComponentAtNode(mountedElement);
    }
  });
}
