export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const PROFILE_OPTIONS = {
  ACCESS_LEVEL: 'access_level',
  ATTRIBUTES: 'attributes',
  WAITLISTED: 'waitlisted',
  EMAIL_VERIFIED: 'email_verified',
  CA_SIGNED: 'ca_signed',
  PROFILE_PRESENT: 'profile_present',
  VALID_LOGIN: 'valid_login'
};

function profileDataKey() {
  return window.location.pathname.replace('preview', '');
}

export function clearProfileData() {
  localStorage.removeItem(profileDataKey());
}

export function getProfileData(fallback = {}) {
  const profileData = localStorage.getItem(profileDataKey());
  if (profileData && typeof profileData === 'string') {
    return JSON.parse(profileData);
  }

  return fallback;
}

export function setProfileData(newProfileData) {
  localStorage.setItem(profileDataKey(), JSON.stringify({ ...getProfileData(), ...newProfileData }));
}
