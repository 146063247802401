import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';
import { setPrimaryRole } from '../../actions';
import {
  PRIMARY_ROLE_INVESTOR_OWNER,
  PRIMARY_ROLE_COMMERCIAL_BROKER,
  PRIMARY_ROLE_RESIDENTIAL_BROKER,
  PRIMARY_ROLE_OTHER,
  isValidPrimaryRole,
  labelForPrimaryRole
} from '../../actions/ui';

// TODO: probably good to collapse (or even hide) the sidebar so user isn't distracted with other flows
// TODO: save their choice (in the session?) so reloading doesn't re-prompt
export function AskUserType({ nextStep, onNext, primaryRole, setPrimaryRole }) {
  useEffect(() => {
    if (isValidPrimaryRole(primaryRole)) {
      onNext({ overrideStep: nextStep });
    }
  }, [primaryRole]);

  return (
    <div className="py-2">
      <div className="text-center">
        <h5 className="mb-4">Are you a ...</h5>
      </div>
      <div className="d-flex flex-column align-items-stretch gap-3 m-auto" style={{ maxWidth: '300px' }}>
        <Button variant="primary" onClick={() => setPrimaryRole(PRIMARY_ROLE_INVESTOR_OWNER)}>
          {labelForPrimaryRole(PRIMARY_ROLE_INVESTOR_OWNER)}
        </Button>
        <Button variant="primary" onClick={() => setPrimaryRole(PRIMARY_ROLE_COMMERCIAL_BROKER)}>
          {labelForPrimaryRole(PRIMARY_ROLE_COMMERCIAL_BROKER)}
        </Button>
        <Button variant="primary" onClick={() => setPrimaryRole(PRIMARY_ROLE_RESIDENTIAL_BROKER)}>
          {labelForPrimaryRole(PRIMARY_ROLE_RESIDENTIAL_BROKER)}
        </Button>
        <Button variant="primary" onClick={() => setPrimaryRole(PRIMARY_ROLE_OTHER)}>
          {labelForPrimaryRole(PRIMARY_ROLE_OTHER)}
        </Button>
      </div>
      <div className="d-flex justify-content-center mt-6">
        <button className="btn btn-link js-login">
          Do you have an existing Buildout account?
        </button>
      </div>
    </div>
  );
}

AskUserType.propTypes = {
  nextStep: PropTypes.string,
  primaryRole: PropTypes.string,
  setPrimaryRole: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

const mapStateToProps = ({ ui }) => ({ primaryRole: ui.primaryRole });
const mapDispatchToProps = { setPrimaryRole };

export default connect(mapStateToProps, mapDispatchToProps)(AskUserType);
