import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PropertyTypeFilters from '../property_type_filters';

import { toggleAssetClassId } from '../../../store/actions/criteria';
import { ASSET_CLASSES, PROPERTY_SUBTYPES_NOT_IN_INSIGHTS } from '../constants';

function PropertyTypeInput({
  criteria,
  toggleAssetClassId,
  offMarketSearch
}) {

  const handleAssetClassChange = (e) => {
    toggleAssetClassId(parseInt(e.target.value), e.target.checked);
  };

  let assetClasses = ASSET_CLASSES;
  if (!offMarketSearch) {
    assetClasses = assetClasses.filter(assetClass => assetClass.id !== 10);
  } else {
    assetClasses = assetClasses.map(assetClass => ({
      ...assetClass,
      subclasses: assetClass.subclasses.filter(subclass => !PROPERTY_SUBTYPES_NOT_IN_INSIGHTS.includes(subclass.value))
    }));
  }

  return (
    <div className="bs3-support">
      <PropertyTypeFilters
        assetClasses={assetClasses}
        selectedAssetClasses={criteria.assetClassIds || []}
        onAssetClassChange={handleAssetClassChange}
      />
    </div>
  );
}

PropertyTypeInput.propTypes = {
  criteria: PropTypes.shape({
    assetClassIds: PropTypes.arrayOf(PropTypes.number)
  }).isRequired,
  offMarketSearch: PropTypes.bool.isRequired,
  toggleAssetClassId: PropTypes.func.isRequired
};

const mapStateToProps = ({ criteria, ui }) => ({ criteria, offMarketSearch: ui.offMarketSearch });

export default connect(mapStateToProps, { toggleAssetClassId })(PropertyTypeInput);
