import React from 'react';
import PropTypes from 'prop-types';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons';
import EmptyState from '../empty_state';
import PropertyTenantRow from './property_tenant_row';
import { Col, Row } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PropertyTenants({ tenants }) {
  if (tenants.length === 0) {
    return (
      <EmptyState
        icon={faUserTie}
        text={'We could not find any tenants that were inside of this property.'}
        title={'No tenants found at this location'}
      />
    );
  }

  return (
    <div className="d-flex flex-column gap-2">
      <Row>
        <Col>
          <div className="d-flex align-items-center gap-2">
            <FontAwesomeIcon className="text-secondary" icon={faUserTie} />
            <h6 className="fw-bolder mb-0">Business Tenants at Location</h6>
          </div>
        </Col>
      </Row>
      {tenants.map((tenant, idx) => (
        <PropertyTenantRow key={idx} tenant={tenant} />
      ))}
    </div>
  );
}

PropertyTenants.propTypes = {
  tenants: PropTypes.array.isRequired
};
