import React from 'react';
import { Card, Col, Row } from 'react-bootstrap-5';
import PropTypes from 'prop-types';

import PropertyPrice from './property_price';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulbDollar } from '@fortawesome/pro-solid-svg-icons';

export default function PropertyValuation({ listings, valuation }) {
  return (
    <div className="d-flex flex-column gap-4">
      <div className="d-flex flex-row gap-2 align-items-center">
        <FontAwesomeIcon className="text-secondary" icon={faLightbulbDollar} />
        <h6 className="my-0 fw-bolder">Valuation Estimate</h6>
      </div>
      <Row className="g-2">
        <Col md={6}>
          <p className="my-0">
            Our valuation is based on a comparison with similar properties in the market,
            factoring in attributes like sale price, building class, and investment type.
          </p>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <PropertyPrice
                centered
                estimatedValue={valuation?.estimate}
                hideSubtitle
                listingValue={listings?.salePrice}
                valueClass="h3"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

PropertyValuation.propTypes = {
  listings: PropTypes.shape({
    salePrice: PropTypes.number,
  }),
  valuation: PropTypes.shape({
    estimate: PropTypes.number.isRequired,
    range_high: PropTypes.number.isRequired,
    range_low: PropTypes.number.isRequired
  }),
};
