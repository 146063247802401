import React from 'react';
import { Badge } from 'react-bootstrap-5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/pro-regular-svg-icons';

export default function LlcMatchBadge() {
  return (
    <Badge bg="" className="secondary-outline badge-sm" pill>
      <FontAwesomeIcon icon={faAward} />
      <span className="d-none d-md-inline">Legal Entity Match</span>
      <span className="d-md-none">LLC</span>
    </Badge>
  );
}
