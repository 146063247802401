import { unmountChildComponents } from 'helpers/react_on_rails';

// When a frame is rendered, it may be replacing React components, which must be unmounted to
// prevent memory leaks
export function handleBeforeFrameRender(event) {
  unmountChildComponents(event.target);
}

// Ditto to handleBeforeFrameRender for streams, but only for destructive actions
export function handleBeforeStreamRender(event) {
  const { action } = event.detail.newStream;
  if (action === 'remove' || action === 'replace' || action === 'update') {
    unmountChildComponents(findStreamTarget(event.detail.newStream));
  }
}

function findStreamTarget(stream) {
  return document.querySelector(`#${stream.target}`);
}
