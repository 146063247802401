import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { faPeopleRoof, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap-5';
import { setOwners } from '../../call_list/store/actions/owners';
import { connect } from 'react-redux';
import EmptyState from '../../empty_state';
import UnlockOwners from './unlock_owners';
import TabbedOwnerList from './tabbed-owner-list';
import AccordionOwnerList from './accordion-owner-list';
import { fetchLastFetchedAt } from '../../../helpers/prospect_api';
import RefreshOwnersModal from './refresh_owners_modal';

function OwnerList({ insightsPropertyId, trialUser, owners, setOwners }) {
  const [show, setShow] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [activeKey, setActiveKey] = useState();
  const [lastFetchedAt, setLastFetchedAt] = useState(null);
  const [remainingCredits, setRemainingCredits] = useState();
  const sharedOwnerListProps = { activeKey, owners, setActiveKey };
  const hasOwners = owners && owners.length > 0;

  const handleOwnersTabLoaded = useCallback((event) => {
    if (event.target.dataset.jsOwnersTab) setShow(true);
  }, []);

  useEffect(() => {
    // Purposefully wait for the "owners" tab to be shown so that we don't fetch owners every single time we
    // show a property
    window.addEventListener('show.bs.tab', handleOwnersTabLoaded);

    return () => window.removeEventListener('show.bs.tab', handleOwnersTabLoaded);
  }, []);

  useEffect(() => {
    if (owners && !owners.find(owner => owner.id === parseInt(activeKey))) setActiveKey(String(owners[0]?.id));
  }, [owners, activeKey]);

  useEffect(() => {
    if (show) {
      fetchLastFetchedAt(insightsPropertyId).then((res) => {
        if (res.lastFetched) {
          setLastFetchedAt(new Date(res.lastFetched));
          setRemainingCredits(res.remainingCredits);
        }
      });
    }
  }, [show]);

  if (!show) return null;

  const COMMON_PROPS = {
    insightsPropertyId,
    remainingCredits,
    trialUser,
    onUnlock: setOwners,
    setRemainingCredits
  };

  return (
    <div>
      <RefreshOwnersModal {...COMMON_PROPS}
        hideModal={() => setShowRefreshModal(false)}
        setLastFetchedAt={setLastFetchedAt}
        show={showRefreshModal}
      />
      <Row className="mb-4 align-items-center">
        <Col>
          <div className="d-flex gap-2 align-items-center">
            <FontAwesomeIcon className="text-secondary" icon={faPeopleRoof} />
            <h6 className="my-0 fw-bolder">Contacts</h6>
          </div>
          {lastFetchedAt && (
            <div className="text-muted small">Fetched on {lastFetchedAt.toLocaleDateString()}</div>
          )}
        </Col>
        {lastFetchedAt && (
          <Col xs="auto">
            <Button size="sm" variant="link" onClick={() => setShowRefreshModal(true)}>
              Refresh Contacts
            </Button>
          </Col>
        )}
      </Row>
      {owners === null && (
        <UnlockOwners {...COMMON_PROPS} />
      )}
      {owners && owners.length === 0 && (
        <EmptyState icon={faUser} title={'No Contacts Found'} />
      )}
      {hasOwners && (
        <>
          <div className="d-block d-lg-none">
            <AccordionOwnerList {...sharedOwnerListProps} />
          </div>
          <div className="d-none d-lg-block">
            <TabbedOwnerList {...sharedOwnerListProps} />
          </div>
        </>
      )}
    </div>
  );
}

OwnerList.propTypes = {
  insightsPropertyId: PropTypes.string.isRequired,
  owners: PropTypes.array,
  setOwners: PropTypes.func.isRequired,
  status: PropTypes.number,
  trialUser: PropTypes.bool.isRequired
};

const mapStateToProps = ({ owners }) => ({ owners });
export default connect(mapStateToProps, { setOwners })(OwnerList);
