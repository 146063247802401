import React from 'react';
import { Modal, Row } from 'react-bootstrap-5';

import PropTypes from 'prop-types';

export default function ImportModalErrors({
  callListData,
  callListId,
  handleClose,
  setShowAddressModal,
}) {
  return (
    <React.Fragment>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Upload Complete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <p>Your properties have been processed and are ready to be viewed.</p>
        </Row>
        <div className="bg-light rounded d-flex align-items-center p-2 mb-2">
          <h6 className="m-0">{callListData.numFound}</h6>
          <p className="text-muted ps-2 m-0">Properties Found</p>
        </div>
        <div className="bg-light rounded d-flex align-items-center justify-content-between p-2">
          <div className="d-flex">
            <h6 className="m-0">{callListData.noPropertyAddresses.length}</h6>
            <p className="text-muted ps-2 m-0">Not Found</p>
          </div>
          <button
            className="btn btn-text-primary"
            onClick={() => setShowAddressModal(true)}
          >
            View Addresses
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-text-primary"
          onClick={handleClose}
        >
          Close
        </button>
        <a className="btn btn-secondary"
          href={`/prospect/connect_call_lists/${callListId}`}
        >
          View List
        </a>
      </Modal.Footer>
    </React.Fragment>
  );
}

ImportModalErrors.propTypes = {
  callListData: PropTypes.object.isRequired,
  callListId: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  setShowAddressModal: PropTypes.func.isRequired,
};
