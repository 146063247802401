import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap-5';

import SessionModal from './session_modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-solid-svg-icons';

export default function SignInCard() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card style={{ backgroundColor: '#192755', borderColor: '#40434F', width: '230px' }}>
        <Card.Body className="d-flex flex-column gap-2">
          <div className="text-white">
            <FontAwesomeIcon icon={faLock} />
            <strong className="my-auto ms-2">Sign up today!</strong>
          </div>


          <small className="text-white">
            Unlock even more features by creating an account today.
          </small>

          <Button variant="secondary" onClick={() => setShowModal(true)}>
            Sign Up / Sign In
          </Button>
        </Card.Body>
      </Card>

      {showModal && <SessionModal onHide={() => setShowModal(false)} />}
    </>
  );
}
