import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleX } from '@fortawesome/pro-regular-svg-icons';

import { listItemPropTypes, reorderableListItem } from 'helpers/react_dnd/reorderable_list';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';

class SelectedColumnRow extends React.Component {
  constructor(props) {
    super(props);
  }

  renderDragSource() {
    return this.props.connectDragSource(
      <div className="me-2" style={{ cursor: 'grab' }}>
        <FontAwesomeIcon icon={faGripDotsVertical}/>
      </div>
    );
  }

  render() {
    const {
      column,
      connectDragPreview,
      connectDropTarget,
      isDragging,
      onDeselectColumn
    } = this.props;

    return connectDragPreview(connectDropTarget(
      <div style={{ opacity: isDragging ? 0 : 1 }}>
        <div
          className="d-flex align-items-center justify-content-between rounded border p-2 pe-4"
        >
          <div className="d-flex">
            {this.renderDragSource()}
            {column.label}
          </div>
          <FontAwesomeIcon
            className="clickable text-danger"
            icon={faCircleX}
            onClick={onDeselectColumn}
          />
        </div>
      </div>
    ));
  }
}

SelectedColumnRow.propTypes = {
  column: PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired }),
  onDeselectColumn: PropTypes.func.isRequired,
  ...listItemPropTypes
};

export default reorderableListItem(SelectedColumnRow, 'SelectedColumnList', {
  beginDrag(props) {
    return {
      id: props.column.value,
      index: props.index
    };
  }
});
