import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlock } from '@fortawesome/pro-regular-svg-icons';
import { Button } from 'react-bootstrap-5';
import { post } from 'helpers/api';
import CreateLeadErrorModal from './create_lead_error_modal';
import GoToPreviewModal from './goToPreviewModal';

export default function CreateLead({ createLeadPath, onNext }) {
  const [saving, setSaving] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');

  const createLead = () => {
    setSaving(true);
    post(createLeadPath)
      .then((data) => {
        if (data.previewUrl) {
          setPreviewUrl(data.previewUrl);
        } else {
          onNext();
        }
      })
      .catch(() => setOpenErrorModal(true))
      .finally(() => setSaving(false));
  };

  return (
    <div className="text-center">
      <h5 className="mb-4">
        <strong>Interested in viewing additional documents?</strong>
      </h5>
      <p>
        To view additional documents we'll need to share your contact information with the brokerage.
      </p>
      <Button disabled={saving} variant="primary" onClick={createLead}>
        <FontAwesomeIcon className="me-2" icon={faUnlock}/>
        {saving ? 'Saving ...' : 'Agree and continue'}
      </Button>

      <CreateLeadErrorModal show={openErrorModal} onHide={() => setOpenErrorModal(false)}/>
      <GoToPreviewModal previewUrl={previewUrl} show={previewUrl !== ''} onHide={() => setPreviewUrl('')}/>
    </div>
  );
}

CreateLead.propTypes = {
  createLeadPath: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired
};

