import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap-5';

import DocumentsModal from './documents/modal';
import LlcTitleCard from './llc_title_card';
import LlcPeopleList from './llc_people_list';

export default function LlcDetails({ llcData }) {
  if (!llcData) return null;
  const [showDocsModal, setShowDocsModal] = useState(false);

  const llcHasDocuments = llcData.filings.length > 0;
  const showDocsButton = () => {
    if (llcHasDocuments) return (
      <Button variant="outline-primary" onClick={() => setShowDocsModal(true)}>Fetch Documents</Button>
    );
  };

  return (
    <>
      <LlcTitleCard button={showDocsButton()} llcData={llcData}/>
      <LlcPeopleList llcData={llcData}/>
      {llcHasDocuments && (
        <DocumentsModal
          companyId={llcData.companyNumber}
          jurisdictionCode={llcData.jurisdictionCode}
          show={showDocsModal}
          onHide={() => setShowDocsModal(false)}
        />
      )}
    </>
  );
}

LlcDetails.propTypes = {
  llcData: PropTypes.shape({
    agentAddress: PropTypes.string,
    agentName: PropTypes.string,
    companyNumber: PropTypes.string,
    companyType: PropTypes.string,
    currentStatus: PropTypes.string,
    documents: PropTypes.arrayOf(PropTypes.shape({
      date: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string
    })),
    incorporationDate: PropTypes.string,
    name: PropTypes.string,
    officers: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      position: PropTypes.string
    }))
  })
};
