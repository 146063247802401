import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { FormControl, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export class SidebarSearch extends React.Component {
  static propTypes = {
    additionalClasses: PropTypes.string,
    children: PropTypes.node,
    forwardedRef: PropTypes.object,
    icon: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
    inputClassName: PropTypes.string,
    newDesignSystem: PropTypes.bool,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    static: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    newDesignSystem: false,
    size: '1x'
  };

  render() {
    const {
      additionalClasses,
      icon,
      inputProps,
      newDesignSystem,
      placeholder,
      size,
      value,
      onChange
    } = this.props;

    const hasValue = value && value !== '';

    const containerClasses = classNames(
      { static: this.props.static },
      { 'ds-search-input': newDesignSystem, 'search-container': !newDesignSystem, 'has-filters': hasValue },
      additionalClasses
    );

    return (
      <div className={containerClasses} ref={this.props.forwardedRef}>
        <FormGroup className="with-icon">
          <div className="icon search-icon">
            <FontAwesomeIcon icon={icon || faSearch} size={size}/>
          </div>
          <FormControl
            autoComplete="off"
            className={this.props.inputClassName}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            {...inputProps}
          />
          {this.props.children}
        </FormGroup>
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => <SidebarSearch {...props} forwardedRef={ref} />);
