import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Form, OverlayTrigger, Popover, PopoverBody, Spinner } from 'react-bootstrap-5';
import CallForm, { CALL_RESULTS } from './call_form';
import { createActivity, evaluateTemplate } from '../../../helpers/prospect_api';
import OtherForm from './other_form';
import {
  ACTIVITY_TYPE_CALL, ACTIVITY_TYPE_EMAIL, ACTIVITY_TYPE_OTHER, CONTACT_MODEL, PROPERTY_MODEL
} from '../../../constants';
import { addActivity } from '../store/actions/activities';
import { connect } from 'react-redux';
import ButtonGroupRadio from './button_group_radio';
import {
  faCircleCheck,
  faFloppyDisk,
  faInfoCircle,
  faPencil,
  faPhone,
  faTimes
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import EmailForm from './email_form';
import NewTaskModal from '../tasks/new_task_modal';
import { pushToRethink, setOwnerContactMethodScore } from '../store/actions/owners';
import { displayToast } from '../../../helpers/display_toast';
import { activityTypeLabel } from '../activity_history_item';

const TYPE_OPTIONS = [
  { icon: faPhone, label: 'Call', value: ACTIVITY_TYPE_CALL, },
  { icon: faEnvelope, label: 'Email', value: ACTIVITY_TYPE_EMAIL },
  { icon: faPencil, label: 'Other', value: ACTIVITY_TYPE_OTHER }
];

const DEFAULT_NEW_ACTIVITY = {
  callResult: CALL_RESULTS[0].value,
  description: '',
  location: '',
  emailSubject: '',
  type: ACTIVITY_TYPE_CALL,
  connectedRecordsAttributes: []
};

function NewActivityForm(
  {
    addActivity,
    callListId,
    callTemplates,
    owner,
    initialActivity,
    propertyId,
    pushToRethink,
    setOwnerContactMethodScore,
    showPushToRethink
  }
) {
  const [activity, setActivity] = useState(DEFAULT_NEW_ACTIVITY);
  const [saving, setSaving] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [createFollowUp, setCreateFollowUp] = useState(false);
  const [pushDataToRethink, setPushDataToRethink] = useState(false);
  const [dataPushedToRethink, setDataPushedToRethink] = useState(false);
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);

  const propertyConnectedRecord = propertyId ? {
    connectedRecordId: propertyId,
    connectedRecordType: PROPERTY_MODEL
  } : undefined;

  const contactConnectedRecord = {
    connectedRecordId: owner.contact.id,
    connectedRecordType: CONTACT_MODEL,
    label: owner.contact.name
  };

  const rethinkPopover = (props) => {
    return (
      <Popover {...props} arrowProps={{ style: { display: 'none' } }} className="border">
        <PopoverBody>
          <div className="d-flex flex-column gap-2">
            <div className="fw-bolder">
              Rethink Sync
            </div>
            <div>
              The contact, property, and activity will be synced with Rethink.
            </div>
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  useEffect(() => {
    if (!selectedTemplate) {
      setActivity(prevActivity => ({ ...prevActivity, script: undefined }));
      return;
    }

    evaluateTemplate(selectedTemplate, owner.contact.id, propertyId)
      .then(script => setActivity(prevActivity => ({ ...prevActivity, script })));
  }, [selectedTemplate, activity.connectedRecordsAttributes]);

  useEffect(() => {
    setActivity({ ...DEFAULT_NEW_ACTIVITY, ...initialActivity });
  }, [initialActivity.type, initialActivity.email, initialActivity.phoneNumber]);

  useEffect(() => {
    setDataPushedToRethink(false);
  }, [owner.id]);

  const handleSave = () => {
    setSaving(true);

    const activityParams = {
      ...activity,
      connectedRecordsAttributes: [
        ...activity.connectedRecordsAttributes,
        ...(propertyId ? [{ connectedRecordId: propertyId, connectedRecordType: PROPERTY_MODEL }] : []),
        { connectedRecordId: owner.contact.id, connectedRecordType: CONTACT_MODEL }
      ],
      name: activityTypeLabel({ ...activity, connectedRecordsAttributes: [contactConnectedRecord] })
    };

    createActivity(activityParams, callListId)
      .then((activity) => {
        displayToast('Activity created', 'success');
        addActivity(activity);
        if (activity.callResult === 'bad_phone' && owner.id && activity.phoneNumber) {
          const phoneNumber = owner.contact.phoneNumbers
            .find(phoneNumber => phoneNumber.number === activity.phoneNumber);

          if (phoneNumber) {
            setOwnerContactMethodScore(owner, 'phoneNumbers', phoneNumber.id, -1);
          }
        }
        setSelectedTemplate();
        setActivity(DEFAULT_NEW_ACTIVITY);

        if (pushDataToRethink) {
          return pushToRethink(propertyId, owner.contact.id, activity.id).then(() => {
            setDataPushedToRethink(true);
          });
        }
      }).then(() => {
        if (createFollowUp) {
          setShowNewTaskModal(true);
          setCreateFollowUp(false);
        }
      })
      .catch(() => {
        // errors
      })
      .finally(() => {
        setPushDataToRethink(false);
        setSaving(false);
      });
  };

  let form;
  if (activity.type === ACTIVITY_TYPE_CALL) {
    form = (
      <CallForm
        activity={activity}
        callTemplates={callTemplates}
        phoneNumbers={owner.contact.phoneNumbers}
        selectedTemplate={selectedTemplate}
        onTemplateSelect={setSelectedTemplate}
        onUpdate={setActivity}
      />
    );
  }
  if (activity.type === ACTIVITY_TYPE_EMAIL) {
    form = <EmailForm activity={activity} emails={owner.contact.emails} onUpdate={setActivity} />;
  }
  if (activity.type === ACTIVITY_TYPE_OTHER) form = <OtherForm activity={activity} onUpdate={setActivity} />;

  return (
    <>
      <div className="d-flex flex-column gap-3">
        {dataPushedToRethink && (
          <Alert className="p-3" variant="success">
            <div className="d-flex align-items-start gap-2">
              <FontAwesomeIcon className="py-1" icon={faCircleCheck} />
              <div className="d-flex flex-column">
                <div className="fw-bolder">
                  Record pushed to Rethink
                </div>
                <div>
                  We've successfully sent over the contact, property, and activity to Rethink.
                </div>
              </div>
              <div className="clickable px-2 py-1 ms-auto">
                <FontAwesomeIcon icon={faTimes} onClick={() => setDataPushedToRethink(false)} />
              </div>
            </div>
          </Alert>
        )}
        <h6 className="m-0">Log Activity</h6>
        <Form.Group>
          <Form.Label>Activity Type</Form.Label>
          <ButtonGroupRadio
            checked={activity.type}
            name="activity_type"
            options={TYPE_OPTIONS}
            onChange={type => setActivity({ ...activity, type })}
          />
        </Form.Group>
        {form}
        <div className="d-flex align-items-center ms-auto gap-2">
          {showPushToRethink && (
            <div className="d-flex align-items-center gap-1">
              <Form.Check
                checked={pushDataToRethink}
                id="push-to-rethink"
                label="Push to Rethink"
                type="checkbox"
                onChange={() => setPushDataToRethink(!pushDataToRethink)}
              />
              <OverlayTrigger
                overlay={rethinkPopover}
                placement="top"
              >
                <FontAwesomeIcon className="text-primary" icon={faInfoCircle} variant="primary"/>
              </OverlayTrigger>
            </div>
          )}
          <Form.Check
            checked={createFollowUp}
            id="follow-up-task"
            label="Create follow-up task"
            type="checkbox"
            onChange={() => setCreateFollowUp(!createFollowUp)}
          />
          <Button disabled={saving} variant="secondary" onClick={handleSave}>
            {saving && <Spinner animation="border" size="sm" />}
            {!saving && <FontAwesomeIcon icon={faFloppyDisk} />}
            Save Activity
          </Button>
        </div>
      </div>
      <NewTaskModal
        autoAddConnectedRecords={propertyConnectedRecord ? [propertyConnectedRecord] : undefined}
        callListId={callListId}
        initialConnectedRecords={[contactConnectedRecord]}
        show={showNewTaskModal}
        title="Follow-up Task"
        onAdd={addActivity}
        onHide={() => setShowNewTaskModal(false)}
      />
    </>
  );
}

NewActivityForm.propTypes = {
  addActivity: PropTypes.func.isRequired,
  callListId: PropTypes.number.isRequired,
  callTemplates: PropTypes.array.isRequired,
  initialActivity: PropTypes.object.isRequired,
  owner: PropTypes.object.isRequired,
  propertyId: PropTypes.number,
  pushToRethink: PropTypes.func.isRequired,
  setOwnerContactMethodScore: PropTypes.func.isRequired,
  showPushToRethink: PropTypes.bool
};

export default connect(undefined, { addActivity, pushToRethink, setOwnerContactMethodScore })(NewActivityForm);
