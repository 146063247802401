import React, { useState } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap-5';

import PropTypes from 'prop-types';
import { createAddressImport } from '../../../helpers/prospect_api';

export default function ImportModalStart({
  handleClose,
  setImportId,
}) {
  const [text, setText] = useState('');
  const [fileName, setFileName] = useState('Choose a file');
  const [hasFile, setHasFile] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [invalidHeaders, setInvalidHeaders] = useState();
  const [invalidFileSize, setInvalidFileSize] = useState();

  const handleKeyboardFileUpload = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      document.getElementById('connect_call_list_file').click();
    }
  };

  const handleChange = (e) => {
    if (e.target.type === 'text') {
      const val = e.target.value;
      setText(val);
      setDisabled(!hasFile || val === '');
    } else {
      e.target.files.length > 0 && setFileName(e.target.files[0].name);
      setHasFile(true);
      setDisabled(text === '');
    }
  };

  const handleFileUpload = () => {
    document.getElementById('connect_call_list_file').click();
  };

  const handleSubmit = () => {
    const file = document.getElementById('connect_call_list_file').files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', text);

    createAddressImport(formData).then((response) => {
      if (response.errors) {
        if (response.errors.invalidHeaders) {
          setInvalidHeaders(response.errors.invalidHeaders.join(', '));
        }
        if (response.errors.invalidFileSize) {
          setInvalidFileSize(response.errors.invalidFileSize);
        }
        return;
      }

      setImportId(response.id);
    });

  };

  return (
    <React.Fragment>
      <Modal.Header className="align-items-start" closeButton>
        <div>
          <Modal.Title as="h5">Upload List</Modal.Title>
          <small className="text-muted" >
            Upload a list of addresses and we'll find properties and their contact information for you.
          </small>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p>
          You can import any addresses inside of a CSV file. Please sure that each address has a zip code.
          City and State are not required, but encouraged.
        </p>
        {invalidHeaders && (
          <p className="text-danger">
            The file imported is missing the following header(s): {invalidHeaders}
          </p>
        )}
        {invalidFileSize && (
          <p className="text-danger">
            {invalidFileSize}
          </p>
        )}
        <Form.Group className="mb-3">
          <Form.Label aria-required="true">List Name: <span style={{ color: '#C2403E' }}>*</span></Form.Label>
          <Form.Control required type="text" onChange={handleChange} />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label aria-required="true">File <span style={{ color: '#C2403E' }}>*</span></Form.Label>
          <div className="d-flex align-items-center flex-direction-column">
            <div className="input-group flex-nowrap me-1" style={{ background: '#FFFFFF', cursor: 'pointer' }}>
              <span className="input-group-text" style={{ background: '#EEEEF2' }} onClick={handleFileUpload}>
                <i className="fa-solid fa-upload" />
              </span>
              <input className="form-control-plaintext px-2" id="connect_call_list_file_text" readOnly
                style={{ cursor: 'pointer' }}
                type="text" value={fileName} onClick={handleFileUpload} onKeyDown={handleKeyboardFileUpload}
              />
              <Form.Control accept=".csv" className="d-none"
                id="connect_call_list_file" required type="file" onChange={handleChange}
              />
            </div>
            <button className="btn-outline-primary btn" onClick={handleFileUpload}>Browse...</button>
          </div>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-between' }}>
        <a className="btn btn-outline-primary"
          download
          href="/prospect/connect_call_lists/download_template.csv"
        >
          <i className="fa-solid fa-file-arrow-down" />
          Download Template
        </a>
        <div>
          <Row>
            <Col xs="auto">
              <Button variant="text-primary" onClick={() => handleClose(false)}>
                Cancel
              </Button>
              <Button disabled={disabled} variant="secondary" onClick={() => handleSubmit()}>Upload</Button>
            </Col>
          </Row>

        </div>
      </Modal.Footer>
    </React.Fragment>
  );
}

ImportModalStart.propTypes = {
  handleClose: PropTypes.func.isRequired,
  setImportId: PropTypes.func.isRequired,
};
