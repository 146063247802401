import React from 'react';

import { ProgressBar } from 'react-bootstrap-5';
import PropTypes from 'prop-types';

export default function LoadingBar({ progress }) {
  return (
    <div className="bg-light p-2 rounded border">
      <div>
        <span className="fw-bolder text-base text-secondary" >{progress}%</span>
      </div>
      <div>
        <ProgressBar className="border" now={progress} />
      </div>
    </div>
  );
}

LoadingBar.propTypes = {
  progress: PropTypes.number.isRequired,
};
