import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, InputGroup, Row } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import SelectedColumnList from './selected_columns/list';

export default function TemplateForm({
  columnOptions,
  disableTypeSelector = false,
  formData,
  selectedColumns,
  setSelectedColumns,
  setFormData
}) {
  const [columnSearchString, setColumnSearchString] = useState('');

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const columnOptionsForSelect = useMemo(() => {
    if (formData.dataExportType == 'activity') return columnOptions.activity.filter(o => !selectedColumns.includes(o));
    if (formData.dataExportType == 'property') return columnOptions.property.filter(o => !selectedColumns.includes(o));

    return [];
  }, [columnOptions, formData, selectedColumns]);

  const columnOptionSearchResults = useMemo(() => {
    if (!columnSearchString) return columnOptionsForSelect;

    return columnOptionsForSelect.filter(o => o.label.toLowerCase().includes(columnSearchString.toLowerCase()));
  }, [columnOptionsForSelect, columnSearchString]);

  const selectColumn = useCallback((column) => {
    setSelectedColumns([...selectedColumns, column]);
  }, [selectedColumns]);

  const deselectColumn = useCallback((column) => {
    setSelectedColumns(selectedColumns.filter(c => c != column));
  }, [selectedColumns]);

  const handleReorderColumns = useCallback((dragIndex, dropIndex) => {
    const columns = Object.assign([], selectedColumns);
    const column = columns[dragIndex];
    columns.splice(dragIndex, 1);
    columns.splice(dropIndex, 0, column);

    setSelectedColumns(columns);
  }, [selectedColumns]);

  return (
    <>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control name="name" value={formData.name} onChange={handleFormChange} />
      </Form.Group>
      <Form.Group className="py-4">
        <Form.Label>Export Type</Form.Label>
        <Form.Select
          disabled={disableTypeSelector}
          name="dataExportType"
          value={formData.dataExportType}
          onChange={handleFormChange}
        >
          <option value="">Select an Export Type</option>
          <option value="property">Property / Owner Data</option>
          <option value="activity">Activity Data</option>
        </Form.Select>
      </Form.Group>
      {formData.dataExportType && (
        <Row>
          <Col xs={6}>
            <Form.Label>Available Columns ({columnOptionSearchResults.length})</Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faMagnifyingGlass}/>
              </InputGroup.Text>
              <Form.Control
                type="text"
                value={columnSearchString}
                onChange={e => setColumnSearchString(e.target.value)}
              />
            </InputGroup>
            <div className="rounded border p-2" style={{ maxHeight: 400, overflow: 'scroll' }}>
              <div className=" d-flex flex-column gap-2">
                {columnOptionSearchResults.map(col => (
                  <div
                    className="rounded border d-flex align-items-center justify-content-between p-2 pe-4"
                    key={col.value}
                  >
                    {col.label}
                    <FontAwesomeIcon
                      className="clickable text-primary"
                      icon={faArrowRight}
                      onClick={() => selectColumn(col)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <Form.Label>Selected Columns ({selectedColumns.length})</Form.Label>
            <div className="rounded border p-2" style={{ maxHeight: 400, overflow: 'scroll' }}>
              <SelectedColumnList
                selectedColumns={selectedColumns}
                onDeselectColumn={deselectColumn}
                onReorderColumns={handleReorderColumns}
              />
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

TemplateForm.propTypes = {
  columnOptions: PropTypes.shape({
    property: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
    activity: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
  }).isRequired,
  disableTypeSelector: PropTypes.bool,
  formData: PropTypes.shape({
    name: PropTypes.string,
    dataExportType: PropTypes.string
  }).isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  setFormData: PropTypes.func.isRequired,
  setSelectedColumns: PropTypes.func.isRequired
};
