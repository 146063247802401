import React, { cloneElement, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce, isEmpty } from 'lodash';

import { faCheckCircle, faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { faBuildoutLogo } from 'helpers/custom_fontawesome_icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Form, ListGroup, Overlay, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap-5';

import CreateListModal from './create_list_modal';
import pluralize from 'pluralize';
import { offMarketPropertySearch } from 'bundles/connect/helpers/prospect_api';
import { addContactsToCallList, addPropertiesToCallList, fetchCallLists } from '../../helpers/prospect_api';
import { CONTACT_MODEL, PROPERTY_MODEL } from '../../constants';

export default function AddToList({
  criteria,
  children,
  itemCount,
  selectAll,
  itemIds,
  itemType,
  onAddToList,
  popoverTargetRef,
  tooltip = true,
}) {
  const [showOverlay, setShowOverlay] = useState();
  const [showCreateModal, setShowCreateModal] = useState();
  const [callLists, setCallLists] = useState([]);
  const [savedTo, setSavedTo] = useState();
  const [saving, setSaving] = useState(false);
  const [searchText, setSearchText] = useState('');
  const triggerRef = useRef(null);
  const overlayId = Math.floor(Math.random() * 100000);

  const debouncedSearch = useCallback(debounce((search) => {
    fetchCallLists({ 'q[name_or_description_cont]': search }).then(setCallLists);
  }, 300), []);

  useEffect(() => {
    if (showOverlay) {
      setSavedTo(undefined);
      setSaving(false);
      setSearchText('');
    }
  }, [showOverlay]);

  useEffect(() => {
    if (savedTo) {
      setTimeout(() => handleCloseOverlay(), 3000);
    }
  }, [savedTo]);

  useEffect(() => {
    if (showOverlay) debouncedSearch(searchText);
  }, [showOverlay, searchText]);

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  const handleEnterPressed = useCallback((e) => {
    e.preventDefault();
    if (searchText.length > 0 && callLists.length === 0) handleShowCreateModal(e);
  }, [searchText, callLists]);

  const assembleCallListItems = async () => {
    let ids = [...itemIds];

    if (selectAll && criteria) {
      const results = await offMarketPropertySearch(criteria, 10_000);
      ids = (results.hits || []).map(hit => hit.propertyId);
    }

    return ids;
  };

  const handleAddToList = (callList) => {
    setShowCreateModal(false);
    setShowOverlay(true);
    setSaving(true);

    (async () => {
      if (itemType === PROPERTY_MODEL) {
        const callListItems = await assembleCallListItems();

        if (window.gtag) window.gtag('event', 'connect_off_market_property_added_to_call_list');

        addPropertiesToCallList(callList.id, callListItems)
          .then(() => setSavedTo(callList.name))
          .finally(() => setSaving(false));
      } else if (itemType === CONTACT_MODEL) {
        addContactsToCallList(callList.id, [...itemIds])
          .then(() => {
            setSavedTo(callList.name);
            onAddToList();
          })
          .finally(() => setSaving(false));
      }
    })();
  };

  const handleShowCreateModal = (e) => {
    e.stopPropagation();

    handleCloseOverlay();
    setShowCreateModal(true);
  };

  const popover = (
    <Popover id={`add-to-list-popover-${overlayId}`} style={{ maxWidth: '500px', minWidth: '320px' }}>
      <Popover.Header as="h6">Add {pluralize('Item', Math.min(itemCount, 10000), true)} To List</Popover.Header>

      {saving && (
        <Popover.Body>
          <div className="text-primary text-center animate__animated animate__fadeIn" style={{ fontSize: '1rem' }}>
            <FontAwesomeIcon beatFade className="me-2" icon={faBuildoutLogo} />
            Saving...
          </div>
        </Popover.Body>
      )}
      {!saving && savedTo && (
        <Popover.Body>
          <div className="text-primary text-center animate__animated animate__fadeIn" style={{ fontSize: '1rem' }}>
            <FontAwesomeIcon className="me-2" icon={faCheckCircle} />
            Saved to "{savedTo}"
          </div>
        </Popover.Body>
      )}
      {!saving && !savedTo && (
        <>
          <Popover.Body className="px-0 pt-2 pb-0">
            <form onSubmit={handleEnterPressed}>
              <Form.Group className="mb-2 px-3">
                <Form.Label>Search for a list</Form.Label>

                <div className="with-icon">
                  <div className="icon search-icon">
                    <FontAwesomeIcon icon={faSearch}/>
                  </div>

                  <Form.Control autoFocus type="text" value={searchText} onChange={e => setSearchText(e.target.value)}/>
                </div>
              </Form.Group>
            </form>

            {isEmpty(callLists) ? (
              <div className="py-2 px-3">No results found</div>
            ) : (
              <ListGroup style={{ maxHeight: '200px', overflow: 'auto' }}>
                {
                  callLists.map(callList => (
                    <ListGroup.Item
                      action
                      className="border-0"
                      key={callList.id}
                      onClick={() => handleAddToList(callList)}
                    >
                      {callList.name}
                    </ListGroup.Item>
                  ))
                }
              </ListGroup>
            )}
          </Popover.Body>
          <div className="border-top d-flex align-items-center justify-content-between p-2">
            <a className="py-2 px-3 d-block" href="/prospect/connect_call_lists">Manage Lists</a>
            <Button variant="primary" onClick={handleShowCreateModal}>
              <FontAwesomeIcon className="me-2" icon={faPlus} size="sm" />
              Create New List
            </Button>
          </div>
        </>
      )}
    </Popover>
  );

  const renderTooltip = (
    <Tooltip id={`add-to-list-tooltip-${overlayId}`}>
      Add to List
    </Tooltip>
  );

  // If there is a provided popoverTargetRef, we don't need a ref on the children.
  // If there is no provided popoverTargetRef, put a ref on the children and use that for the popover target
  const modifiedChildren = cloneElement(children, {
    onClick: () => setShowOverlay(true),
    ref: popoverTargetRef?.current ? undefined : triggerRef
  });

  return (
    <>
      <Overlay
        flip={true}
        placement="auto"
        rootClose
        show={showOverlay}
        target={popoverTargetRef?.current || triggerRef.current}
        onHide={() => setShowOverlay(false)}
      >
        {popover}
      </Overlay>

      {tooltip ? (
        <OverlayTrigger
          overlay={renderTooltip}
          placement="top"
          popperConfig={{ modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            }
          ] }}
        >
          {modifiedChildren}
        </OverlayTrigger>
      ) : modifiedChildren}

      <CreateListModal
        defaultName={searchText}
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onCreate={handleAddToList}
      />
    </>
  );
}

AddToList.propTypes = {
  children: PropTypes.node.isRequired,
  criteria: PropTypes.object,
  itemCount: PropTypes.number.isRequired,
  itemIds: PropTypes.instanceOf(Set).isRequired,
  itemType: PropTypes.string.isRequired,
  placement: PropTypes.string,
  popoverTargetRef: PropTypes.object,
  selectAll: PropTypes.bool,
  tooltip: PropTypes.bool,
  onAddToList: PropTypes.func
};
