import React from 'react';
import PropTypes from 'prop-types';
import ProductFeature from './product_feature';
import ButtonGroupRadio from '../../call_list/activity_forms/button_group_radio';
import { faMap, faTableRows } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import { setShowTableView } from '../store/actions/ui';

const MAP_VIEW = 'MAP_VIEW';
const TABLE_VIEW = 'TABLE_VIEW';

const TYPE_OPTIONS = [
  { icon: faMap, label: '', value: MAP_VIEW },
  { icon: faTableRows, label: '', value: TABLE_VIEW }
];

function OffMarketMapTableViewToggle({ showTableView, setShowTableView }) {
  const view = showTableView ? TABLE_VIEW : MAP_VIEW;

  const handleViewChange = () => {
    setShowTableView(!showTableView);
  };

  return (
    <div className="d-none d-xxl-block">
      <ProductFeature product="searchTableView">
        <ButtonGroupRadio
          checked={view}
          name="search_view"
          options={TYPE_OPTIONS}
          onChange={handleViewChange}
        />
      </ProductFeature>
    </div>
  );
}

OffMarketMapTableViewToggle.propTypes = {
  setShowTableView: PropTypes.func.isRequired,
  showTableView: PropTypes.bool.isRequired
};

const mapStateToProps = ({ ui: { showTableView } }) => {
  return {
    showTableView
  };
};

export default connect(mapStateToProps, { setShowTableView })(OffMarketMapTableViewToggle);

