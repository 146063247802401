import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap-5';
import MyPropertiesTable from './my_properties_table';
import { debounce } from 'lodash';
import { myPropertiesByAddress } from '../../helpers/prospect_api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import Paginator from '../paginator';
import EmptyState from '../empty_state';
import AddToList from '../properties/add_to_list';
import { PROPERTY_MODEL } from '../../constants';

export default function MyPropertiesIndex() {
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [properties, setProperties] = useState();
  const [selectedProperties, setSelectedProperties] = useState([]);

  useEffect(() => {
    debouncedSearch(searchText, page);
  }, [searchText, page]);

  const onUpdate = useCallback((e) => {
    setPage(1);
    setSearchText(e.target.value);
  });

  const debouncedSearch = useCallback(debounce((address, page) => {
    myPropertiesByAddress(address, page)
      .then((r) => {
        setProperties(r.properties);
        setTotal(r.total);
      });
  }, 300), []);

  const handleSelectAll = useCallback(() => {
    if (selectedProperties.length < properties.length) {
      setSelectedProperties(properties);
    } else {
      setSelectedProperties([]);
    }
  });

  const handleSelect = useCallback((property) => {
    const propertyExists = selectedProperties.some(prop => prop.propertyId === property.propertyId);
    let newcheckedProperties;

    if (propertyExists) {
      newcheckedProperties = selectedProperties.filter(prop => prop.propertyId !== property.propertyId);
    } else {
      newcheckedProperties = selectedProperties.concat(property);
    }

    setSelectedProperties(newcheckedProperties);
  });

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col sm={6} xs={8} >
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faMagnifyingGlass}/>
              </InputGroup.Text>
              <Form.Control
                placeholder="Search for a property name"
                type="text"
                value={searchText}
                onChange={e => onUpdate(e)}
              />
            </InputGroup>
          </Col>
          <Col className="ml-auto text-end" sm={6} xs={4} >
            <AddToList
              itemCount={selectedProperties.length}
              itemIds={new Set(selectedProperties.map(property => property.insightsPropertyId))}
              itemType={PROPERTY_MODEL}
              tooltip={false}
            >
              <Button
                className="text-nowrap px-2"
                disabled={selectedProperties.length === 0}
                variant="secondary"
              >
                + Add to List
              </Button>
            </AddToList>
          </Col>
        </Row>
      </Card.Header>
      {properties && properties.length === 0 && (
        <EmptyState
          icon={faBuilding}
          text={searchText ? '' : 'Your bookmarked properties will display here.'}
          title={searchText ? 'No Results Found' : ''}
        />
      )}
      {properties && properties.length > 0 && (
        <MyPropertiesTable
          properties={properties}
          selectedProperties={selectedProperties}
          onSelect={handleSelect}
          onSelectAll={handleSelectAll}
        />
      )}
      {!properties && (
        <div className="py-4 text-center">
          <Spinner animation="border" variant="primary"/>
        </div>
      )}
      {total > 25 && (
        <Card.Footer className="d-flex justify-content-center border-top-0">
          <Paginator currentPage={page} perPage={25} total={total} onSelect={setPage} />
        </Card.Footer>
      )}
    </Card>
  );
}

