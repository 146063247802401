import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap-5';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faPencilAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { post } from 'helpers/api';
import CaText from './ca_text';
import { getProfileData, PROFILE_OPTIONS, setProfileData } from '../../helpers/preview';

export default function SignCaText({ caText, name, readonly, signCaPath, onNext }) {
  const [emailCa, setEmailCa] = useState(false);
  const [agreedToCa, setAgreedToCa] = useState(false);
  const [saving, setSaving] = useState(false);

  const signCa = (e) => {
    e.preventDefault();

    setSaving(true);
    if (readonly) setProfileData({ [PROFILE_OPTIONS.CA_SIGNED]: true });
    const previewProfile = getProfileData();
    post(signCaPath, { signCa: true, emailCa, readonly, previewProfile }).then(() => {
      onNext();
    });
  };

  return (
    <div>
      <div className="text-center mb-4 text-branding">
        <FontAwesomeIcon icon={faLock} size="2x"/>
      </div>
      <h5 className="text-center mb-4">
        <strong>Sign the CA to view this document</strong>
      </h5>
      <form onSubmit={signCa}>
        <CaText caText={caText}/>
        <Form.Group className="mb-4">
          <Form.Control name="name" readOnly value={name}/>
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Check
            checked={agreedToCa}
            id="agree-to-ca"
            label="Click here to agree to sign the CA"
            name="agree_to_ca"
            type="checkbox"
            onChange={e => setAgreedToCa(e.target.checked)}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Check
            checked={emailCa}
            id="email-ca"
            label="Email me a copy of this CA"
            name="email_ca"
            type="checkbox"
            onChange={e => setEmailCa(e.target.checked)}
          />
        </Form.Group>
        <Button
          className="w-100"
          disabled={!agreedToCa || saving}
          name="save"
          type="submit"
          variant="primary"
          onClick={signCa}
        >
          <FontAwesomeIcon className="me-2" icon={saving ? faSpinner : faPencilAlt} spin={saving}/>
          {saving ? 'Signing ...' : 'Sign CA'}
        </Button>
      </form>
    </div>
  );
}

SignCaText.propTypes = {
  caText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  readonly: PropTypes.bool,
  signCaPath: PropTypes.string.isRequired,
  onNext: PropTypes.func.isRequired
};
