import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Col, Row } from 'react-bootstrap-5';
import Ownership from '../../call_list/owners/ownership';
import OwnerNameDisplay from './owner-name-display';
import LlcMatchBadge from '../../call_list/owners/llc_match_badge';

export default function AccordionOwnerList({ activeKey, owners, setActiveKey }) {
  return (
    <Row>
      <Accordion activeKey={activeKey} className="pb-2" onSelect={setActiveKey}>
        {owners.map(owner => (
          <Accordion.Item eventKey={String(owner.id)} key={owner.id}>
            <Accordion.Header>
              <Row className="align-items-center w-100">
                <Col>
                  <OwnerNameDisplay label={owner.label} name={owner.contact.name} />
                </Col>
                {owner.llcMatch && (
                  <Col xs="auto">
                    <LlcMatchBadge />
                  </Col>
                )}
              </Row>
            </Accordion.Header>
            <Accordion.Body>
              <Ownership owner={owner} showOwnerName={false} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Row>
  );
}

AccordionOwnerList.propTypes = {
  activeKey: PropTypes.string,
  owners: PropTypes.array.isRequired,
  setActiveKey: PropTypes.func.isRequired,
};
