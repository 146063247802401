import {
  zoneListForBounds
} from 'bundles/connect/helpers/prospect_api';

export const SET_HOVER_LISTING_ID = 'SET_HOVER_LISTING_ID';
export const SET_OFF_MARKET_HOVER_PROPERTY_ID = 'SET_OFF_MARKET_HOVER_PROPERTY_ID';
export const SET_LISTING_ID = 'SET_LISTING_ID';
export const SET_LOADING = 'SET_LOADING';
export const SET_SHOW_MAP = 'SET_SHOW_MAP';
export const SET_SHOW_TABLE_VIEW = 'SET_SHOW_TABLE_VIEW';
export const SET_SAVE_SEARCH_MODAL = 'SET_SAVE_SEARCH_MODAL';
export const INCREMENT_LISTING_VIEW_COUNT = 'INCREMENT_LISTING_VIEW_COUNT';
export const SET_OFF_MARKET_SEARCH = 'SET_OFF_MARKET_SEARCH';
export const SET_MAP_LOADED = 'SET_MAP_LOADED';
export const SET_MAP_ZOOM_READONLY = 'SET_MAP_ZOOM_READONLY';
export const SET_CHOICE_PRESETS = 'SET_CHOICE_PRESETS';

export function mapLoaded() {
  return { type: SET_MAP_LOADED };
}

export function setOffMarketSearch(val) {
  return {
    type: SET_OFF_MARKET_SEARCH,
    payload: val
  };
}

export function setMapZoomReadonly(val) {
  return {
    type: SET_MAP_ZOOM_READONLY,
    payload: val
  };
}

export function setHoverListingId(id) {
  return {
    type: SET_HOVER_LISTING_ID,
    payload: id
  };
}

export function setOffMarketHoverPropertyId(id) {
  return {
    type: SET_OFF_MARKET_HOVER_PROPERTY_ID,
    payload: id
  };
}

export function setLoading(loading) {
  return { type: SET_LOADING, payload: loading };
}

export function setShowMap(show) {
  return { type: SET_SHOW_MAP, payload: show };
}

export function setShowTableView(show) {
  return { type: SET_SHOW_TABLE_VIEW, payload: show };
}

export function showSaveSearchModal(show) {
  return { type: SET_SAVE_SEARCH_MODAL, payload: show };
}

export function fetchZonesList() {
  return async (dispatch, getState) => {
    const { criteria } = getState();
    const zones = await zoneListForBounds(criteria.mapBounds);
    dispatch(setChoicePresets({ key: 'zoneList', value: zones }));
  };
}

export function setChoicePresets(val) {
  return {
    type: SET_CHOICE_PRESETS,
    payload: {
      key: val.key,
      value: val.value,
    },
  };
}
