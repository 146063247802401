import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPeopleRoof } from '@fortawesome/pro-solid-svg-icons';
import { Card, Col, ListGroup, Nav, Row, Tab } from 'react-bootstrap-5';
import ContactSection from '../../call_list/owners/contact_section';

const ownerData = [
  {
    id: 1,
    name: 'John Doe',
    addresses: ['123 Main St, Springfield, IL', '456 Elm St, Springfield, IL'],
    emails: ['johndoe@example.com', 'doejohn@example.com'],
    phones: ['+15554325678', '+15554325679'],
  },
  {
    id: 2,
    name: 'Jane Smith',
    addresses: ['789 Oak St, Springfield, IL', '101 Pine St, Springfield, IL'],
    emails: ['janesmith@example.com', 'smithjane@example.com'],
    phones: ['+15554325680', '+15554325681'],
  },
];

export default function FakeContacts() {
  return (
    <div>
      <Row className="mb-4">
        <Col className="d-flex align-items-center gap-2">
          <FontAwesomeIcon className="text-secondary" icon={faPeopleRoof} />
          <h6 className="my-0 fw-bolder">Contacts</h6>
        </Col>
      </Row>
      <Tab.Container activeKey={1}>
        <Row>
          <Col>
            <Nav className="nav-pills flex-column">
              {ownerData.map(owner => (
                <Nav.Link
                  className="text-start rounded-1 border-0"
                  key={owner.name}
                >
                  <Row className="align-items-center">
                    <Col>
                      <small className="text-muted fw-normal">Owner</small>
                      <h6 className="text-body">{owner.name}</h6>
                    </Col>
                    <Col xs="auto">
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Col>
                  </Row>
                </Nav.Link>
              ))}
            </Nav>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Tab.Content>
                  {ownerData.map(owner => (
                    <Tab.Pane eventKey={owner.id} key={owner.id}>
                      <ListGroup.Item className="pb-4">
                        <div className="d-flex flex-column gap-3">
                          <ContactSection defaultOpen={true} title="Phone Numbers">
                            {owner.phones.map(phoneNumber => (
                              <div className={`d-flex align-items-center flex-wrap`} key={phoneNumber}>
                                <div>{phoneNumber}</div>
                                <small className="ms-1 text-muted fw-normal">Office</small>
                              </div>
                            ))}
                          </ContactSection>
                          <ContactSection defaultOpen={true} title="Email Addresses">
                            {owner.emails.map(email => (
                              <div className={`text-truncate`} key={email}>
                                {email}
                              </div>
                            ))}
                          </ContactSection>
                          <ContactSection title="Addresses">
                            {owner.addresses.map(address => (
                              <div key={address}>
                                <div>{address}</div>
                              </div>
                            ))}
                          </ContactSection>
                        </div>
                      </ListGroup.Item>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
