import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];
  static values = { shown: Array, hidden: Array };

  toggleVisibility({ currentTarget }) {
    const parentElement = currentTarget.parentElement;
    if (this.contentTarget.style.display === 'none') {
      this.contentTarget.style.setProperty('display', 'inherit');
      if (this.shouldToggleClasses()) this.toggleClasses(parentElement, this.shownValue, this.hiddenValue);
    } else {
      this.contentTarget.style.setProperty('display', 'none', 'important');
      if (this.shouldToggleClasses()) this.toggleClasses(parentElement, this.hiddenValue, this.shownValue);
    }
  }

  shouldToggleClasses() {
    return Array.isArray(this.hiddenValue) && Array.isArray(this.shownValue);
  }

  toggleClasses(element, classesToAdd, classesToRemove) {
    classesToRemove.forEach(className => element.classList.remove(className));
    classesToAdd.forEach(className => element.classList.add(className));
  }
}
