import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap-5';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import Results from './results';
import Map from './map';
import OffMarketResults from './off_market_results';

export function ResultAndMapViewer({ nominatimUrl, showMap, showTableView, offMarketSearch }) {
  const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
  const shouldShowMap = showMap || !isMobile;
  const containerRef = useRef(null);

  return (
    <Row className="flex-grow-1 overflow-hidden">
      <Col
        className={
          classNames(
            'border-end h-100 overflow-auto position-relative p-0 bg-body z-0',
            isMobile && shouldShowMap ? 'd-none' : 'd-block'
          )
        }
        lg={showTableView && offMarketSearch ? 8 : 6}
        ref={containerRef}
        xxl={showTableView && offMarketSearch ? 8 : 4}
      >
        {offMarketSearch ? <OffMarketResults containerRef={containerRef} /> : <Results containerRef={containerRef} />}
      </Col>
      <Col className={classNames('px-0', shouldShowMap ? 'd-block' : 'd-none')}>
        <Map nominatimUrl={nominatimUrl} showMap={shouldShowMap} />
      </Col>
    </Row>
  );
}

ResultAndMapViewer.propTypes = {
  nominatimUrl: PropTypes.string.isRequired,
  offMarketSearch: PropTypes.bool.isRequired,
  showMap: PropTypes.bool.isRequired,
  showTableView: PropTypes.bool.isRequired
};

const mapStateToProps = (
  { ui: { showMap, showTableView, offMarketSearch } }
) => ({ showMap, showTableView, offMarketSearch });

export default connect(mapStateToProps)(ResultAndMapViewer);
