import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap-5';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPlus } from '@fortawesome/pro-solid-svg-icons';
import EmptyState from '../empty_state';
import NewTaskModal from '../call_list/tasks/new_task_modal';
import EditTaskModal from '../call_list/tasks/edit_task_modal';
import Task from '../call_list/tasks/task';
import { addActivity, removeActivity, updateActivity } from '../call_list/store/actions/activities';
import { CONTACT_MODEL } from '../../constants';

function TasksCard({ activities, contact, addActivity, removeActivity, updateActivity }) {
  const [showNewTaskModal, setShowNewTaskModal] = useState(false);
  const [taskForEdit, setTaskForEdit] = useState();
  const tasks = activities.filter(activity => activity.isTask && !activity.completedAt);

  return (
    <div>
      <Card>
        <Card.Header>
          <Row className="align-items-center">
            <Col xs="auto">
              <Button variant="secondary" onClick={() => setShowNewTaskModal(true)}>
                <FontAwesomeIcon icon={faPlus} /> New
              </Button>
            </Col>
          </Row>
        </Card.Header>
        {tasks.length === 0 && (
          <Card.Body>
            <EmptyState
              icon={faCalendar}
              text="There are no tasks in the queue."
            />
          </Card.Body>
        )}
        {tasks.length > 0 && (
          <ListGroup variant="flush">
            {tasks.map(task => (
              <Task
                key={task.id}
                task={task}
                onDelete={() => removeActivity(task.id, 'Task')}
                onEdit={setTaskForEdit}
                onUpdate={updateActivity}
              />
            ))}
          </ListGroup>
        )}
      </Card>
      <NewTaskModal
        initialConnectedRecords={
          [{ connectedRecordType: CONTACT_MODEL, connectedRecordId: contact.id, label: contact.name }]
        }
        show={showNewTaskModal}
        onAdd={addActivity}
        onHide={() => setShowNewTaskModal(false)}
      />
      <EditTaskModal
        existingActivity={taskForEdit}
        onHide={() => setTaskForEdit()}
        onUpdate={updateActivity}
      />
    </div>
  );
}

TasksCard.propTypes = {
  activities: PropTypes.array.isRequired,
  addActivity: PropTypes.func.isRequired,
  contact: PropTypes.object.isRequired,
  removeActivity: PropTypes.func.isRequired,
  updateActivity: PropTypes.func.isRequired
};

const mapStateToProps = ({ activities }) => ({ activities });
export default connect(mapStateToProps, { addActivity, removeActivity, updateActivity })(TasksCard);
