import { Controller } from '@hotwired/stimulus';
import { setOffMarketPropertyId } from '../components/search/helpers/navigation_helper';

export default class extends Controller {
  static values = {
    propertyId: String
  }

  loadProperty(e) {
    e.preventDefault();
    setOffMarketPropertyId(this.propertyIdValue);  
  }
}
