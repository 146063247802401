import React from 'react';
import PropTypes from 'prop-types';

import { USD } from 'helpers/formatters/number_formats';

export default function PropertyPrice({
  centered,
  estimatedValue,
  hideSubtitle,
  listingValue,
  shortSubtitle,
  valueClass,
}) {
  const useEstimated = !(listingValue && listingValue !== 0);
  const valueForDisplay = listingValue || estimatedValue;

  if (!valueForDisplay) return null;

  return (
    <div className={`d-flex flex-column gap-1 ${centered ? 'align-items-center' : 'align-items-baseline'}`}>
      <span className={`my-0 ${valueClass}`}>
        {USD.format(valueForDisplay, { round: true })}
      </span>
      {!hideSubtitle && useEstimated && (
        <div className="text-xs text-muted">
          Est. {!shortSubtitle && 'Value'}
        </div>
      )}
    </div>
  );
}

PropertyPrice.propTypes = {
  centered: PropTypes.bool,
  estimatedValue: PropTypes.number,
  hideSubtitle: PropTypes.bool,
  listingValue: PropTypes.number,
  shortSubtitle: PropTypes.bool,
  valueClass: PropTypes.string,
};
